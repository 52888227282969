import React, { useCallback, useRef, useState } from 'react';
import { Row, Col, InputGroup, Input, FormGroup } from 'reactstrap';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { useIntl } from 'react-intl';
import { ConfirmAction, InlineEdit } from 'smv-components';

import './Weight.scss';
import { dataPrepActions } from 'smv-redux';
import { permissionEnum, useUserHasPermission } from 'smv-helpers';

const baseUrl = `${process.env.REACT_APP_STATIC_ASSET_URL}`;

export const Weight = ({ weight, weightHandler, surveyId }) => {
  const inputFile = useRef(null);
  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  const handleClickUploadFileButton = () => {
    inputFile.current.click();
  };

  const intl = useIntl();

  // eslint-disable-next-line
  const [isOpen, setIsOpen] = useState(false);

  const handler = {
    setWeightLabel: value => {
      weightHandler.updateWeight(weight.id, { ...weight, label: value });
    },
    setWeightMode: value => {
      weightHandler.updateWeight(weight.id, { ...weight, mode: value ? 'interlocked' : 'independent' });
    },
    setStandardWeight: value => {
      weightHandler.updateWeight(weight.id, { ...weight, isDefault: value });
    },

    setDefinitionMode: (id, value) => {
      const index = weight.definitions.findIndex(def => def.id === id);
      if (index >= 0) {
        weight.definitions[index].mode = value;
      }

      weightHandler.updateWeight(weight.id, weight);
    },
    setDefinitionSource: (id, value) => {
      const index = weight.definitions.findIndex(def => def.id === id);
      if (index >= 0) {
        weight.definitions[index].sourceId = value;
      }

      weightHandler.updateWeight(weight.id, weight);
    },
    toggleDefinitionSubgroup: (id, value) => {
      const index = weight.definitions.findIndex(def => def.id === id);
      if (index >= 0) {
        weight.definitions[index].inSubgroups = value;
      }

      weightHandler.updateWeight(weight.id, weight);
    },
    setDefinitionSubgroup: (id, value) => {
      const index = weight.definitions.findIndex(def => def.id === id);
      if (index >= 0) {
        weight.definitions[index].subgroupId = value;
      }

      weightHandler.updateWeight(weight.id, weight);
    },
    setDefinitionPoststratValue: (defid, choiceid, value) => {
      // todo: definition values not present in the endpoint
      // setWeight(state =>
      //   produce(state, draftState => {
      //     if (Array.isArray(draftState.definitions.list[defid].values)) draftState.definitions.list[defid].values = {};
      //     draftState.definitions.list[defid].values[choiceid] = parseFloat(value) / 100;
      //   })
      // );
    },
    addDefinition: () => {
      weightHandler.updateWeight(weight.id, {
        ...weight,
        definitions: [...weight?.definitions, { mode: 'poststratification' }]
      });
    },
    deleteDefinition: id => {
      const index = weight.definitions.findIndex(def => def.id === id);
      if (index >= 0) {
        weight.definitions = weight.definitions.filter(def => def.id !== id);
      }

      weightHandler.updateWeight(weight.id, weight);
    }
  };

  const handleFileUploadDialog = useCallback(
    e => {
      let files = Object.values(e?.target?.files).map(el => {
        el.id = weight.id;
        return el;
      });

      // handleUploadImages(files)
      files.forEach(file => {
        dataPrepActions.uploadWeightCsvFile(surveyId, weight.id, file);
      });
      e.target.value = null;
    },
    [surveyId, weight.id]
  );

  const handleDeleteCsvFile = useCallback(() => {
    dataPrepActions.deleteWeightCsvFile(surveyId, weight.id);
  }, [surveyId, weight.id]);

  const isFileUploaded = weight?.mode === 'file' ?? false;

  return (
    <div className='smv-weight' id={weight.id}>
      <Row key={weight.id} className='mb-1 d-flex align-items-center'>
        <Col md={{ size: 6, offset: 0 }}>
          <div className='d-flex align-items-center'>
            {/* <i
              onClick={() => setIsOpen(b => !b)}
              id={`toggle_${weight.id}`}
              className={`pointer fas fa-fw fa-chevron-right rotate ${isOpen ? 'rotate-90' : ''}`}
            ></i> */}
            {/* <UncontrolledTooltip className='tooltip-test' placement='top' target={`toggle_${weight.id}`} fade={false}>
              {isOpen ? 'Hide' : 'Show'}
            </UncontrolledTooltip> */}
            <b onClick={() => setIsOpen(b => !b)} className='bold mr-1'>
              {intl.formatMessage({ id: 'smoove.page.data.weight.weight-name' })}:
            </b>
            <InlineEdit
              className='smv-weight__title mr-4'
              defaultValue={weight.label}
              placeholder='enter weight name...'
              saveFn={value => handler.setWeightLabel(value)}
              disabled={!userCanEditProject}
            />
            <CopyWeightIdInput idstring={`weight_${weight.id}`} />
          </div>
        </Col>
        <Col md={6} className='d-flex justify-content-end align-items-center'>
          <div className='d-flex align-items-center' id={`weight_standard_toggle-${weight.id}`}>
            <div className='mr-2'>{intl.formatMessage({ id: 'smoove.page.data.weight.standard-weight' })} </div>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                id={`toggleSetStandardWeight_${weight.id}`}
                name={`toggleSetStandardWeight_${weight.id}`}
                className='pointer'
                checked={weight.isDefault ?? false}
                disabled={!userCanEditProject}
                onChange={e => handler.setStandardWeight(e.target.checked)}
              />
            </FormGroup>
          </div>
          <UncontrolledTooltip placement='top' target={`weight_standard_toggle-${weight.id}`}>
            {intl.formatMessage({ id: 'smoove.page.data.weight.default-weight-for-newly-created-tables' })}
          </UncontrolledTooltip>
          {isFileUploaded && userCanEditProject && (
            <ConfirmAction
              title={intl.formatMessage({ id: 'smoove.page.data.weight.delete-weight-csv-file' })}
              size={'md'}
              body={
                <div>
                  {intl.formatMessage({
                    id: 'smoove.page.data.weight.do-you-really-want-to-delete-the-csv-file-for-this-weight'
                  })}
                </div>
              }
              callback={handleDeleteCsvFile}
            >
              {confirm => (
                <Button color='danger' outline onClick={confirm} className='mr-2'>
                  <span>{intl.formatMessage({ id: 'smoove.page.data.weight.delete-csv-file' })}</span>
                </Button>
              )}
            </ConfirmAction>
          )}
          {isFileUploaded && (
            <a className='btn btn-sm btn-smv-blue-outline mr-2' href={`${baseUrl}/${weight.weightFileUrl}`}>
              {intl.formatMessage({ id: 'smoove.page.data.weight.download-csv-file' })}
            </a>
          )}
          <Button onClick={handleClickUploadFileButton} className='mr-4' disabled={!userCanEditProject}>
            <span>{isFileUploaded ? 'Change' : 'Upload'} .csv file</span>
          </Button>
          <input
            type='file'
            accept='.csv'
            id='file'
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={handleFileUploadDialog}
          />

          {userCanEditProject && (
            <>
              <ConfirmAction
                title={intl.formatMessage({ id: `smoove.page.data.weight.delete-weight` })}
                size={'md'}
                body={<div>{intl.formatMessage({ id: `smoove.page.data.weight.delete-weight-confirm-text` })}</div>}
                callback={() => weightHandler.deleteWeight(weight.id)}
              >
                {confirm => (
                  <Button color='danger' outline className='mr-1' onClick={confirm} id={`delweight_${weight.id}`}>
                    <i className='fal fa-trash-alt'></i>
                  </Button>
                )}
              </ConfirmAction>
              <UncontrolledTooltip placement='top' target={`delweight_${weight.id}`} fade={false}>
                {intl.formatMessage({ id: 'smoove.page.data.weight.delete-weight' })}
              </UncontrolledTooltip>
            </>
          )}
        </Col>
      </Row>
      {/* <Row>
        <Col md={{ size: 12 }}>
          <Collapse isOpen={isOpen}>
            {weight?.definitions?.map(definition => {
              return (
                <WeightDefinition
                  key={definition.id}
                  weight={weight}
                  definition={definition}
                  sources={sources}
                  handler={handler}
                />
              );
            })}
          </Collapse>
        </Col>
      </Row> */}
    </div>
  );
};

const CopyWeightIdInput = ({ idstring }) => {
  const [showNotification, setShowNotification] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(idstring);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 1200);
  };

  return (
    <div className='copy-weight-input'>
      <InputGroup>
        <Input type='text' className='pointer' value={idstring} readOnly onClick={copyToClipboard} />
        <Button color='primary' onClick={copyToClipboard}>
          <i className='fal fa-copy'></i>
        </Button>
      </InputGroup>
      {showNotification && <div className='copied-notification'>ID copied</div>}
    </div>
  );
};
