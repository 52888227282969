import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Input, ListGroupItem, ListGroupItemHeading, Row } from 'reactstrap';

import { getRowOrder, useGetSourceElement, useMatrixType, useSubelementPropertyTranslation } from 'smv-helpers';
import produce from 'immer';
import { isEqual } from 'lodash';

export const ChartDimensionsConfig = ({ chartConfig, tableConfig, tableResult, resultTableType, handler }) => {
  const intl = useIntl();

  const getSourceElement = useGetSourceElement();
  const { isCompactMatrix } = useMatrixType(tableConfig);

  const colSources = useMemo(() => {
    const _colSources = {};
    let heads = tableConfig.heads;

    if (isCompactMatrix) {
      heads = produce(heads, d => {
        if (isCompactMatrix) {
          tableConfig.rows.order.forEach(rowid => {
            if (tableConfig.rows.list[rowid].sourceType === 'question') {
              d.order = [rowid, ...d.order];
              d.list[rowid] = tableConfig.rows.list[rowid];
            }
          });
        }
      });
    }

    // collect the value structure from each head
    const valueStructureElements = {};

    for (const headId of heads.order) {
      const head = heads.list[headId];
      const { element, elementValues } = getSourceElement(head);

      valueStructureElements[element.id] = [];

      for (const orderItem of tableResult?.headOrder?.[headId] ?? []) {
        const subelement = elementValues.list[orderItem.headSubId];
        valueStructureElements[element.id].push(subelement.value);

        if (chartConfig.scatterDimensionsDimension === 'heads') {
          _colSources[`${headId}`] = {
            element,
            cellIdentifier: { headId }
          };
        } else {
          _colSources[`${headId}~${orderItem.headSubId}`] = {
            element,
            subelement,
            cellIdentifier: { headId, headSubId: orderItem.headSubId }
          };
        }
      }
    }

    // check if the heads have the same value sequence
    const valueStructureCheck = hasDuplicateValueStructure(valueStructureElements);

    Object.keys(_colSources).map(sourceKey => {
      return (_colSources[sourceKey]['duplicatedValueStructure'] =
        valueStructureCheck?.[_colSources?.[sourceKey]?.element?.id] ?? false);
    });

    return _colSources;
  }, [chartConfig.scatterDimensionsDimension, getSourceElement, tableConfig, tableResult, isCompactMatrix]);

  const rowSources = useMemo(() => {
    const _rowSources = {};

    // collect the value structure from each row
    const valueStructureElements = {};

    for (const rowId of tableConfig.rows.order) {
      const row = tableConfig.rows.list[rowId];
      const { element, elementValues, elementScales } = getSourceElement(row);
      const { rowOrder } = getRowOrder(tableResult?.order ?? [], row);

      valueStructureElements[element.id] = [];

      for (const orderItem of rowOrder) {
        let sourceId;
        let subelement = null;
        let subsubelement = null;

        const isHidden = orderItem?.hidden ?? false;
        const isExcluded = orderItem?.excluded ?? false;

        if (isHidden || isExcluded) continue;

        if (orderItem?.subsubelement && tableResult?.tableStruct === 'matrix_detail') {
          subelement = elementValues.list[orderItem.subelement];

          valueStructureElements[element.id].push(subelement?.value);

          subsubelement = elementScales.list[orderItem.subsubelement];

          sourceId = `${rowId}~${orderItem.subelement}~${orderItem.subsubelement}`;
        } else if (
          element.type === 'question' &&
          ['single_matrix', 'multiple_matrix'].includes(element.config.qtype) &&
          tableResult?.tableStruct === 'matrix_compact_single'
        ) {
          subelement = elementScales.list[orderItem.subelement];

          sourceId = `${rowId}~${orderItem.subelement}~${null}`;
        } else {
          subelement = elementValues.list[orderItem.subelement];

          valueStructureElements[element?.id].push(subelement?.value);

          sourceId = `${rowId}~${orderItem.subelement}~${null}`;
        }

        if (chartConfig.scatterDimensionsDimension === 'rows') {
          _rowSources[`${rowId}`] = {
            element,
            cellIdentifier: { rowId }
          };
        } else {
          _rowSources[`${sourceId}`] = {
            element,
            subelement,
            subsubelement,
            cellIdentifier: { rowId, rowSubId: orderItem.subelement, rowSubSubId: orderItem?.subsubelement ?? null }
          };
        }
      }
    }

    // check if the rows have the same value sequence
    const valueStructureCheck = hasDuplicateValueStructure(valueStructureElements);

    Object.keys(_rowSources).map(sourceKey => {
      return (_rowSources[sourceKey]['duplicatedValueStructure'] =
        valueStructureCheck?.[_rowSources?.[sourceKey]?.element?.id] ?? false);
    });

    return _rowSources;
  }, [chartConfig.scatterDimensionsDimension, getSourceElement, tableConfig, tableResult]);

  const { selectedDimensionSources, subDimensionSources } = useMemo(() => {
    let selected = {};
    let sub = {};

    if (chartConfig?.scatterDimensionsDimension === 'allHeads' || chartConfig?.scatterDimensionsDimension === 'rows') {
      selected = rowSources;
      sub = colSources;
    } else if (
      chartConfig?.scatterDimensionsDimension === 'heads' ||
      chartConfig?.scatterDimensionsDimension === 'allRows'
    ) {
      selected = colSources;
      sub = rowSources;
    }

    if (['heads', 'rows'].includes(chartConfig.scatterDimensionsDimension)) {
      selected = Object.fromEntries(Object.entries(selected).filter(([, value]) => value.duplicatedValueStructure));
    }

    return { selectedDimensionSources: selected, subDimensionSources: sub };
  }, [chartConfig.scatterDimensionsDimension, rowSources, colSources]);

  const rowsDoHaveDuplicatedValueStructure =
    Object.keys(rowSources).length > 1
      ? Object.values(rowSources).some(item => item.duplicatedValueStructure === true) !== false
      : undefined;

  const colsDoHaveDuplicatedValueStructure =
    Object.keys(colSources).length > 1
      ? Object.values(colSources).some(item => item.duplicatedValueStructure === true) !== false
      : undefined;

  const ScatterDimensionsSubDimensionKey = useMemo(() => {
    if (chartConfig?.scatterDimensionsSubDimension === null) {
      const firstKey = Object.keys(subDimensionSources)?.[0];
      return firstKey;
    } else if (
      isEqual(chartConfig?.scatterDimensionsSubDimension ?? {}, { headId: '__total__', headSubId: '__total__' })
    ) {
      return '__total__';
    } else if (!!chartConfig?.scatterDimensionsSubDimension) {
      return Object.values(chartConfig?.scatterDimensionsSubDimension ?? {})
        .map(v => `${v}`)
        .join('~');
    }
  }, [chartConfig?.scatterDimensionsSubDimension, subDimensionSources]);

  const ScatterDimensionsXKey = useMemo(() => {
    if (chartConfig?.scatterDimensionsX === null) {
      const firstKey = Object.keys(selectedDimensionSources)?.[0];
      return firstKey;
    } else if (!!chartConfig?.scatterDimensionsX) {
      return Object.values(chartConfig?.scatterDimensionsX ?? {})
        .map(v => `${v}`)
        .join('~');
    }
  }, [chartConfig?.scatterDimensionsX, selectedDimensionSources]);

  const ScatterDimensionsYKey = useMemo(() => {
    if (chartConfig?.scatterDimensionsY === null) {
      const secondKey = Object.keys(selectedDimensionSources)?.[1];
      return secondKey;
    } else if (!!chartConfig?.scatterDimensionsY) {
      return Object.values(chartConfig?.scatterDimensionsY ?? {})
        .map(v => `${v}`)
        .join('~');
    }
  }, [chartConfig?.scatterDimensionsY, selectedDimensionSources]);

  const ScatterDimensionsLabelsKey = useMemo(() => {
    if (chartConfig?.scatterDimensionsLabels === null) {
      return Object.keys(selectedDimensionSources)?.[0];
    } else if (!!chartConfig?.scatterDimensionsLabels) {
      return Object.values(chartConfig?.scatterDimensionsLabels ?? {})
        .map(v => `${v}`)
        .join('~');
    }
  }, [chartConfig?.scatterDimensionsLabels, selectedDimensionSources]);

  useEffect(() => {
    // initial 'allHeads' (default logic) selection
    if (chartConfig?.scatterDimensionsDimension === undefined) {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsDimension = 'allHeads';
        })
      );
    }

    // if 'rows' is selected and the user hides a subrow from one of the rows which are used for x/y dimensions - switch to 'allRows'
    if (chartConfig?.scatterDimensionsDimension === 'rows' && rowsDoHaveDuplicatedValueStructure === false) {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsDimension = 'allRows';
        })
      );
    }

    // if the table has only one head switch to 'allHeads'(default logic)
    if (Object.keys(colSources).length < 2 && rowsDoHaveDuplicatedValueStructure === false) {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsDimension = 'allHeads';
        })
      );
    }

    // initial 'heads' or 'rows 'selection -> select first row (if heads) or first col (if rows) as subdimension
    if (chartConfig?.scatterDimensionsDimension === 'heads' || chartConfig?.scatterDimensionsDimension === 'rows') {
      const rowKey = Object.keys(subDimensionSources)?.[0];
      if (!chartConfig?.scatterDimensionsSubDimension && rowKey) {
        handler.setTableConfig(state =>
          produce(state, draftState => {
            draftState.chart.scatterDimensionsSubDimension = subDimensionSources?.[rowKey]?.cellIdentifier ?? null;
          })
        );
      }
    } else {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsSubDimension = null;
        })
      );
    }

    // take first entry from selected sources as x
    const xKey = Object.keys(selectedDimensionSources)?.[0] ?? null;
    if (!chartConfig?.scatterDimensionsX && xKey) {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsX = selectedDimensionSources?.[xKey]?.cellIdentifier ?? null;
          draftState.chart.scatterDimensionsLabels = selectedDimensionSources?.[xKey]?.cellIdentifier ?? null;
        })
      );
    }

    // take second entry from selected sources as y
    const yKey = Object.keys(selectedDimensionSources)?.[1] ?? null;
    if (!chartConfig?.scatterDimensionsY && yKey) {
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsY = selectedDimensionSources?.[yKey]?.cellIdentifier ?? null;
        })
      );
    }
  }, [
    chartConfig?.scatterDimensionsDimension,
    chartConfig?.scatterDimensionsSubDimension,
    chartConfig?.scatterDimensionsX,
    chartConfig?.scatterDimensionsY,
    chartConfig?.scatterDimensionsLabels,
    selectedDimensionSources,
    subDimensionSources,
    rowSources,
    colSources,
    rowsDoHaveDuplicatedValueStructure,
    handler
  ]);

  const setScatterDimensionsDimension = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsDimension = value;
          draftState.chart.scatterDimensionsSubDimension = null;
          draftState.chart.scatterDimensionsX = null;
          draftState.chart.scatterDimensionsY = null;
          draftState.chart.scatterDimensionsLabels = null;
        })
      );
    },
    [handler]
  );

  const setDimensionsSubDimension = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          if (value === '__total__') {
            draftState.chart.scatterDimensionsSubDimension = { headId: '__total__', headSubId: '__total__' };
          } else {
            draftState.chart.scatterDimensionsSubDimension = subDimensionSources?.[value]?.cellIdentifier ?? null;
          }

          draftState.chart.scatterDimensionsX = null;
          draftState.chart.scatterDimensionsY = null;
          draftState.chart.scatterDimensionsLabels = null;
        })
      );
    },
    [handler, subDimensionSources]
  );

  const setScatterDimensionsX = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsX = selectedDimensionSources?.[value]?.cellIdentifier ?? null;
          draftState.chart.scatterDimensionsLabels = null;
        })
      );
    },
    [handler, selectedDimensionSources]
  );

  const setScatterDimensionsY = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsY = selectedDimensionSources?.[value]?.cellIdentifier ?? null;
          draftState.chart.scatterDimensionsLabels = null;
        })
      );
    },
    [handler, selectedDimensionSources]
  );

  const setScatterDimensionsLabels = useCallback(
    e => {
      const { value } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          draftState.chart.scatterDimensionsLabels = selectedDimensionSources?.[value]?.cellIdentifier ?? null;
        })
      );
    },
    [handler, selectedDimensionSources]
  );

  // console.log('chartConfig?.scatterDimensionsDimension', chartConfig?.scatterDimensionsDimension)
  // console.log('chartConfig?.scatterDimensionsX', chartConfig?.scatterDimensionsX)
  // console.log('chartConfig?.scatterDimensionsY', chartConfig?.scatterDimensionsY)
  // console.log('chartConfig?.scatterDimensionsSubDimension', chartConfig?.scatterDimensionsSubDimension)
  // console.log('colSources', colSources)
  // console.log('rowSources', rowSources)
  // console.log('selectedDimensionSources', selectedDimensionSources)
  // console.log('subDimensionSources', subDimensionSources)
  // console.log('colsDoHaveDuplicatedValueStructure', colsDoHaveDuplicatedValueStructure)
  // console.log('rowsDoHaveDuplicatedValueStructure', rowsDoHaveDuplicatedValueStructure)

  return (
    <ListGroupItem>
      <ListGroupItemHeading>
        <FormattedMessage
          id={'smoove.page.tables.chart-config.scatter-dimensions.settings'}
          defaultMessage={'Dimensions settings'}
        />
      </ListGroupItemHeading>
      {/* xy dimension : heads, rows, heads */}
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage
            id={'smoove.page.tables.chart-config.scatter-dimensions.dimension'}
            defaultMessage={'Dimension'}
          />
        </Col>
        <Col md={7}>
          <Input
            type='select'
            label={intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.allHeads` })}
            id={`scatterDimensionsDimension`}
            name='scatterDimensionsDimension'
            value={chartConfig?.scatterDimensionsDimension ?? 'allHeads'}
            onChange={setScatterDimensionsDimension}
          >
            <option value={'allHeads'}>
              {intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.allHeads` })}
            </option>
            {Object.keys(colSources).length > 1 && colsDoHaveDuplicatedValueStructure && (
              <option value={'heads'}>
                {intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.heads` })}
              </option>
            )}
            {Object.keys(colSources).length > 1 && (
              <option value={'allRows'}>
                {intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.allRows` })}
              </option>
            )}
            {/* there have to be two rows with the same amount of subRows (values) */}
            {/* only display 'rows' if this condition is met */}
            {rowsDoHaveDuplicatedValueStructure && (
              <option value={'rows'}>
                {intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.rows` })}
              </option>
            )}
          </Input>
        </Col>
      </Row>
      {/* row/column selection */}
      {(chartConfig?.scatterDimensionsDimension === 'heads' || chartConfig?.scatterDimensionsDimension === 'rows') && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>
            <FormattedMessage
              id={
                chartConfig?.scatterDimensionsDimension === 'heads'
                  ? 'smoove.page.tables.chart-config.scatter-dimensions.row-selection'
                  : 'smoove.page.tables.chart-config.scatter-dimensions.col-selection'
              }
              defaultMessage={'row selection'}
            />
          </Col>
          <Col md={7}>
            <Input
              type='select'
              label={
                chartConfig?.scatterDimensionsDimension === 'heads'
                  ? intl.formatMessage({ id: 'smoove.page.tables.chart-config.scatter-dimensions.row-selection' })
                  : intl.formatMessage({ id: 'smoove.page.tables.chart-config.scatter-dimensions.col-selection' })
              }
              id={`scatterDimensionsSubDimension`}
              name='scatterDimensionsSubDimension'
              value={ScatterDimensionsSubDimensionKey}
              onChange={setDimensionsSubDimension}
            >
              {chartConfig?.scatterDimensionsDimension === 'rows' && !isCompactMatrix && (
                <option value='__total__'>{intl.formatMessage({ id: `smoove.page.tables.total` })}</option>
              )}
              {Object.keys(subDimensionSources).map(sourceKey => {
                return (
                  <QuestionOptions key={sourceKey} sourceKey={sourceKey} source={subDimensionSources[sourceKey]} />
                );
              })}
            </Input>
          </Col>
        </Row>
      )}
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage
            id={'smoove.page.tables.chart-config.scatter-dimensions.x'}
            defaultMessage={'x dimension'}
          />
        </Col>
        <Col md={7}>
          <Input
            type='select'
            label={intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.x` })}
            id={`scatterDimensionsX`}
            name='scatterDimensionsX'
            value={ScatterDimensionsXKey}
            onChange={setScatterDimensionsX}
          >
            {Object.keys(selectedDimensionSources)
              .filter(sourceKey =>
                chartConfig?.scatterDimensionsDimension === 'heads'
                  ? selectedDimensionSources[sourceKey].duplicatedValueStructure
                  : true
              )
              .map(sourceKey => {
                return (
                  <QuestionOptions key={sourceKey} sourceKey={sourceKey} source={selectedDimensionSources[sourceKey]} />
                );
              })}
          </Input>
        </Col>
      </Row>
      <Row className='mt-1 align-items-center'>
        <Col md={5}>
          <FormattedMessage
            id={'smoove.page.tables.chart-config.scatter-dimensions.y'}
            defaultMessage={'y dimension'}
          />
        </Col>
        <Col md={7}>
          <Input
            type='select'
            label={intl.formatMessage({ id: `smoove.page.tables.chart-config.scatter-dimensions.y` })}
            id={`scatterDimensionsY`}
            name='scatterDimensionsY'
            value={ScatterDimensionsYKey}
            onChange={setScatterDimensionsY}
          >
            {Object.keys(selectedDimensionSources)
              .filter(sourceKey =>
                chartConfig?.scatterDimensionsDimension === 'heads'
                  ? selectedDimensionSources[sourceKey].duplicatedValueStructure
                  : true
              )
              .map(sourceKey => {
                return (
                  <QuestionOptions key={sourceKey} sourceKey={sourceKey} source={selectedDimensionSources[sourceKey]} />
                );
              })}
          </Input>
        </Col>
      </Row>
      {(chartConfig?.scatterDimensionsDimension === 'heads' || chartConfig?.scatterDimensionsDimension === 'rows') && (
        <Row className='mt-1 align-items-center'>
          <Col md={5}>
            <FormattedMessage
              id={'smoove.page.tables.chart-config.scatter-dimensions.labels'}
              defaultMessage={'labels'}
            />
          </Col>
          <Col md={7}>
            <Input
              type='select'
              label={intl.formatMessage({ id: 'smoove.page.tables.chart-config.scatter-dimensions.labels' })}
              id={`scatterDimensionsLabels`}
              name='scatterDimensionsLabels'
              value={ScatterDimensionsLabelsKey}
              onChange={setScatterDimensionsLabels}
            >
              {Object.keys(selectedDimensionSources)
                .filter(sourceKey => selectedDimensionSources[sourceKey].duplicatedValueStructure)
                .map(sourceKey => {
                  return (
                    <QuestionOptions
                      key={sourceKey}
                      sourceKey={sourceKey}
                      source={selectedDimensionSources[sourceKey]}
                    />
                  );
                })}
            </Input>
          </Col>
        </Row>
      )}
    </ListGroupItem>
  );
};

const QuestionOptions = ({ sourceKey, source }) => {
  const elementLabel = source.element?.label ?? source.element?.title ?? source.element.id;

  const labels = [];

  labels[0] = useSubelementPropertyTranslation(source.subelement, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  labels[1] = useSubelementPropertyTranslation(source.subsubelement, null, {
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  const finalLabel = source.subelement ? `${elementLabel} - ${labels.filter(Boolean).join(' | ')}` : `${elementLabel}`;

  return <option value={sourceKey}>{finalLabel}</option>;
};

function hasDuplicateValueStructure(obj) {
  // convert arrays to string for comparison
  const serializedArrays = Object.entries(obj).map(([key, arr]) => [key, JSON.stringify(arr)]);

  // count occurrences of each serialized array
  const counts = serializedArrays.reduce((acc, [, arrStr]) => {
    acc[arrStr] = (acc[arrStr] || 0) + 1;
    return acc;
  }, {});

  // check if the serialized array has duplicates
  return Object.fromEntries(serializedArrays.map(([key, arrStr]) => [key, counts[arrStr] > 1]));
}
