import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResponsiveContainer, PieChart as PieChartReCharts, Tooltip, Legend, Pie, Cell } from 'recharts';

import {
  getContrastColor,
  getFixedColor,
  getFormattedValue,
  getSchemeColor,
  useChartData,
  useLegendMouseEffects
} from './helper';

export const PieChart = ({
  elementid,
  chartConfig,
  table,
  tableResult,
  activeSignificanceType = null,
  getOpacitySignificance
}) => {
  const [chartData, chartDimensions] = useChartData({
    chartConfig,
    table,
    tableResult,
    transpose: true
  });

  const selectedClientId = useSelector(state => state.clients.selected);
  const colorScales = useSelector(
    state => state.clients?.list?.[selectedClientId]?.colorScales ?? { list: {}, order: [] }
  );
  const valueFormatter = useCallback(value => getFormattedValue(value, table.config), [table.config]);

  /**
   * HOVER EFFECTS
   */
  const { onMouseEnter, onMouseLeave, getOpacity } = useLegendMouseEffects();

  if (!chartData || chartDimensions.length < 1) return null;

  const { colorSchema, colorSchemaReverse } = chartConfig;

  const valuePosition = chartConfig?.valuesValuePosition ?? 'inside';

  const dimension = chartDimensions[0];

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <PieChartReCharts width={200} height={120}>
        {(chartConfig?.showTooltip ?? true) && (
          <Tooltip formatter={valueFormatter} labelFormatter={label => <strong>{label}</strong>} />
        )}

        {(chartConfig?.showLegend ?? true) && (
          <Legend
            iconType={'circle'}
            iconSize={chartConfig?.legendIconSize ?? 14}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            formatter={value => <span style={{ color: '#666' }}>{value}</span>}
          />
        )}

        <Pie
          key={dimension.id}
          data={chartData}
          dataKey={`${dimension.id}.value`}
          cx='50%'
          cy='50%'
          startAngle={90}
          endAngle={-270}
          paddingAngle={chartConfig?.significancesShow ? 2 : 0}
          innerRadius={chartConfig?.innerRadius ?? 0}
          outerRadius={100}
          labelLine={chartConfig?.valuesShow && valuePosition !== 'inside'}
          label={chartConfig?.valuesShow ? ValueLabel : false}
          isAnimationActive={false}
        >
          {chartData.map((category, index) => {
            let scalePointValue = index / (chartData.length - 1);
            if (isNaN(scalePointValue)) scalePointValue = 0;
            if (colorSchemaReverse) scalePointValue = 1 - scalePointValue;

            let { color, contrastColor } = getSchemeColor({ colorScales, colorSchema, value: scalePointValue });

            const cellIdentifier = category[dimension.id]?.cellIdentifier;
            if (!cellIdentifier) return null;
            const fixedColor = getFixedColor(chartConfig, cellIdentifier);
            if (fixedColor) {
              color = fixedColor.hex;
              contrastColor = getContrastColor(fixedColor.rgb);
            }

            const significance = category?.significances[dimension.id] ?? null;

            let stroke = '#ffffff';

            if (chartConfig?.significancesShow && significance) {
              const sigHighColor = '#008000';
              const sigLowColor = '#FF0000';
              const sigMutuallyColor = '#ac4bbd';

              stroke =
                significance === 'high'
                  ? sigHighColor
                  : significance === 'low'
                  ? sigLowColor
                  : significance === 'both'
                  ? sigMutuallyColor
                  : '#fff';
            }

            const opacity =
              activeSignificanceType && getOpacitySignificance !== undefined
                ? getOpacitySignificance(significance)
                : getOpacity(category.name);

            return (
              <Cell
                key={category.name}
                fill={color}
                strokeWidth={2}
                stroke={stroke}
                textFill={contrastColor}
                opacity={opacity}
                valuePosition={valuePosition}
                valueFormatter={valueFormatter}
              />
            );
          })}
        </Pie>
      </PieChartReCharts>
    </ResponsiveContainer>
  );
};

const ValueLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  textFill,
  opacity,
  valueFormatter,
  valuePosition
}) => {
  const radius = valuePosition === 'inside' ? innerRadius + (outerRadius - innerRadius) * 0.5 : outerRadius + 25;
  const _x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
  const _y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);
  const textAnchor = valuePosition === 'inside' ? 'middle' : _x > cx ? 'start' : 'end';

  return (
    <text
      x={_x}
      y={_y}
      fill={valuePosition === 'inside' ? textFill : '#000'}
      textAnchor={textAnchor}
      dominantBaseline={'central'}
      opacity={opacity}
    >
      {valueFormatter(value)}
    </text>
  );
};
