import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { useSelector } from 'react-redux';
import { Row, Col, ListGroup } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import produce from 'immer';
import { isArray, isEqual } from 'lodash';
import { arrayMoveImmutable } from 'array-move';

import { tablesActions } from 'smv-redux';
import {
  exportDomElementAsImage,
  permissionEnum,
  useIsQueWriteable,
  useMatrixType,
  useUserHasPermission,
  usePrevious,
  useResultTableType
} from 'smv-helpers';
import { SmvSidebarToggleButton, SmvSidebarToggleButtonMobile, SyntheticDataWarning } from '../components/misc';
import { useSidebarToggle } from 'src/smoove/components/misc/helper';
import { TabulationAssistant } from '../components/project-results/TabulationAssistant/TabulationAssistant';

import {
  ChartConfig,
  LanguageSelectDropdown,
  QuestionList,
  ResultTable,
  TABLE_INITIAL_STATE,
  TableButtons,
  TableConfig,
  TablesList,
  tableTypeChartTypes
} from '../components/project-results';

import './Tables.scss';

export const Tables = ({ history, match }) => {
  const { params, path } = match;
  const { surveyid, tableid } = params;

  const intl = useIntl();
  const translationPrefix = 'smoove.page.survey.tables';

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  const [tableConfig, setTableConfig] = useState(TABLE_INITIAL_STATE);
  const isTableConfigInitialState = isEqual(tableConfig, TABLE_INITIAL_STATE);
  const previousTableConfig = usePrevious(tableConfig);

  const survey = useSelector(s => s.survey);
  const tables = survey.tables;
  const table = tables?.list?.[tableid ?? '__tmp'];
  const tableResult = table?.result;
  const tableResultError = useSelector(
    s => s.survey.tables?.[tableid]?.error ?? s.survey?.tables?.['__tmp']?.error ?? null
  );
  const isLoading = useSelector(
    s => s.survey.tables?.list[tableid]?.loading ?? s.survey?.tables?.list['__tmp']?.loading ?? tables?.loading ?? false
  );
  const selectedLocale = useSelector(
    state => state.projectSettings?.[surveyid]?.selectedLocale ?? state.survey.locales.main
  );

  const savedTableConfig = useMemo(() => tables.list[tableid] ?? null, [tableid, tables]);

  const { matrixType, isCompactMatrix } = useMatrixType(tableConfig);

  const [isTableChanged, setIsTableChanged] = useState(false);
  const [isLeftSidebarOpenMobile, setIsLeftSidebarOpenMobile] = useState(true);
  const [isRightSidebarOpenMobile, setIsRightSidebarOpenMobile] = useState(true);

  const [leftSideBarSize, toggleLeftSidebar] = useSidebarToggle();
  const [rightSideBarSize, toggleRightSideBar] = useSidebarToggle();

  const isWriteable = useIsQueWriteable(survey?.status, false) && userCanEditProject;

  /**
   * Update table config if tableid changes in route
   */
  useEffect(() => {
    if (Object.values(tables.list ?? {}).length > 0 && tableid && !tables.list[tableid]) {
      history.push(generatePath(path, { ...params, tableid: undefined }));
    }
    if (tableid !== tableConfig?.id && tables?.list[tableid]) {
      setTableConfig(tables.list[tableid]);
    }
  }, [tableid, tables, tableConfig?.id, history, path, params]);

  const { isEmpty, resultTableType } = useResultTableType(tableConfig);

  /**
   * Set default table type, if resultTableType updates
   */
  useEffect(() => {
    setTableConfig(state => {
      if (resultTableType && !tableTypeChartTypes[resultTableType].includes(state.chart.chartType)) {
        return produce(state, draft => {
          draft.chart.chartType = tableTypeChartTypes[resultTableType][0];
        });
      } else {
        return state;
      }
    });
  }, [resultTableType]);

  /**
   * Questions:
   * - Keep data locally or add unsaved states to the redux store whilst working on it?
   * - Should it be persisted/ warning displayed when component unmounts and the user has an unsaved state?
   */

  useEffect(() => {
    if (!surveyid || !tableConfig?.id || !previousTableConfig?.id) return;

    if (survey.loading) return;
    if (tableConfig.loading) return;

    // skip, if no rows in table
    if (tableConfig?.rows?.order?.length <= 0) return;

    // skip, if no result and table is already loading
    if (!tableResult && table?.loading) return;

    // modifications which should trigger result loading
    if (
      !tableResult ||
      tableConfig.rows.list !== previousTableConfig.rows.list ||
      tableConfig.heads.list !== previousTableConfig.heads.list ||
      tableConfig.filters !== previousTableConfig.filters ||
      tableConfig.config.calculationOrder !== previousTableConfig.config.calculationOrder ||
      tableConfig.config.sortation !== previousTableConfig.config.sortation ||
      tableConfig.config.valueSignificances !== previousTableConfig.config.valueSignificances ||
      tableConfig.config.valueSignificancesBenchmark !== previousTableConfig.config.valueSignificancesBenchmark ||
      tableConfig.config.weightResult !== previousTableConfig.config.weightResult ||
      tableConfig.config.weightVariable !== previousTableConfig.config.weightVariable
    ) {
      tablesActions.loadTableResults(surveyid, tableConfig, selectedLocale);
    }
  }, [surveyid, selectedLocale, tableConfig, previousTableConfig, tableResult, survey.loading, table?.loading]);

  const handler = useMemo(() => {
    return {
      setTableConfig: setTableConfig,
      saveTable: async table => {
        if (tableid) {
          const { id, ...tableData } = table;

          tablesActions.updateTable(surveyid, tableid, tableData).then(savedTable => {
            if (savedTable) {
              setTableConfig(savedTable);
              if (savedTable.id !== tableid) history.push(generatePath(path, { ...params, tableid: savedTable.id }));
            }
          });
        }
      },
      createTable: async table => {
        const { id, surveyId, ...tableData } = table;

        tablesActions.createTable(surveyid, tableData).then(table => {
          if (table) {
            history.push(generatePath(path, { ...params, tableid: table.id }));
            tablesActions.loadFolders(surveyid);
          }
        });
      },
      deleteTable: tableid => {
        tablesActions.deleteTable(surveyid, tableid).then(success => {
          tablesActions.loadFolders(surveyid);
          setTableConfig(TABLE_INITIAL_STATE);
          history.push(generatePath(path, { ...params, tableid: undefined }));
        });
      },
      /**
       * Switches the visibility of a subelement (choice/ scale/ unknown value).
       * The subelement can be shown, hidden or excluded.
       * @param {string} type rows | head
       * @param {string|null} mode null for show | hidden | excluded
       * @param {string} id
       * @param {string} subid
       * @param {string} subsubid
       */
      toggleSubelementVisibility: (type = 'rows', mode, id, subid, subsubid = null) => {
        let key = subid;
        if (subsubid) key = subsubid;

        setTableConfig(state =>
          produce(state, draftState => {
            if (isArray(draftState[type].list[id].config) || !draftState[type].list[id].config)
              draftState[type].list[id].config = { hidden: {}, excluded: {} };
            if (!draftState[type].list[id].config?.hidden || isArray(draftState[type].list[id].config.hidden))
              draftState[type].list[id].config.hidden = {};
            if (!draftState[type].list[id].config?.excluded || isArray(draftState[type].list[id].config.excluded))
              draftState[type].list[id].config.excluded = {};

            if (mode === null) {
              draftState[type].list[id].config.hidden[key] = false;
              draftState[type].list[id].config.excluded[key] = false;
            } else {
              if (draftState[type].list[id].config[mode]?.[key] === true) {
                draftState[type].list[id].config[mode][key] = false;
              } else {
                // reset both
                draftState[type].list[id].config.excluded[key] = false;
                draftState[type].list[id].config.hidden[key] = false;
                // set desired
                draftState[type].list[id].config[mode][key] = true;
              }
            }
          })
        );
      },
      toggleShowAll: (type = 'rows', id, subid, subsubid = null, subelements) => {
        setTableConfig(state =>
          produce(state, draftState => {
            if (draftState[type].list[id]?.config?.hidden) {
              subelements.order.forEach(_subId => {
                if (draftState[type].list[id].config.hidden[_subId] === true) {
                  draftState[type].list[id].config.hidden[_subId] = false;
                }
              });
            }
          })
        );
      },
      toggleHideAllOthers: (rowid, subid, subsubid = null, subelements) => {
        const id = rowid;
        const type = 'rows';

        let key = subid;
        if (subsubid) key = subsubid;

        setTableConfig(state =>
          produce(state, draftState => {
            if (isArray(draftState[type].list[id].config) || !draftState[type].list[id].config)
              draftState[type].list[id].config = { hidden: {}, excluded: {} };
            if (!draftState[type].list[id].config?.hidden || isArray(draftState[type].list[id].config.hidden))
              draftState[type].list[id].config.hidden = {};
            if (!draftState[type].list[id].config?.excluded || isArray(draftState[type].list[id].config.excluded))
              draftState[type].list[id].config.excluded = {};

            subelements.order.forEach(subelementid => {
              if (subelementid === key) {
                draftState[type].list[id].config.excluded[subelementid] = false;
                draftState[type].list[id].config.hidden[subelementid] = false;
              } else {
                if (draftState[type].list[id].config.excluded[subelementid] !== true) {
                  draftState[type].list[id].config.hidden[subelementid] = true;
                }
              }
            });
          })
        );
      },
      setLoopChoice: (rowId, loopParent, choiceid, isAddChoice = false) => {
        setTableConfig(state =>
          produce(state, draftState => {
            if (isArray(draftState.rows.list[rowId].config)) draftState.rows.list[rowId].config = {};
            if (!draftState.rows.list[rowId].config?.loop) draftState.rows.list[rowId].config.loop = {};

            if (!draftState.rows.list[rowId].config.loop?.[loopParent.id])
              draftState.rows.list[rowId].config.loop[loopParent.id] = {
                id: loopParent.id,
                included: {}
              };

            if (!choiceid) {
              delete draftState.rows.list[rowId].config.loop[loopParent.id];
            } else {
              const activeValues = Object.values(
                draftState.rows.list[rowId].config.loop[loopParent.id].included
              ).filter(Boolean);
              const currentValue = draftState.rows.list[rowId].config.loop[loopParent.id].included[choiceid] ?? false;

              /**
               * if another choice is active, and the current choice should be activated, check, if the current choice
               * should be added or replace the current selection. `isAddChoice` is true, when the shift
               */
              if (activeValues.length > 0 && currentValue === false && !isAddChoice) {
                draftState.rows.list[rowId].config.loop[loopParent.id].included = {};
              }
              draftState.rows.list[rowId].config.loop[loopParent.id].included[choiceid] = !currentValue;

              if (activeValues.length <= 0 && currentValue) {
                delete draftState.rows.list[rowId].config.loop[loopParent.id];
              }
            }
          })
        );
      },
      removeElement: (id, from = 'rows') => {
        if (['rows', 'heads'].indexOf(from) < 0) {
          console.log(`Invalid from type "${from}"`);
          return;
        }

        setTableConfig(state =>
          produce(state, draftState => {
            if (draftState[from].list[id].sourceType === 'calculation') {
              draftState.config.calculationOrder = draftState.config.calculationOrder.filter(
                order => order.elementId !== id
              );
            }

            delete draftState[from].list[id];
            draftState[from].order = draftState[from].order.filter(_id => _id !== id);
          })
        );
      },
      sortTable: (headid, subelementid) => {
        const directionOrder = ['desc', 'asc'];

        setTableConfig(
          produce(draftState => {
            if (!draftState?.config?.sortation) draftState.config.sortation = TABLE_INITIAL_STATE.config.sortation;

            if (
              draftState.config.sortation.headElemId === '__value__' &&
              draftState.config.sortation.headElemId === headid
            ) {
              const currentDirectionIndex = directionOrder.indexOf(draftState.config.sortation.direction);
              const nextDirectionIndex = (currentDirectionIndex + directionOrder.length - 1) % directionOrder.length;
              draftState.config.sortation.direction = directionOrder[nextDirectionIndex];
            } else if (
              draftState.config.sortation.headElemId === headid &&
              draftState.config.sortation.headChoiceId === subelementid &&
              directionOrder.indexOf(draftState.config.sortation.direction) < directionOrder.length - 1
            ) {
              const currentDirectionIndex = directionOrder.indexOf(draftState.config.sortation.direction);
              draftState.config.sortation.direction = directionOrder[currentDirectionIndex + 1];
            } else if (
              draftState.config.sortation.headElemId === headid &&
              draftState.config.sortation.headChoiceId === subelementid &&
              directionOrder.indexOf(draftState.config.sortation.direction) >= directionOrder.length - 1
            ) {
              draftState.config.sortation = { ...draftState.config.sortation, ...TABLE_INITIAL_STATE.config.sortation };
            } else {
              draftState.config.sortation = {
                ...draftState.config.sortation,
                headElemId: headid,
                headChoiceId: subelementid,
                direction: directionOrder[0]
              };
            }
          })
        );
      },
      sortChangeElementPinned: (mode, rowid, subelementid, subsubelementid = null) => {
        const elementkey = subsubelementid ?? subelementid;
        setTableConfig(
          produce(draftState => {
            if (isArray(draftState.rows.list[rowid].config)) draftState.rows.list[rowid].config = {};
            if (!draftState.rows.list[rowid]?.config?.sortation) {
              draftState.rows.list[rowid].config.sortation = { pinnedElements: {} };
            }
            if (
              !draftState.rows.list[rowid]?.config?.sortation?.pinnedElements ||
              isArray(draftState.rows.list[rowid]?.config?.sortation?.pinnedElements)
            ) {
              draftState.rows.list[rowid].config.sortation.pinnedElements = {};
            }

            const currentPinned = draftState.rows.list[rowid].config.sortation?.pinnedElements?.[elementkey] ?? null;
            if (currentPinned === null || currentPinned !== mode)
              draftState.rows.list[rowid].config.sortation.pinnedElements[elementkey] = mode;
            else if (currentPinned === mode)
              delete draftState.rows.list[rowid].config.sortation.pinnedElements[elementkey];
          })
        );
      },
      reorderDimension: (dimension = 'rows', source, destination) => {
        setTableConfig(s =>
          produce(s, d => {
            d[dimension].order = arrayMoveImmutable(d[dimension].order, source.index, destination.index);
          })
        );
      },
      setTableElementColor: (color, cellIdentifier) => {
        setTableConfig(
          produce(draftState => {
            if (!draftState.chart.colorsFixed) draftState.chart.colorsFixed = [];

            const currentColorIndex = (draftState.chart?.colorsFixed ?? []).findIndex(_c => {
              const { color, ..._cellIdentifier } = _c;
              return isEqual(_cellIdentifier, cellIdentifier);
            });

            if (currentColorIndex >= 0 && !color) {
              draftState.chart.colorsFixed.splice(currentColorIndex, 1);
            } else if (currentColorIndex >= 0 && color) {
              draftState.chart.colorsFixed[currentColorIndex].color = color;
            } else if (currentColorIndex < 0 && color) {
              draftState.chart.colorsFixed.push({ ...cellIdentifier, color });
            }
          })
        );
      },
      export: exportFileType => {
        if (['pptx', 'xlsx'].includes(exportFileType)) {
          setTableConfig(tableConfig => {
            tablesActions.exportFile(surveyid, tableConfig, exportFileType);
            return tableConfig;
          });
        } else if (exportFileType === 'image') {
          const chart = document.querySelectorAll('div.chart-container')?.[0] ?? false;
          if (chart) exportDomElementAsImage(chart);
        } else {
          console.log(`Export type ${exportFileType} not implemented!`);
        }
      }
    };
  }, [history, params, path, surveyid, tableid]);

  const clearTable = useCallback(() => {
    if (!tableid) {
      setTableConfig(TABLE_INITIAL_STATE);
    } else {
      setTableConfig(TABLE_INITIAL_STATE);
      history.push(generatePath(path, { ...params, tableid: null }));
    }
  }, [history, setTableConfig, tableid, path, params]);

  const resetTable = useCallback(() => {
    setTableConfig(savedTableConfig);
    setIsTableChanged(false);
  }, [setTableConfig, savedTableConfig]);

  if (!userCanEditProject)
    return (
      <div className='container-fluid base-content flex-fill mw-100 tables-page'>
        {intl.formatMessage({ id: 'smoove.page.access.sorry-you-dont-have-the-rights-to-view-this-page' })}
      </div>
    );

  return (
    <div className='container-fluid base-content flex-fill mw-100 tables-page'>
      <Helmet>
        <title>
          {survey?.title || ''} - {intl.formatMessage({ id: `core.menu.surveysubmenu.item.tables` })}
        </title>
      </Helmet>
      <Row>
        <Col sm='9'>
          <div className='d-flex align-items-center mb-2'>
            <h2 className='text-uppercase text-lgv fw-bold mb-0 mr-2'>
              {intl.formatMessage({ id: 'smoove.page.survey.tables.headline' })}
            </h2>
            {!tableResultError && <SyntheticDataWarning />}
          </div>
        </Col>
        <Col sm='3' className='d-flex justify-content-end align-items-center pr-0'>
          {userCanEditProject && <TabulationAssistant readonly={false} />}
          <LanguageSelectDropdown showToggleShortlabelsItem={true} writeable={isWriteable} />
        </Col>
      </Row>

      {/**
       * The Provider is also used in the `Questionnaire` component. When switching routes, the following error occurs:
       * `Cannot have two HTML5 backends at the same time`
       *
       * This should have been fixed in react-dnd 11.1.3 but the error still occurs.
       * The `HTML5Backend` seems to be not unmounted correctly. To fix this temporarily, the `DndProvider`
       * was moved to the next parent component (`Project`) and disabled in both child components (`Tables`, `Questionnaire`).
       */}
      {/* <DndProvider backend={HTML5Backend}> */}
      <Row className='mt-1'>
        <Col sm={12} className='d-md-none d-lg-none d-xl-none'>
          <SmvSidebarToggleButtonMobile
            toggleFn={setIsLeftSidebarOpenMobile}
            isOpen={isLeftSidebarOpenMobile}
            label={intl.formatMessage({ id: `${translationPrefix}.sidebar.toggle-sidebar-left-label-mobile` })}
          />
        </Col>
        <Col
          md={leftSideBarSize.md}
          lg={leftSideBarSize.lg}
          className={`tables-sidebar-left ${!isLeftSidebarOpenMobile ? 'tables-sidebar-left--closed' : ''}`}
        >
          <SmvSidebarToggleButton position='left' toggleFn={toggleLeftSidebar} isOpen={leftSideBarSize.open} />

          <ListGroup className='list-group--first-lvl'>
            <QuestionList />
            <TablesList
              surveyid={surveyid}
              tables={tables}
              readonly={false}
              initCollapseOpen={true}
              tablesHandler={handler}
              clearTable={clearTable}
              isTableConfigInitialState={isTableConfigInitialState}
            />
          </ListGroup>
        </Col>

        <Col
          md={12 - leftSideBarSize.md - rightSideBarSize.md}
          lg={12 - leftSideBarSize.lg - rightSideBarSize.lg}
          className='tables-page__table'
        >
          <ResultTable
            handler={handler}
            tableConfig={tableConfig}
            tableResult={tableResult}
            isCompactMatrix={isCompactMatrix}
            matrixType={matrixType}
            isEmpty={isEmpty}
            resultTableType={resultTableType}
            tableResultError={tableResultError}
            isTableChanged={isTableChanged}
            isLoading={isLoading}
          />
        </Col>
        <Col sm={12} className='d-md-none d-lg-none d-xl-none'>
          <SmvSidebarToggleButtonMobile
            toggleFn={setIsRightSidebarOpenMobile}
            isOpen={isRightSidebarOpenMobile}
            label={intl.formatMessage({ id: `${translationPrefix}.sidebar.toggle-sidebar-right-label-mobile` })}
          />
        </Col>
        <Col
          md={rightSideBarSize.md}
          lg={rightSideBarSize.lg}
          className={`tables-sidebar-right ${!isRightSidebarOpenMobile ? 'tables-sidebar-right--closed' : ''}`}
        >
          <SmvSidebarToggleButton position='right' toggleFn={toggleRightSideBar} isOpen={rightSideBarSize.open} />
          <ListGroup className='tables-sidebar-right__content list-group--first-lvl'>
            <TableConfig
              handler={handler}
              tableConfig={tableConfig}
              tableResult={tableResult}
              isEmpty={isEmpty}
              resultTableType={resultTableType}
            />
            <ChartConfig
              handler={handler}
              chartConfig={tableConfig?.chart}
              tableConfig={tableConfig}
              isEmpty={isEmpty}
              matrixType={matrixType}
              resultTableType={resultTableType}
              tableResult={tableResult}
            />
            <TableButtons
              handler={handler}
              table={tableConfig}
              resetTable={resetTable}
              clearTable={clearTable}
              isTableChanged={isTableChanged}
              isEmpty={isEmpty}
            />
          </ListGroup>
        </Col>
      </Row>
      {/* </DndProvider> */}
    </div>
  );
};
