import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import produce from 'immer';
import {
  ResponsiveContainer,
  BarChart as BarChartReCharts,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  LabelList,
  Label,
  Rectangle
} from 'recharts';
import { rgb } from 'd3';

import {
  CustomizedAxisTick,
  useChartData,
  getSchemeColor,
  getFormattedValue,
  useLegendMouseEffects,
  getFixedColor,
  getContrastColor,
  flattenKeys
} from './helper';

import './barChart.scss';

export const BarChart = ({
  elementid,
  chartConfig,
  table,
  // tableConfig,
  tableResult,
  activeSignificanceType = null,
  getOpacitySignificance,
  handler
}) => {
  const [chartData, chartDimensions] = useChartData({
    chartConfig,
    table,
    tableResult,
    transpose: chartConfig?.tableTranspose ?? false
  });

  const selectedClientId = useSelector(state => state.clients.selected);
  const colorScales = useSelector(
    state => state.clients?.list?.[selectedClientId]?.colorScales ?? { list: {}, order: [] }
  );

  /**
   * DOMAIN
   */
  const domain = useMemo(() => {
    if (chartConfig?.chartSubtype === 'stacked') return [0, 100];
    else {
      // domain min/max from chart config otherwise "dataMax"
      if (!!chartConfig?.chartDomainMax && !isNaN(parseFloat(chartConfig.chartDomainMax))) {
        return [0, parseFloat(chartConfig.chartDomainMax)];
      } else {
        return [0, 'auto'];
      }
    }
  }, [chartConfig?.chartSubtype, chartConfig?.chartDomainMax]);

  const valueFormatter = useCallback(value => getFormattedValue(value, table.config), [table.config]);

  /**
   * AXIS
   */
  const { xAxis, yAxis } = useMemo(() => {
    let xAxis, yAxis;

    if (!chartConfig?.chartOrientation || chartConfig.chartOrientation === 'horizontal') {
      xAxis = (
        <XAxis
          type={'number'}
          domain={domain}
          allowDataOverflow={chartConfig?.chartSubtype === 'stacked' ? true : false}
        />
      );

      let yAxisWidth = 60;
      if (!!chartConfig?.axisYwidth) {
        let value = parseInt(chartConfig.axisYwidth);
        if (isFinite(value)) yAxisWidth = value;
      }
      yAxis = (
        <YAxis
          dataKey='name'
          type='category'
          orientation={'left'}
          width={yAxisWidth}
          reversed={chartConfig?.axisYreversed ?? false}
        />
      );
    } else if (chartConfig.chartOrientation === 'vertical') {
      xAxis = (
        <XAxis dataKey='name' interval={0} tick={<CustomizedAxisTick code={elementid} elementid={elementid} />} />
      );
      yAxis = (
        <YAxis
          type={'number'}
          domain={domain}
          allowDataOverflow={chartConfig?.chartSubtype === 'stacked' ? true : false}
        />
      );
    }

    return { xAxis, yAxis };
  }, [chartConfig, domain, elementid]);

  /**
   * LEGEND EFFECTS
   */
  const flattendHiddenSeries = useMemo(() => {
    if (chartConfig.legendSaveHiddenSeries ?? false) {
      return flattenKeys(chartConfig.legendHiddenSeries ?? {});
    } else {
      return {};
    }
  }, [chartConfig.legendSaveHiddenSeries, chartConfig.legendHiddenSeries]);
  const { onMouseEnter, onMouseLeave, getOpacity, toggleSeries, isHidden, hiddenSeries } = useLegendMouseEffects({
    hiddenSeries: flattendHiddenSeries
  });

  useEffect(() => {
    if (handler?.setTableConfig) {
      handler.setTableConfig(s => {
        // hidden series should be saved, and the current state is different to stored state => update table config
        if (
          s.chart.legendSaveHiddenSeries === true &&
          !isEqual(flattenKeys(s.chart.legendHiddenSeries), hiddenSeries)
        ) {
          return produce(s, d => {
            d.chart.legendHiddenSeries = hiddenSeries;
          });
        }
        // hidden series should not be saved, but the stored state not empty => update table config
        else if (s.chart.legendSaveHiddenSeries !== true && !isEqual(s.chart.legendHiddenSeries, {})) {
          return produce(s, d => {
            d.chart.legendHiddenSeries = {};
          });
        }
        // no update required, series should not be saved or stored state equals current state
        else {
          return s;
        }
      });
    }
  }, [handler, hiddenSeries]);

  if (!chartData || chartData.length <= 0) return null;

  const { legendIconType, legendIconSize } = chartConfig;

  const colorSchemaMap = {};

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChartReCharts
        width={200}
        height={120}
        data={chartData}
        layout={chartConfig?.chartOrientation === 'horizontal' ? 'vertical' : 'horizontal'}
      >
        <defs>
          {chartDimensions.map((dimension, index) => {
            const { colorSchema, colorSchemaReverse } = chartConfig;

            let scalePointValue = index / (chartDimensions.length - 1);
            if (isNaN(scalePointValue)) scalePointValue = 0;
            if (colorSchemaReverse) scalePointValue = 1 - scalePointValue;

            let { color, contrastColor } = getSchemeColor({ colorScales, colorSchema, value: scalePointValue });
            const fixedColor = getFixedColor(chartConfig, dimension?.cellIdentifier);
            if (fixedColor) {
              color = fixedColor.hex;
              contrastColor = getContrastColor(rgb(fixedColor.hex));
            }

            colorSchemaMap[dimension.id] = {
              color: color,
              text: contrastColor
            };

            return (
              <linearGradient
                key={`color_${elementid}_${dimension.id}`}
                id={`color_${elementid}_${dimension.id}`}
                x1='0'
                y1='0'
                x2='0'
                y2='1'
              >
                <stop offset='5%' stopColor={color} stopOpacity={0.9} />
                <stop offset='95%' stopColor={color} stopOpacity={0.5} />
              </linearGradient>
            );
          })}
        </defs>
        {(chartConfig?.showGrid ?? false) && <CartesianGrid strokeDasharray='3 3' />}

        {xAxis}
        {yAxis}

        {(chartConfig?.showTooltip ?? true) && (
          <Tooltip formatter={valueFormatter} labelFormatter={label => <strong>{label}</strong>} />
        )}

        {(chartConfig?.showLegend ?? true) && (
          <Legend
            iconType={legendIconType ?? 'square'}
            iconSize={legendIconSize ?? 14}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={toggleSeries}
            formatter={value => <span style={{ color: '#666' }}>{value}</span>}
          />
        )}

        {(chartConfig?.dimensionsReversed === true ? [...chartDimensions].reverse() : [...chartDimensions]).map(
          dimension => {
            if (chartConfig?.chartSubtype === 'stacked') {
              return (
                <Bar
                  key={dimension.id}
                  name={dimension.label}
                  dataKey={`${dimension.id}.value`}
                  fill={`url(#color_${elementid}_${dimension.id})`}
                  stackId='a'
                  opacity={getOpacity(dimension.id)}
                  isAnimationActive={false}
                  hide={isHidden(`${dimension.id}.value`)}
                  shape={
                    <CustomizedRect
                      dataKey={`${dimension.id}.value`}
                      opacity={getOpacity(dimension.id)}
                      significancesShow={chartConfig?.significancesShow ?? false}
                      getOpacitySignificance={getOpacitySignificance}
                      activeSignificanceType={activeSignificanceType}
                      chartConfig={chartConfig}
                    />
                  }
                >
                  {chartConfig?.valuesShow && (
                    <LabelList
                      dataKey={dimension.id}
                      position='centerTop'
                      opacity={activeSignificanceType ? 0.2 : getOpacity(dimension.id)}
                      fill={colorSchemaMap[dimension.id].text}
                      stroke={'none'}
                      content={payload => {
                        let { content, fill, width, ...rest } = payload;

                        const cellIdentifier = payload?.value?.cellIdentifier;
                        if (!cellIdentifier) return null;
                        const fixedColor = getFixedColor(chartConfig, cellIdentifier);
                        if (fixedColor && chartConfig?.valuesValuePosition !== 'outside') {
                          fill = getContrastColor(fixedColor.rgb);
                        }

                        return (
                          <Label fill={fill} width={!isNaN(width) ? width : 0} {...rest}>
                            {valueFormatter(payload.value.value)}
                          </Label>
                        );
                      }}
                    />
                  )}
                </Bar>
              );
            } else {
              let position = 'top';

              if (chartConfig?.chartOrientation === 'horizontal') {
                position = (chartConfig?.valuesValuePosition ?? 'inside') === 'outside' ? 'right' : 'insideRight';
              } else {
                position = (chartConfig?.valuesValuePosition ?? 'inside') === 'outside' ? 'top' : 'insideTop';
              }
              return (
                <Bar
                  key={dimension.id}
                  name={dimension.label}
                  dataKey={`${dimension.id}.value`}
                  fill={`url(#color_${elementid}_${dimension.id})`}
                  opacity={getOpacity(dimension.id)}
                  isAnimationActive={false}
                  hide={isHidden(`${dimension.id}.value`)}
                  shape={
                    <CustomizedRect
                      dataKey={`${dimension.id}.value`}
                      opacity={getOpacity(dimension.id)}
                      significancesShow={chartConfig?.significancesShow ?? false}
                      getOpacitySignificance={getOpacitySignificance}
                      activeSignificanceType={activeSignificanceType}
                      chartConfig={chartConfig}
                    />
                  }
                >
                  {chartConfig?.valuesShow && (
                    <LabelList
                      dataKey={`${dimension.id}`}
                      position={position}
                      fill={
                        (chartConfig?.valuesValuePosition ?? 'inside') === 'outside'
                          ? '#000'
                          : colorSchemaMap[dimension.id].text
                      }
                      opacity={activeSignificanceType !== null ? 0.2 : getOpacity(dimension.id)}
                      stroke={'none'}
                      content={payload => {
                        let { content, fill, width, ...rest } = payload;

                        const cellIdentifier = payload?.value?.cellIdentifier;
                        const fixedColor = getFixedColor(chartConfig, cellIdentifier);
                        if (fixedColor && chartConfig?.valuesValuePosition !== 'outside') {
                          fill = getContrastColor(fixedColor.rgb);
                        }
                        return (
                          <Label fill={fill} width={!isNaN(width) ? width : 0} {...rest}>
                            {valueFormatter(payload?.value?.value)}
                          </Label>
                        );
                      }}
                    />
                  )}
                </Bar>
              );
            }
          }
        )}
      </BarChartReCharts>
    </ResponsiveContainer>
  );
};

const CustomizedRect = props => {
  let fill = props.fill;
  const {
    chartConfig,
    dataKey,
    x,
    y,
    width,
    height,
    opacity,
    significancesShow,
    getOpacitySignificance,
    activeSignificanceType
  } = props;
  if (isNaN(width) || isNaN(height)) return null;

  const _dataKey = dataKey?.split('.')?.[0];
  if (!_dataKey) return null;
  const cellIdentifier = props?.[_dataKey]?.cellIdentifier;
  const fixedColor = getFixedColor(chartConfig, cellIdentifier);
  if (fixedColor) fill = fixedColor.hex;

  const significance = props?.significances[_dataKey];

  const _opacity =
    activeSignificanceType && getOpacitySignificance !== undefined ? getOpacitySignificance(significance) : opacity;

  if (significancesShow && significance) {
    const sigHighColor = '#008000';
    const sigLowColor = '#FF0000';
    const sigMutuallyColor = '#ac4bbd';

    const stroke =
      significance === 'high'
        ? sigHighColor
        : significance === 'low'
        ? sigLowColor
        : significance === 'both'
        ? sigMutuallyColor
        : '#fff';
    const strokeWidth = 2;

    return (
      <>
        <rect
          width={width - strokeWidth}
          height={height - strokeWidth}
          x={x + strokeWidth / 2}
          y={y + strokeWidth / 2}
          fill={'#fff'}
          stroke={stroke}
          strokeWidth={strokeWidth}
          opacity={_opacity}
        />
        <rect
          width={width - strokeWidth * 4}
          height={height - strokeWidth * 4}
          x={x + strokeWidth * 2}
          y={y + strokeWidth * 2}
          fill={fill}
          opacity={_opacity}
        />
      </>
    );
  } else {
    return <Rectangle width={width} height={height} x={x} y={y} fill={fill} opacity={_opacity} />;
    // <rect width={width} height={height} x={x} y={y} fill={fill} opacity={_opacity} />;
  }
};
