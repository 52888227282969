import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { dataPrepActions } from 'smv-redux';
import { useUserHasPermission, permissionEnum } from 'smv-helpers';
import { DataWeighting, DataCalculation } from '../components/data-preparation';
import { PageHeadline } from '../components';

import './Data.scss';

export const Data = () => {
  const intl = useIntl();

  const survey = useSelector(state => state.survey);

  const data = survey?.data ?? {};
  const isLoading = data?.isLoading ?? false;

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  // useEffect(() => {
  //   if (!lastInsertId) return;
  //   // scroll down to newly added weight/calculation only if lastInsertId changed
  //   if (lastInsertId) {
  //     const element = document.getElementById(lastInsertId);

  //     setTimeout(() => {
  //       const offset = 120;
  //       const bodyRect = document.body.getBoundingClientRect().top;
  //       const elementRect = element.getBoundingClientRect().top;
  //       const elementPosition = elementRect - bodyRect;
  //       const offsetPosition = elementPosition - offset;

  //       window.scrollTo({
  //         top: offsetPosition,
  //         behavior: 'smooth'
  //       });
  //     }, 100);

  //     const classes = ['smv-scroll-indicator'];
  //     element.classList.add(...classes);
  //     setTimeout(() => element.classList.remove(...classes), 1500);
  //   }
  //   //TODO: set lastinsertid null after scrolling? Right now it also scrolls if the id is the same and sometihng else changes, eg expertMode
  // }, [lastInsertId]);

  useEffect(() => {
    if (survey?.id) {
      dataPrepActions.getDataPrepBySurveyId(survey.id).then(res => {
        if (res?.data === null) {
          dataPrepActions.addDataPrep(survey?.id);
        }
      });
    }
  }, [survey?.id]);

  if (!data) {
    return (
      <div className='container-fluid base-content flex-fill mw-100 tables-page'>
        Sorry, something went wrong. Try reloading the page.
      </div>
    );
  }

  if (!userCanEditProject)
    return (
      <div className='container-fluid base-content flex-fill mw-100 tables-page'>
        {intl.formatMessage({ id: 'smoove.page.access.sorry-you-dont-have-the-rights-to-view-this-page' })}
      </div>
    );

  return (
    <div className='smv-data-page container-fluid base-content flex-fill'>
      <Helmet>
        <title>
          {survey?.title ?? ''} - {intl.formatMessage({ id: `core.menu.surveysubmenu.item.data` })}
        </title>
      </Helmet>
      <Row>
        <Col sm='12' className='d-flex align-items-center'>
          <PageHeadline headline={'smoove.page.survey.data.headline'} />
          {isLoading && <i className='fal fa-spinner fa-spin ml-2 mb-4'></i>}
        </Col>
      </Row>

      <DataWeighting />
      <hr className='my-4' />
      <DataCalculation />
    </div>
  );
};
