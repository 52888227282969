import React, { useCallback, useMemo, useState } from 'react';
import { Collapse, ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import produce from 'immer';

import { isNumeric } from 'smv-helpers';
import {
  GeneralChartConfig,
  ChartDimensionsConfig,
  ChartAxisConfig,
  ChartValueConfig,
  ChartSignificancesConfig,
  ChartLegendConfig,
  ChartTooltipConfig,
  ChartWordcloudConfig,
  ChartTableHighlightConfig
} from './';

import '../ResultConfig.scss';

export const ChartConfig = ({
  handler,
  chartConfig,
  tableConfig,
  isEmpty,
  resultTableType,
  matrixType,
  tableResult
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = useCallback(
    () =>
      setIsOpen(b => {
        if (isEmpty) return false;
        else return !b;
      }),
    [isEmpty]
  );

  // generic handlers ------------------
  const setSelectValue = useCallback(
    e => {
      const { name, value } = e.target;
      // convert pure number strings to number
      let _value;
      if (!isNaN(+value)) _value = +value;
      else _value = value;

      handler.setTableConfig(state =>
        produce(state, draftState => {
          if (!draftState?.chart) draftState.chart = {};
          draftState.chart[name] = _value;
        })
      );
    },
    [handler]
  );

  const setCheckboxValue = useCallback(
    e => {
      const { name, checked } = e.target;
      handler.setTableConfig(state =>
        produce(state, draftState => {
          if (!draftState?.chart) draftState.chart = {};
          draftState.chart[name] = checked;
        })
      );
    },
    [handler]
  );
  // ------------------------------------

  const defaultHandler = useMemo(
    () => ({
      setCheckboxValue,
      setSelectValue
    }),
    [setCheckboxValue, setSelectValue]
  );

  const generalChartConfigHandler = useMemo(() => {
    return {
      setChartType: e => {
        const { value } = e.target;
        handler.setTableConfig(state =>
          produce(state, draftState => {
            if (!draftState?.chart) draftState.chart = {};
            draftState.chart.chartType = value;
            draftState.chart.tableTranspose = value === 'scatter';
            draftState.chart.valuesValuePosition = 'inside';
            if (value === 'bar' && state.chart.chartType !== 'bar' && state.chart.chartSubtype === 'independent') {
              draftState.chart.chartSubtype = 'grouped';
            }
            if (value !== 'bar' && state.chart.chartType === 'bar' && state.chart.chartSubtype === 'grouped') {
              draftState.chart.chartSubtype = 'independent';
            }
          })
        );
      },
      setChartOrientation: setSelectValue,
      setChartSubtype: setSelectValue,
      setChartColorSchema: setSelectValue,
      setCheckboxValue: setCheckboxValue
    };
  }, [handler, setCheckboxValue, setSelectValue]);

  const axisHandler = useMemo(() => {
    return {
      setChartDomainAuto: setCheckboxValue,
      setChartDomainRange: e => {
        const { value, name } = e.target;
        handler.setTableConfig(state =>
          produce(state, draftState => {
            if (!draftState?.chart) draftState.chart = {};
            const _value = isNumeric(value) ? parseFloat(value) : null;
            draftState.chart[name] = _value;
          })
        );
      },
      setChartAxisYwidth: setSelectValue,
      setCheckboxValue: setCheckboxValue,
      setSelectValue: setSelectValue
    };
  }, [handler, setCheckboxValue, setSelectValue]);

  const scattedDimensionsHandler = useMemo(() => {
    return {
      setTableConfig: handler.setTableConfig,
      setSelectValue: setSelectValue
    };
  }, [handler, setSelectValue]);

  return (
    <ListGroupItem className='result-config'>
      <ListGroupItemHeading
        onClick={toggleOpen}
        className={classNames({
          'd-flex align-items-center py-1 list-item-sticky': true,
          'pointer': !isEmpty
        })}
      >
        <i className={`far fa-chevron-${isOpen ? 'down' : 'right'}`}></i>
        <span className='ml-1'>
          <FormattedMessage id={'smoove.page.tables.chart-config.chart-config'} defaultMessage={'Chart Config'} />
        </span>
      </ListGroupItemHeading>
      <Collapse isOpen={!isEmpty && isOpen}>
        <ListGroup flush={true}>
          <GeneralChartConfig
            chartConfig={chartConfig}
            resultTableType={resultTableType}
            matrixType={matrixType}
            handler={generalChartConfigHandler}
          />
          {chartConfig?.chartType === 'scatter' && (
            <ChartDimensionsConfig
              chartConfig={chartConfig}
              tableConfig={tableConfig}
              tableResult={tableResult}
              resultTableType={resultTableType}
              handler={scattedDimensionsHandler}
            />
          )}
          <ChartAxisConfig chartConfig={chartConfig} resultTableType={resultTableType} handler={axisHandler} />
          <ChartValueConfig chartConfig={chartConfig} resultTableType={resultTableType} handler={defaultHandler} />
          <ChartSignificancesConfig chartConfig={chartConfig} resultTableType={resultTableType} handler={handler} />
          <ChartTableHighlightConfig
            tableConfig={tableConfig}
            chartConfig={chartConfig}
            resultTableType={resultTableType}
            handler={handler}
          />
          <ChartLegendConfig chartConfig={chartConfig} resultTableType={resultTableType} handler={defaultHandler} />
          <ChartTooltipConfig chartConfig={chartConfig} resultTableType={resultTableType} handler={defaultHandler} />
          <ChartWordcloudConfig
            chartConfig={chartConfig}
            resultTableType={resultTableType}
            handler={handler}
            tableResult={tableResult}
          />
        </ListGroup>
      </Collapse>
    </ListGroupItem>
  );
};
