import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Store as notificationStore } from 'react-notifications-component';

import { RowLabel } from 'smv-components';
import { permissionEnum, useUserHasPermission } from 'smv-helpers';
import { dataPrepActions } from 'smv-redux/actions';

import { Calculation } from '.';

export const DataCalculation = () => {
  const projectid = useSelector(state => state.survey.projectid);
  const surveyid = useSelector(state => state.survey.id);
  const surveyData = useSelector(state => state.survey?.data);

  const intl = useIntl();

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  const calculationHandler = useMemo(() => {
    return {
      addCalculationValue: (calculationId, data) => {
        dataPrepActions.addCalculationValue(surveyid, calculationId, data);
      },
      updateCalculationValue: (calculationId, valueId, data) => {
        dataPrepActions.updateCalculationValue(surveyid, calculationId, valueId, data);
      },
      deleteCalculationValue: (calculationId, valueId) => {
        dataPrepActions.deleteCalculationValue(surveyid, calculationId, valueId);
      },
      addCalculation: () => {
        dataPrepActions.addCalculation(surveyid);
      },
      deleteCalculation: id => {
        dataPrepActions.deleteCalculation(surveyid, id);
      },
      updateCalculation: (id, updatedCalculation) => {
        dataPrepActions.updateCalculation(surveyid, id, updatedCalculation);
      }
    };
  }, [surveyid]);

  const applyCalculations = () => {
    dataPrepActions
      .applyCalculations(projectid, surveyid)
      .then(response => {
        notificationStore.addNotification({
          type: 'success',
          container: 'bottom-right',
          title: 'Success',
          message: 'The recalculation was triggered, depending on the amount of data, this may take some time.',
          dismiss: {
            showIcon: true,
            duration: 5000,
            onScreen: true,
            pauseOnHover: true
          }
        });
      })
      .catch(error => {
        const defaultErrorMessage = 'There was an error in the recalculation of the data. Please check your entries.';
        notificationStore.addNotification({
          type: 'danger',
          container: 'bottom-right',
          title: 'Error',
          message: error?.error ?? defaultErrorMessage,
          dismiss: {
            showIcon: true,
            duration: 5000,
            onScreen: true,
            pauseOnHover: true
          }
        });
      });
  };

  const hasCalculations = (surveyData ?? {})?.calculations?.length > 0;

  return (
    <>
      <Row>
        <RowLabel
          name={'calculations'}
          title={intl.formatMessage({ id: 'smoove.page.data.calculation.calculations' })}
          tooltip={intl.formatMessage({ id: 'smoove.page.data.calculation.calculations-tooltip' })}
        />
      </Row>

      {hasCalculations &&
        surveyData.calculations.map(calculation => (
          <Calculation key={calculation.id} calculation={calculation} calculationHandler={calculationHandler} />
        ))}

      {!hasCalculations && (
        <Row>
          <Col className='mb-2'>
            <i>
              <FormattedMessage
                id={'smoove.page.data.calculation.no-calculations-defined'}
                defaultMessage={'No calculations defined'}
              />
            </i>
          </Col>
        </Row>
      )}
      {userCanEditProject && (
        <Row>
          <Col lg={12} xl={12} className={'d-flex mb-2'}>
            <Button onClick={() => calculationHandler.addCalculation()} color='primary'>
              <FormattedMessage
                id={'smoove.page.data.calculation.add-calculation'}
                defaultMessage={'Add calculation'}
              />
            </Button>
            {hasCalculations && (
              <Button onClick={applyCalculations} color={'secondary'} className={'ml-2'}>
                <FormattedMessage
                  id={'smoove.page.data.calculation.apply-calculations'}
                  defaultMessage={'Recalculate'}
                />
              </Button>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
