import React, { useMemo } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RowLabel } from 'smv-components';
import { Weight } from '.';
// import { SmvToggleExpertModeSwitch } from '../../misc/SmvToggleExpertModeSwitch';
import { dataPrepActions } from 'smv-redux';
import { permissionEnum, useUserHasPermission } from 'smv-helpers';

export const DataWeighting = () => {
  const surveyId = useSelector(state => state.survey.id);
  const surveyData = useSelector(state => state.survey?.data);
  const intl = useIntl();

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  const weightHandler = useMemo(() => {
    return {
      addWeight: () => {
        dataPrepActions.addWeight(surveyId);
      },
      deleteWeight: id => {
        dataPrepActions.deleteWeight(surveyId, id);
      },
      updateWeight: (id, updatedWeight) => {
        dataPrepActions.updateWeight(surveyId, id, updatedWeight);
      }
    };
  }, [surveyId]);

  return (
    <>
      <Row>
        <RowLabel
          name={'weighting'}
          title={intl.formatMessage({ id: 'smoove.page.data.weight.weights' })}
          tooltip={intl.formatMessage({ id: 'smoove.page.data.weight.weights-tooltip' })}
        />
        {/* {userCanEditProject && (
          <Col>
            <div className='d-flex justify-content-end align-items-center ml-4'>
              <div className='mr-2'>{intl.formatMessage({ id: 'smoove.common.project.expert-mode' })}</div>
              <SmvToggleExpertModeSwitch />
            </div>
          </Col>
        )} */}
      </Row>
      <Row>
        <Col md={12}>
          <p>
            To use weighting in smoove, you need to create a new weight and upload a .csv file containing the weight
            definition. The contents of this file need to fit to your data, we do not validate it in any way. If the
            results break when using the weight, please check if the data inside the file is correct.
          </p>
        </Col>
      </Row>

      {(surveyData ?? {})?.weights?.map(weight => (
        <Weight key={weight.id} surveyId={surveyId} weight={weight} weightHandler={weightHandler} />
      ))}

      {(surveyData ?? {})?.weights?.length === 0 && (
        <Row>
          <Col className='mb-2'>
            <i>{intl.formatMessage({ id: 'smoove.page.data.weight.no-weights-defined' })}</i>
          </Col>
        </Row>
      )}
      <Row>
        <Col lg={12} xl={12} className={'d-flex mb-2'}>
          <Button onClick={() => weightHandler.addWeight()} color='primary' disabled={!userCanEditProject}>
            {intl.formatMessage({ id: 'smoove.page.data.weight.add-weight' })}
          </Button>
        </Col>
      </Row>
    </>
  );
};
