import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col, Input, Collapse, FormGroup } from 'reactstrap';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { ConfirmAction } from 'smv-components/modals';

import { InlineEdit } from 'smv-components';
import { permissionEnum, useUserHasPermission } from 'smv-helpers';
import { CalculationValue } from './CalculationValue';
import './Calculation.scss';

export const Calculation = ({ calculation, calculationHandler }) => {
  // const expertMode = useSelector(state => state.smooveSettings?.user?.expertMode ?? false);

  const intl = useIntl();

  const userCanEditProject = useUserHasPermission(permissionEnum.PROJECT_CHANGE);

  const [isOpen, setIsOpen] = useState(false);

  const handler = useMemo(() => {
    return {
      addValue: (newValue = {}) => {
        calculationHandler.addCalculationValue(calculation.id, newValue);
      },
      updateValue: (id, value) => {
        calculationHandler.updateCalculationValue(calculation.id, id, value);
      },
      deleteValue: id => {
        calculationHandler.deleteCalculationValue(calculation.id, id);
      },
      setLabel: value => {
        calculationHandler.updateCalculation(calculation.id, { label: value });
      },
      setVarname: value => {
        calculationHandler.updateCalculation(calculation.id, { varname: value });
      },
      setMultiple: value => {
        calculationHandler.updateCalculation(calculation.id, { multiple: value });
      }
    };
  }, [calculation, calculationHandler]);

  return (
    <div className='smv-calculation' id={calculation.id}>
      <Row className='mb-1 d-flex align-items-center justify-content-between'>
        <Col md={{ size: 4, offset: 0 }}>
          <div className='d-flex align-items-center'>
            <i
              onClick={() => setIsOpen(b => !b)}
              id={`toggle_${calculation.id}_container`}
              className={`pointer fas fa-fw fa-chevron-right rotate ${isOpen ? 'rotate-90' : ''}`}
            ></i>
            <UncontrolledTooltip
              className='tooltip-test'
              placement='top'
              target={`toggle_${calculation.id}_container`}
              fade={true}
            >
              {isOpen ? (
                <FormattedMessage id={'smoove.common.buttons.hide'} defaultMessage={'Hide'} />
              ) : (
                <FormattedMessage id={'smoove.common.buttons.show'} defaultMessage={'Show'} />
              )}
            </UncontrolledTooltip>
            <b
              className='bold pointer mr-1'
              onClick={() => {
                setIsOpen(b => !b);
              }}
            >
              <FormattedMessage
                id={'smoove.page.data.calculation.calculation-label'}
                defaultMessage={'Calculation Name:'}
              />
            </b>
            <InlineEdit
              className='smv-calculation__title'
              defaultValue={calculation.label || ''}
              placeholder={intl.formatMessage({ id: `smoove.page.data.calculation.calculation-placeholder` })}
              saveFn={value => handler.setLabel(value)}
              disabled={!userCanEditProject}
            />
          </div>
        </Col>

        <Col md={{ size: 4, offset: 0 }}>
          <div className='d-flex align-items-center'>
            <b className='bold mr-1'>
              <FormattedMessage id={'smoove.page.data.calculation.variable-name'} defaultMessage={'Variable name:'} />
            </b>
            <InlineEdit
              className='smv-calculation__title'
              defaultValue={calculation.varname || ''}
              placeholder={intl.formatMessage({ id: `smoove.page.data.calculation.enter-variable-name` })}
              saveFn={value => handler.setVarname(value)}
              disabled={!userCanEditProject}
            />
          </div>
        </Col>

        <Col md={{ size: 2 }}>
          <div className='d-flex align-items-center'>
            <b className='bold mr-2'>
              <FormattedMessage id={'smoove.page.data.calculation.multi'} defaultMessage={'Multi:'} />
            </b>
            <FormGroup switch>
              <Input
                type='switch'
                role='switch'
                id={`multi_${calculation.id}`}
                name='multi'
                checked={calculation?.multiple || false}
                onChange={e => handler.setMultiple(e.target.checked)}
                disabled={!userCanEditProject}
              />
            </FormGroup>
          </div>
        </Col>
        <Col md={{ size: 1, offset: 0 }} className='d-flex justify-content-end'>
          <Button
            color='primary'
            outline
            className='mr-1'
            onClick={() => {
              const length = calculation.values.length;
              handler.addValue({ label: '', value: length >= 0 ? length + 1 : 0 });
              setIsOpen(true);
            }}
            id={`add_calc_${calculation.id}`}
            disabled={!userCanEditProject}
          >
            <i className='fal fa-plus'></i>
          </Button>
          <UncontrolledTooltip placement='top' target={`add_calc_${calculation.id}`} fade={true}>
            <FormattedMessage id={'smoove.page.data.calculation.add-value'} defaultMessage={'Add value:'} />
          </UncontrolledTooltip>

          {userCanEditProject && (
            <>
              <ConfirmAction
                title={intl.formatMessage({ id: `smoove.page.data.calculation.delete-calculation` })}
                size={'lg'}
                body={
                  <div>{intl.formatMessage({ id: `smoove.page.data.calculation.delete-calculation-modal-text` })}</div>
                }
                callback={() => {
                  calculationHandler.deleteCalculation(calculation.id);
                }}
              >
                {confirm => (
                  <Button color='danger' outline className='mr-1' id={`del_value_${calculation.id}`} onClick={confirm}>
                    <i className={'fal fa-trash-alt pointer'} />
                  </Button>
                )}
              </ConfirmAction>
              <UncontrolledTooltip placement='top' target={`del_value_${calculation.id}`} fade={false}>
                <FormattedMessage
                  id={'smoove.page.data.calculation.delete-calculation'}
                  defaultMessage={'Delete calculation'}
                />
              </UncontrolledTooltip>
            </>
          )}
        </Col>
      </Row>
      <Collapse isOpen={isOpen}>
        {isOpen && (
          <>
            {calculation?.values?.map(value => {
              return <CalculationValue key={value.id} calculation={calculation} value={value} handler={handler} />;
            })}
          </>
        )}
      </Collapse>
    </div>
  );
};
