import React, { useState, useMemo, useCallback } from 'react';
import { Button, Input, UncontrolledTooltip } from 'reactstrap';
import { useIntl } from 'react-intl';
import { isEqualWith } from 'lodash';

import { stripHtml } from '../../helpers';
import { MultipleSelectDropdownQB } from '.';
import { isQueryBuilderEmptyState } from './helper';

const ruleConfig = {
  single_vector: {
    operators: [
      { value: 'EQUALS', label: `smoove.common.querybuilder.equals` },
      { value: 'ONE_OF', label: `smoove.common.querybuilder.one-of` },
      { value: 'NONE_OF', label: `smoove.common.querybuilder.none-of` }
    ]
  },
  multiple_vector: {
    operators: [
      { value: 'ONE_OF', label: `smoove.common.querybuilder.one-of` },
      { value: 'ALL_OF', label: `smoove.common.querybuilder.all-of` },
      { value: 'NONE_OF', label: `smoove.common.querybuilder.none-of` }
    ]
  },
  single_input_number: {
    operators: [
      { value: 'EQUALS', label: `smoove.common.querybuilder.equals` },
      { value: 'GE', label: `smoove.common.querybuilder.greater-than-or-equal` },
      { value: 'LE', label: `smoove.common.querybuilder.less-than-or-equal` }
    ]
  },
  multiple_input_number: {
    operators: [
      { value: 'EQUALS', label: `smoove.common.querybuilder.equals` },
      { value: 'GE', label: `smoove.common.querybuilder.greater-than-or-equal` },
      { value: 'LE', label: `smoove.common.querybuilder.less-than-or-equal` }
    ]
  },
  single_input_text: {
    operators: [
      { value: 'EQUALS', label: `smoove.common.querybuilder.equals` },
      { value: 'NOT_EQUALS', label: `smoove.common.querybuilder.not-equals` },
      { value: 'ONE_OF', label: `smoove.common.querybuilder.one-of` },
      { value: 'NONE_OF', label: `smoove.common.querybuilder.none-of` }
    ]
  },
  single_matrix: {
    operators: [
      { value: 'EQUALS', label: `smoove.common.querybuilder.equals` },
      { value: 'NOT_EQUALS', label: `smoove.common.querybuilder.not-equals` },
      { value: 'ONE_OF', label: `smoove.common.querybuilder.one-of` },
      { value: 'NONE_OF', label: `smoove.common.querybuilder.none-of` }
    ],
    choice: true
  },
  multiple_matrix: {
    operators: [
      { value: 'ONE_OF', label: `smoove.common.querybuilder.one-of` },
      { value: 'ALL_OF', label: `smoove.common.querybuilder.all-of` },
      { value: 'NONE_OF', label: `smoove.common.querybuilder.none-of` }
    ],
    choice: true
  },
  custom_shelf: { operators: [], input: '' }
};

export const Rule = ({ fields, rule, handler, translations, locale, readOnly = false, queryBuilder }) => {
  const [sourceConfig, setSourceConfig] = useState({ qtype: '' });

  const isDeleteButtonDisabled = isQueryBuilderEmptyState(queryBuilder);

  const sourceKey = useMemo(() => {
    const { sourceId, sourceLoopConfig } = rule;
    if (!sourceId) return null;

    const sourceElement = fields.find(field => {
      if (!sourceLoopConfig) return field.element.id === sourceId;
      else return field.element.id === sourceId && isEqualWith(sourceLoopConfig, field?.sourceLoopConfig);
    });

    if (!sourceElement) return null;
    setSourceConfig(sourceElement.element.config);

    return sourceElement.id;
  }, [fields, rule]);

  const intl = useIntl();

  const getInputType = () => {
    if (['single_input_number', 'multiple_input_number'].includes(sourceConfig.qtype)) return 'number';
    if (['single_input_text', 'multiple_input_text'].includes(sourceConfig.qtype)) return 'text';
    if (rule.operator === 'EQUALS' || rule.operator === 'NOT_EQUALS') return 'singleselect';
    return 'multiselect';
  };

  return (
    <div className='querybuilder__group-rule'>
      <div className={'row pl-3'}>
        {/* Source question */}
        <div className='querybuilder__group-rule-line-container pb-2'>
          <Input
            disabled={readOnly}
            type='select'
            name={`rule${rule.id}_var`}
            value={sourceKey ?? ''}
            className={`${sourceKey ? '' : 'querybuilder__select--invalid'}`}
            onChange={({ target }) => {
              if (!target?.value) return;
              const sourceElement = fields.find(field => field.id === target.value);
              if (!sourceElement) return;

              handler.updateRule({ ...rule, sourceId: sourceElement.element.id });
              // if (!!sourceElement?.sourceLoopConfig) updateRule('sourceLoopConfig', sourceElement.sourceLoopConfig);
            }}
          >
            <option value={''} disabled>
              {intl.formatMessage({ id: `smoove.common.querybuilder.select-source`, defaultMessage: 'select source' })}
            </option>
            <SourceOptions fields={fields} />
          </Input>
          {!sourceKey && (
            <small className='text-warning'>
              {intl.formatMessage({ id: 'smoove.common.querybuilder.please-select-a-source' })}
            </small>
          )}
        </div>

        {/* Choice for single matrix and multiple matrix */}
        {sourceConfig.qtype && ruleConfig[sourceConfig.qtype] && ruleConfig[sourceConfig.qtype].choice && (
          <div className='col-md-2 pr-0 pb-2'>
            <Input
              disabled={readOnly}
              type='select'
              name={`rule${rule.id}_choice`}
              value={rule?.choiceId ?? ''}
              onChange={e => handler.updateRule({ ...rule, choiceId: e.target.value })}
              className={`${rule?.choiceId ? '' : 'querybuilder__select--invalid'}`}
            >
              <option value={''} disabled>
                {intl.formatMessage({ id: `smoove.common.querybuilder.select-item`, defaultMessage: 'select item' })}
              </option>
              {sourceConfig?.choices?.order?.length > 0 &&
                sourceConfig?.choices?.order?.map(id => (
                  <option key={id} value={sourceConfig.choices.list[id].id}>
                    {stripHtml(translations?.[sourceConfig.choices.list[id].config.translations.label]?.[locale] ?? '')}
                  </option>
                ))}
            </Input>
            {!rule?.choiceId && (
              <small className='text-warning'>
                {intl.formatMessage({ id: 'smoove.common.querybuilder.please-select-an-item' })}
              </small>
            )}
          </div>
        )}

        {(sourceConfig.qtype === 'multiple_input_number' || sourceConfig.qtype === 'multiple_input_text') && (
          <div className='col-md-2 pr-0 pb-2'>
            <Input
              disabled={readOnly}
              type='select'
              name={`rule${rule.id}_choice`}
              value={rule?.choiceId ?? ''}
              onChange={e => handler.updateRule({ ...rule, choiceId: e.target.value })}
              className={`${rule?.choiceId ? '' : 'querybuilder__select--invalid'}`}
            >
              <option value={''} disabled>
                {intl.formatMessage({ id: `smoove.common.querybuilder.select-item`, defaultMessage: 'select item' })}
              </option>

              {(() => {
                const options = [];
                for (let index = 1; index <= sourceConfig.inputCount; index++) {
                  options.push(
                    <option key={`choice-${index}`} value={index}>
                      Input {index}
                    </option>
                  );
                }
                return options;
              })()}
            </Input>
            {!rule?.choiceId && (
              <small className='text-warning'>
                {intl.formatMessage({ id: 'smoove.common.querybuilder.please-select-an-item' })}
              </small>
            )}
          </div>
        )}

        {/* Condition */}
        {ruleConfig[sourceConfig.qtype] && (
          <div className='col-md-2 pr-0 pb-2'>
            <Input
              disabled={readOnly}
              type='select'
              name={`rule${rule.id}_opr`}
              value={rule.operator || ''}
              className={`${rule.operator ? '' : 'querybuilder__select--invalid'}`}
              onChange={e => handler.updateRule({ ...rule, operator: e.target.value })}
            >
              <option value={''} disabled>
                {intl.formatMessage({
                  id: `smoove.common.querybuilder.select-condition`,
                  defaultMessage: 'select condition'
                })}
              </option>
              {ruleConfig[sourceConfig.qtype].operators.map(operator => (
                <option key={operator.value} value={operator.value}>
                  {intl.formatMessage({ id: operator.label })}
                </option>
              ))}
            </Input>
            {!rule?.operator && (
              <small className='text-warning'>
                {intl.formatMessage({ id: 'smoove.common.querybuilder.please-select-condition' })}
              </small>
            )}
          </div>
        )}

        {/* Value */}
        {ruleConfig[sourceConfig.qtype] && (
          <div className='col-md-4 pr-0 pb-2'>
            {!rule?.operator ? (
              <Input
                disabled={true}
                placeholder={intl.formatMessage({ id: `smoove.common.querybuilder.select-value` })}
              ></Input>
            ) : (
              <InputSelector
                readOnly={readOnly}
                key={sourceConfig.text}
                rule={rule}
                handler={handler}
                translations={translations}
                locale={locale}
                inputType={getInputType()}
                choiceList={
                  ruleConfig[sourceConfig.qtype].choice ? sourceConfig?.scales?.list : sourceConfig?.choices?.list
                }
              />
            )}
          </div>
        )}

        <div className='col-auto pb-2 mt-1'>
          <Button
            disabled={readOnly || isDeleteButtonDisabled}
            color={isDeleteButtonDisabled ? 'grey' : 'danger'}
            size={'sm'}
            outline={true}
            onClick={e => {
              handler.removeRule(rule);
            }}
            id={'rmrule_' + rule.id}
          >
            <i className='fal fa-trash-alt' />
            {!isDeleteButtonDisabled && (
              <UncontrolledTooltip target={'rmrule_' + rule.id}>
                {intl.formatMessage({ id: `smoove.common.querybuilder.remove` })}
              </UncontrolledTooltip>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

const SourceOptions = ({ fields }) => {
  return fields.map(field => (
    <option key={field.id} value={field.id}>
      {field.element?.config?.varname ?? ''}
      {field.element?.config?.varname ? ' - ' : ''}
      {field.label ?? 'unnamed element'}
    </option>
  ));
};

const InputSelector = ({ rule, handler, inputType, choiceList, translations, locale, readOnly = false }) => {
  const intl = useIntl();

  const getLabel = useCallback(
    item => {
      return stripHtml(translations?.[item?.config?.translations?.label]?.[locale]) ?? item.label;
    },
    [locale, translations]
  );

  const options = useMemo(() => {
    return choiceList
      ? Object.values(choiceList).map(item => {
          return {
            id: item.id,
            label: getLabel(item)
          };
        })
      : [];
  }, [choiceList, getLabel]);

  const [_input, setInput] = useState(rule?.values?.[0] ?? '');

  switch (inputType) {
    case 'number':
    case 'text': {
      return (
        <>
          <Input
            disabled={readOnly}
            type={inputType}
            name={`rule_${rule.id}`}
            value={_input}
            className={`${!rule?.values?.[0] ? 'querybuilder__select--invalid' : ''}`}
            onChange={e => setInput(e.target.value)}
            onBlur={({ target }) => target?.value && handler.updateRule({ ...rule, values: [target.value] })}
          />
          {!rule?.values?.[0] && (
            <small className='text-warning'>
              {intl.formatMessage({ id: 'smoove.common.querybuilder.please-enter-a-value' })}
            </small>
          )}
        </>
      );
    }
    case 'singleselect': {
      // operator "EQUALS" is selected
      return (
        <>
          <Input
            // disabled={readOnly}
            type='select'
            value={rule?.values?.[0] || ''}
            placeholder={intl.formatMessage({
              id: `smoove.common.querybuilder.select-value`,
              defaultMessage: 'select values'
            })}
            className={`${!rule?.values?.[0] ? 'querybuilder__select--invalid' : ''}`}
            onChange={({ target }) => target?.value && handler.updateRule({ ...rule, values: [target.value] })}
          >
            <option value={''} disabled>
              {intl.formatMessage({ id: `smoove.common.querybuilder.select-value` })}
            </option>
            {Object.values(choiceList).map(item => (
              <option key={item.id} value={item.id} disabled={readOnly}>
                {getLabel(item)}
              </option>
            ))}
          </Input>
          {!rule?.values?.[0] && (
            <small className='text-warning'>
              {intl.formatMessage({ id: 'smoove.common.querybuilder.please-select-a-value' })}
            </small>
          )}
        </>
      );
    }
    case 'multiselect': {
      return (
        <MultipleSelectDropdownQB
          readOnly={readOnly}
          options={options}
          values={rule.values}
          onChange={values => handler.updateRule({ ...rule, values: values ?? [] })}
          placeholder={intl.formatMessage({
            id: `smoove.common.querybuilder.select-value`,
            defaultMessage: 'select values'
          })}
        />
      );
    }
    default:
      return (
        <div>{intl.formatMessage({ id: 'smoove.common.querybuilder.input-type-not-implemented-or-invalid' })}</div>
      );
  }
};
