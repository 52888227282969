export const getHighestHeadBase = (
  tableValues = {},
  headid,
  headsubelementid,
  isDetailedMatrix = false,
  baseMetric = 'smvrslt_base'
) => {
  let hightestBaseValue = 0;

  if (!isDetailedMatrix) {
    Object.values(tableValues).forEach(row =>
      Object.values(row).forEach(rowchoice => {
        if ((rowchoice?.[headid]?.[headsubelementid]?.[baseMetric] ?? 0) > hightestBaseValue) {
          hightestBaseValue = parseFloat(rowchoice?.[headid]?.[headsubelementid]?.[baseMetric]);
        }
      })
    );
  } else {
    Object.values(tableValues).forEach(row =>
      Object.values(row).forEach(rowsubelement =>
        Object.values(rowsubelement).forEach(rowsubsubelement => {
          if ((rowsubsubelement?.[headid]?.[headsubelementid]?.[baseMetric] ?? 0) > hightestBaseValue) {
            hightestBaseValue = parseFloat(rowsubsubelement?.[headid]?.[headsubelementid]?.[baseMetric]);
          }
        })
      )
    );
  }

  return hightestBaseValue;
};
