/**
 * Returns the formated value
 *
 * @param {object} cell Cell results or undefined
 * @param {string} metric Metric
 * @param {object} config Configuration object
 */
export function getCellValue(cell, metric, config = {}) {
  if (!cell) return '-';
  const { valuePrecision = 0, valueForcePrecision = false } = config;

  let value = cell?.[metric];

  if (!value) return '-';

  if (metric === 'smvrslt_perc') value *= 100;

  if (!Number.isInteger(value)) {
    value = Math.round(value * Math.pow(10, valuePrecision)) / Math.pow(10, valuePrecision);
  }

  if (valuePrecision > 0 && valueForcePrecision) {
    value = value.toFixed(valuePrecision);
  }

  return `${value}`;
}
