import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { QuestionConfigModal, ContainerConfigModal, PageConfigModal } from '.';

export const ElementConfigSwitch = ({ writeable }) => {
  const { elementid } = useParams();
  const element = useSelector(state => state.survey.questionnaire.list?.[elementid]);
  if (element?.type === 'question') return <QuestionConfigModal question={element} writeable={writeable} />;
  else if (element?.type === 'container') return <ContainerConfigModal container={element} writeable={writeable} />;
  else if (element?.type === 'page') return <PageConfigModal page={element} writeable={writeable} />;
  else return <div>Unsupported element type.</div>;
};
