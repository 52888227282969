import Axios from 'axios';
import { Store as notify } from 'react-notifications-component';
// import { customAlphabet } from 'nanoid'

import 'smv-redux/axiosConfig';
import { intl, store } from 'smv-redux';
import { dataPrepActionTypes } from '../constants';
import { defaultNotifyProps } from 'smv-constants';
import { parseErrorAsMessageString } from 'src/smoove/helpers/parseErrorAsMessageString';

/**
 * Saves a weight element
 *
 * @param {string} surveyid
 * @param {object} content Element content
 */
// const saveWeight = (surveyid, content) => {
//   console.log('old backend request!');
// const elementid = content?.id || customAlphabet('1234567890abcdef', 24)();

// const defaultContent = {
//   id: elementid,
//   label: '',
//   type: null,
//   config: { mode: 'individual' },
//   definitions: { list: {}, order: [] }
// };

// const _content = _.merge(defaultContent, content);

// store.dispatch({
//   type: dataPrepActionTypes.SAVE_DATAPREP_ELEMENT_REQUEST,
//   payload: { elementid, type: 'weights' }
// });

// const data = new FormData();
// data.append(
//   'payload',
//   JSON.stringify({
//     projectid: surveyid,
//     surveyid: surveyid,
//     content: _content
//   })
// );

// Axios.post(`/jsonrpc.php`, data, {
//   params: {
//     program: 'Smoove',
//     controller: 'XSurvey',
//     action: 'saveWeight'
//   }
// })
//   .then(res => {
//     const { weight } = res.data.result;
//     store.dispatch({
//       type: dataPrepActionTypes.SAVE_DATAPREP_ELEMENT_SUCCESS,
//       payload: {
//         type: 'weights',
//         elementid: elementid,
//         element: weight
//       }
//     });
//   })
//   .catch(err => {
//     store.dispatch({
//       type: dataPrepActionTypes.SAVE_DATAPREP_ELEMENT_FAILURE,
//       payload: {
//         type: 'weights',
//         elementid: elementid,
//         error: err
//       }
//     });
//   });
// };

/**
 * Deletes a weight element
 *
 * @param {string} surveyid
 * @param {string} weightid
 */
// const deleteWeight = (surveyid, weightid) => {
//   console.log('old backend request');
// store.dispatch({
//   type: dataPrepActionTypes.DELETE_DATAPREP_ELEMENT_REQUEST,
//   payload: { elementid: weightid, type: 'weights' }
// });

// const data = new FormData();
// data.append(
//   'payload',
//   JSON.stringify({
//     projectid: surveyid,
//     surveyid: surveyid,
//     weightid: weightid
//   })
// );

// Axios.post(`/jsonrpc.php`, data, {
//   params: {
//     program: 'Smoove',
//     controller: 'XSurvey',
//     action: 'deleteWeight'
//   }
// })
//   .then(res => {
//     // const { weight } = res.data.result;
//     store.dispatch({
//       type: dataPrepActionTypes.DELETE_DATAPREP_ELEMENT_SUCCESS,
//       payload: {
//         type: 'weights',
//         elementid: weightid
//       }
//     });
//   })
//   .catch(err => {
//     store.dispatch({
//       type: dataPrepActionTypes.DELETE_DATAPREP_ELEMENT_FAILURE,
//       payload: {
//         type: 'weights',
//         elementid: weightid,
//         error: err
//       }
//     });
//   });
// };

const loadWeightingFactors = (surveyid, weight) => {
  console.log('old backend request');
  // store.dispatch({
  //   type: dataPrepActionTypes.LOAD_DATAPREP_WEIGHTING_FACTORS_REQUEST,
  //   payload: { weight }
  // });

  // const data = new FormData();
  // data.append(
  //   'payload',
  //   JSON.stringify({
  //     projectid: surveyid,
  //     surveyid: surveyid,
  //     weight: weight
  //   })
  // );

  // return Axios.post(`/jsonrpc.php`, data, {
  //   params: {
  //     program: 'Smoove',
  //     controller: 'XSurvey',
  //     action: 'loadWeightingFactors'
  //   }
  // })
  //   .then(res => {
  //     const { weight } = res.data.result;
  //     store.dispatch({
  //       type: dataPrepActionTypes.LOAD_DATAPREP_WEIGHTING_FACTORS_SUCCESS,
  //       payload: {
  //         weight: weight
  //       }
  //     });

  //     return Promise.resolve(weight);
  //   })
  //   .catch(err => {
  //     console.log(err);

  //     store.dispatch({
  //       type: dataPrepActionTypes.LOAD_DATAPREP_WEIGHTING_FACTORS_FAILURE,
  //       payload: {
  //         weight: weight,
  //         error: err
  //       }
  //     });
  //   });
};

/**
 * Saves a calculation element
 *
 * @param {string} surveyid
 * @param {object} content Element content
 */
// const saveCalculation = (surveyid, content) => {
//   console.log('old backend request');
// const elementid = content?.id || customAlphabet('1234567890abcdef', 24)();

// const defaultContent = {
//   id: elementid,
//   label: '',
//   varname: ''
//   // config: { mode: 'individual' },
//   // definitions: { list: {}, order: [] }
// };

// const _content = _.merge(defaultContent, content);

// store.dispatch({
//   type: dataPrepActionTypes.SAVE_DATAPREP_ELEMENT_REQUEST,
//   payload: { elementid, type: 'calculations' }
// });

// const data = new FormData();
// data.append(
//   'payload',
//   JSON.stringify({
//     projectid: surveyid,
//     surveyid: surveyid,
//     content: _content
//   })
// );

// Axios.post(`/jsonrpc.php`, data, {
//   params: {
//     program: 'Smoove',
//     controller: 'XSurvey',
//     action: 'saveCalculation'
//   }
// })
//   .then(res => {
//     const { calculation } = res.data.result;
//     store.dispatch({
//       type: dataPrepActionTypes.SAVE_DATAPREP_ELEMENT_SUCCESS,
//       payload: {
//         type: 'calculations',
//         elementid: elementid,

//         // TODO
//         // Incorrect respones form backend
//         // on initial state, element.values.list should be an empty object, but currently array is returned
//         element: {
//           ...calculation,
//           values: { list: _.isArray(calculation.values.list) ? {} : calculation.values.list, order: [] }
//         }
//       }
//     });
//   })
//   .catch(err => {
//     store.dispatch({
//       type: dataPrepActionTypes.SAVE_DATAPREP_ELEMENT_FAILURE,
//       payload: {
//         type: 'calculations',
//         elementid: elementid,
//         error: err
//       }
//     });
//   });
// };

/**
 * Deletes a calculation element
 *
 * @param {string} surveyid
 * @param {string} calculationid
 */
// const deleteCalculation = (surveyid, calculationid) => {
//   console.log('old backend request');
// store.dispatch({
//   type: dataPrepActionTypes.DELETE_DATAPREP_ELEMENT_REQUEST,
//   payload: { elementid: calculationid, type: 'calculations' }
// });

// const data = new FormData();
// data.append(
//   'payload',
//   JSON.stringify({
//     projectid: surveyid,
//     surveyid: surveyid,
//     calculationid: calculationid
//   })
// );

// Axios.post(`/jsonrpc.php`, data, {
//   params: {
//     program: 'Smoove',
//     controller: 'XSurvey',
//     action: 'deleteCalculation'
//   }
// })
//   .then(res => {
//     // const { calculation } = res.data.result;
//     store.dispatch({
//       type: dataPrepActionTypes.DELETE_DATAPREP_ELEMENT_SUCCESS,
//       payload: {
//         type: 'calculations',
//         elementid: calculationid
//       }
//     });
//   })
//   .catch(err => {
//     store.dispatch({
//       type: dataPrepActionTypes.DELETE_DATAPREP_ELEMENT_FAILURE,
//       payload: {
//         type: 'calculations',
//         elementid: calculationid,
//         error: err
//       }
//     });
//   });
// };

/**
 * Saves a calculation value
 *
 * @param {string} surveyid
 * @param {object} content Element content
 */
// const saveCalculationValue = (surveyid, calculation, content) => {
//   console.log('old backend request');
// const elementid = content?.id || customAlphabet('1234567890abcdef', 24)();

// const defaultContent = {
//   id: elementid,
//   label: '',
//   value: null,
//   condition: null
// };

// const _content = _.merge(defaultContent, content);

// store.dispatch({
//   type: dataPrepActionTypes.SAVE_DATAPREP_ELEMENT_REQUEST,
//   payload: { elementid, calculationid: calculation.id, type: 'calculationValue' }
// });

// const data = new FormData();
// data.append(
//   'payload',
//   JSON.stringify({
//     projectid: surveyid,
//     surveyid: surveyid,
//     calculationid: calculation.id,
//     content: _content
//   })
// );

// Axios.post(`/jsonrpc.php`, data, {
//   params: {
//     program: 'Smoove',
//     controller: 'XSurvey',
//     action: 'saveCalculationValue'
//   }
// })
//   .then(res => {
//     const { value } = res.data.result;
//     store.dispatch({
//       type: dataPrepActionTypes.SAVE_DATAPREP_ELEMENT_SUCCESS,
//       payload: {
//         type: 'calculationValue',
//         calculationid: calculation.id,
//         elementid: elementid,
//         element: value
//       }
//     });
//   })
//   .catch(err => {
//     store.dispatch({
//       type: dataPrepActionTypes.SAVE_DATAPREP_ELEMENT_FAILURE,
//       payload: {
//         type: 'calculationValue',
//         calculationid: calculation.id,
//         elementid: elementid,
//         error: err
//       }
//     });
//   });
// };

/**
 * Deletes a calculation value
 *
 * @param {string} surveyid
 * @param {string} calculationid
 * @param {string} valueid
 */
// const deleteCalculationValue = (surveyid, calculationid, valueid) => {
// console.log('old backend request');
// store.dispatch({
//   type: dataPrepActionTypes.DELETE_DATAPREP_ELEMENT_REQUEST,
//   payload: { elementid: valueid, type: 'calculationValue' }
// });

// const data = new FormData();
// data.append(
//   'payload',
//   JSON.stringify({
//     projectid: surveyid,
//     surveyid: surveyid,
//     calculationid: calculationid,
//     valueid: valueid
//   })
// );

// Axios.post(`/jsonrpc.php`, data, {
//   params: {
//     program: 'Smoove',
//     controller: 'XSurvey',
//     action: 'deleteCalculationValue'
//   }
// })
//   .then(res => {
//     store.dispatch({
//       type: dataPrepActionTypes.DELETE_DATAPREP_ELEMENT_SUCCESS,
//       payload: {
//         type: 'calculationValue',
//         calculationid: calculationid,
//         elementid: valueid
//       }
//     });
//   })
//   .catch(err => {
//     store.dispatch({
//       type: dataPrepActionTypes.DELETE_DATAPREP_ELEMENT_FAILURE,
//       payload: {
//         type: 'calculationValue',
//         elementid: valueid,
//         error: err
//       }
//     });
//   });
// };

/**
 * Applies the configured calculations on stored respondent data
 *
 * @param {string} surveyid
 * @returns {Promise}
 */
const applyCalculations = (projectid, surveyid, calculationid = null) => {
  console.log('old backend request');
  // store.dispatch({
  //   type: dataPrepActionTypes.DATA_APPLY_CALCULATIONS_REQUEST,
  //   payload: { projectid, surveyid, calculationid }
  // });

  // const data = new FormData();
  // data.append(
  //   'payload',
  //   JSON.stringify({
  //     projectid,
  //     surveyid,
  //     calculationid
  //   })
  // );

  // return Axios.post(`/jsonrpc.php`, data, {
  //   params: {
  //     program: 'Smoove',
  //     controller: 'XSurvey',
  //     action: 'runCalculations'
  //   }
  // })
  //   .then(response => {
  //     if (response.data.result.status) {
  //       store.dispatch({
  //         type: dataPrepActionTypes.DATA_APPLY_CALCULATIONS_SUCCESS,
  //         payload: {
  //           projectid,
  //           surveyid,
  //           calculationid
  //         }
  //       });

  //       return response.data.result;
  //     } else {
  //       return Promise.reject(response.data.result);
  //     }
  //   })
  //   .catch(error => {
  //     store.dispatch({
  //       type: dataPrepActionTypes.DATA_APPLY_CALCULATIONS_FAILURE,
  //       payload: {
  //         projectid,
  //         surveyid,
  //         calculationid
  //       }
  //     });

  //     return Promise.reject(error);
  //   });
};

const addDataPrep = async surveyId => {
  store.dispatch({
    type: dataPrepActionTypes.ADD_DATA_PREP_REQUEST,
    payload: { surveyId }
  });

  return Axios.post(`/surveys/${surveyId}/data-preps`)
    .then(res => {
      const dataPrep = res.data;
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_FAILURE,
        payload: {
          error
        }
      });
    });
};

const getDataPrepBySurveyId = async surveyId => {
  store.dispatch({
    type: dataPrepActionTypes.GET_DATA_PREP_BY_SURVEY_ID_REQUEST,
    payload: { surveyId }
  });

  return Axios.get(`/surveys/${surveyId}/data-preps`)
    .then(res => {
      const dataPrep = res.data;
      store.dispatch({
        type: dataPrepActionTypes.GET_DATA_PREP_BY_SURVEY_ID_SUCCESS,
        payload: {
          surveyId,
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.GET_DATA_PREP_BY_SURVEY_ID_FAILURE,
        payload: {
          error
        }
      });
    });
};

// const updateDataPrep = async (surveyId, data) => {
//   console.log(data);
//   delete data.isLoading;
//   store.dispatch({
//     type: dataPrepActionTypes.UPDATE_DATA_PREP_REQUEST,
//     payload: { ...data }
//   });

//   return Axios.patch(`/surveys/${surveyId}/data-preps`, data)
//     .then(res => {
//       const dataPrep = res.data;
//       console.log(dataPrep);
//       store.dispatch({
//         type: dataPrepActionTypes.UPDATE_DATA_PREP_SUCCESS,
//         payload: {
//           dataPrep
//         }
//       });

//       return res;
//     })
//     .catch(error => {
//       console.log(error);
//       store.dispatch({
//         type: dataPrepActionTypes.UPDATE_DATA_PREP_FAILURE,
//         payload: {
//           error
//         }
//       });
//     });
// };

const addWeight = async surveyId => {
  store.dispatch({
    type: dataPrepActionTypes.ADD_DATA_PREP_WEIGHT_REQUEST,
    payload: surveyId
  });

  return Axios.post(`/surveys/${surveyId}/data-preps/weights`)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_WEIGHT_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_WEIGHT_FAILURE,
        payload: {
          error
        }
      });
    });
};

const updateWeight = async (surveyId, weightId, data) => {
  console.log(data);
  delete data.isLoading;
  store.dispatch({
    type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_REQUEST,
    payload: { ...data }
  });

  return Axios.patch(`/surveys/${surveyId}/data-preps/weights/${weightId}`, data)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_WEIGHT_FAILURE,
        payload: {
          error
        }
      });
    });
};

const deleteWeight = async (surveyId, weightId) => {
  store.dispatch({
    type: dataPrepActionTypes.DELETE_DATA_PREP_WEIGHT_REQUEST,
    payload: { surveyId, weightId }
  });

  return Axios.delete(`/surveys/${surveyId}/data-preps/weights/${weightId}`)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_DATA_PREP_WEIGHT_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_DATA_PREP_WEIGHT_FAILURE,
        payload: {
          error
        }
      });
    });
};

const addCalculation = async surveyId => {
  store.dispatch({
    type: dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_REQUEST,
    payload: surveyId
  });

  return Axios.post(`/surveys/${surveyId}/data-preps/calculations`)
    .then(res => {
      const dataPrep = res.data;
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_FAILURE,
        payload: {
          error
        }
      });
    });
};

const updateCalculation = async (surveyId, calculationId, data) => {
  console.log(data);
  delete data.isLoading;
  store.dispatch({
    type: dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_REQUEST,
    payload: { ...data }
  });

  return Axios.patch(`/surveys/${surveyId}/data-preps/calculations/${calculationId}`, data)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_FAILURE,
        payload: {
          error
        }
      });
    });
};

const deleteCalculation = async (surveyId, calculationId) => {
  store.dispatch({
    type: dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_REQUEST,
    payload: { surveyId, calculationId }
  });

  return Axios.delete(`/surveys/${surveyId}/data-preps/calculations/${calculationId}`)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_FAILURE,
        payload: {
          error
        }
      });
    });
};

const addCalculationValue = async (surveyId, calculationId, data) => {
  store.dispatch({
    type: dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_VALUE_REQUEST,
    payload: { ...data }
  });

  return Axios.post(`/surveys/${surveyId}/data-preps/calculations/${calculationId}/values`, data)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_VALUE_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.ADD_DATA_PREP_CALCULATION_VALUE_FAILURE,
        payload: {
          error
        }
      });
    });
};

const updateCalculationValue = async (surveyId, calculationId, valueId, data) => {
  console.log(data);
  delete data.isLoading;
  store.dispatch({
    type: dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_VALUE_REQUEST,
    payload: { ...data }
  });

  return Axios.patch(`/surveys/${surveyId}/data-preps/calculations/${calculationId}/values/${valueId}`, data)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_VALUE_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.UPDATE_DATA_PREP_CALCULATION_VALUE_FAILURE,
        payload: {
          error
        }
      });
    });
};

const deleteCalculationValue = async (surveyId, calculationId, valueId) => {
  store.dispatch({
    type: dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_VALUE_REQUEST,
    payload: { surveyId, calculationId }
  });

  return Axios.delete(`/surveys/${surveyId}/data-preps/calculations/${calculationId}/values/${valueId}`)
    .then(res => {
      const dataPrep = res.data;
      console.log(dataPrep);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_VALUE_SUCCESS,
        payload: {
          dataPrep
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_DATA_PREP_CALCULATION_VALUE_FAILURE,
        payload: {
          error
        }
      });
    });
};

const addCalculationValueConditionElement = async (surveyId, calculationId, valueId, data) => {
  delete data.isLoading;
  store.dispatch({
    type: dataPrepActionTypes.ADD_CALCULATION_VALUE_CONDITION_ELEMENT_REQUEST,
    payload: { ...data }
  });

  return Axios.post(`/surveys/${surveyId}/data-preps/calculations/${calculationId}/values/${valueId}/conditions`, data)
    .then(res => {
      const condition = res.data;
      store.dispatch({
        type: dataPrepActionTypes.ADD_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS,
        payload: {
          surveyId,
          calculationId,
          valueId,
          condition
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.ADD_CALCULATION_VALUE_CONDITION_ELEMENT_FAILURE,
        payload: {
          surveyId,
          calculationId,
          valueId,
          error
        }
      });

      return error;
    });
};

const removeCalculationValueConditionElement = async (surveyId, calculationId, valueId, condtionElementId) => {
  store.dispatch({
    type: dataPrepActionTypes.REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_REQUEST,
    payload: condtionElementId
  });

  return Axios.delete(
    `/surveys/${surveyId}/data-preps/calculations/${calculationId}/values/${valueId}/conditions/${condtionElementId}`
  )
    .then(res => {
      const condition = res.data;

      store.dispatch({
        type: dataPrepActionTypes.REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS,
        payload: {
          surveyId,
          calculationId,
          valueId,
          condition
        }
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: dataPrepActionTypes.REMOVE_CALCULATION_VALUE_CONDITION_ELEMENT_FAILURE,
        payload: {
          error
        }
      });

      return error;
    });
};

const updateCalculationValueConditionElement = async (surveyId, calculationId, valueId, condtionElementId, data) => {
  delete data.isLoading;
  store.dispatch({
    type: dataPrepActionTypes.UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_REQUEST,
    payload: { ...data }
  });
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/surveys/${surveyId}/data-preps/calculations/${calculationId}/values/${valueId}/conditions/${condtionElementId}`,
      {
        ...data,
        id: undefined
      }
    )
      .then(res => {
        const condition = res.data;
        store.dispatch({
          type: dataPrepActionTypes.UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_SUCCESS,
          payload: {
            surveyId,
            calculationId,
            valueId,
            condition
          }
        });

        resolve(res);
      })
      .catch(error => {
        store.dispatch({
          type: dataPrepActionTypes.UPDATE_CALCULATION_VALUE_CONDITION_ELEMENT_FAILURE,
          payload: {
            error
          }
        });

        reject(error);
      });
  });
};

const uploadWeightCsvFile = (surveyId, weightId, csvFile, callbackFn = () => {}) => {
  store.dispatch({
    type: dataPrepActionTypes.UPLOAD_WEIGHT_CSV_REQUEST,
    payload: { surveyId, weightId }
  });

  const fd = new FormData();
  fd.append('file', csvFile);
  fd.append('name', csvFile.name);
  Axios.post(`/surveys/${surveyId}/data-preps/weights/${weightId}/csv`, fd)
    .then(response => {
      const data = response.data;
      const dataPrep = data.data;
      store.dispatch({
        type: dataPrepActionTypes.UPLOAD_WEIGHT_CSV_SUCCESS,
        payload: {
          dataPrep
        }
      });
      callbackFn(false);

      notify.addNotification({
        title: intl.formatMessage({
          id: 'smoove.page.survey.data.weights.upload-csv',
          defaultMessage: `Successfully uploaded`
        }),

        type: 'success',
        ...defaultNotifyProps
      });

      return { dataPrep };
    })
    .catch(err => {
      console.log(err);
      callbackFn(err);
      store.dispatch({
        type: dataPrepActionTypes.UPLOAD_WEIGHT_CSV_FAILURE,
        payload: {
          err
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(err),
        type: 'danger',
        ...defaultNotifyProps
      });
    });
};

const deleteWeightCsvFile = (surveyId, weightId) => {
  store.dispatch({
    type: dataPrepActionTypes.DELETE_WEIGHT_CSV_REQUEST,
    payload: { surveyId, weightId }
  });

  Axios.delete(`/surveys/${surveyId}/data-preps/weights/${weightId}/csv`)
    .then(response => {
      const dataPrep = response.data;
      store.dispatch({
        type: dataPrepActionTypes.DELETE_WEIGHT_CSV_SUCCESS,
        payload: {
          dataPrep
        }
      });

      notify.addNotification({
        title: intl.formatMessage({
          id: 'smoove.page.survey.data.weights.delete-csv',
          defaultMessage: `Successfully deleted`
        }),

        type: 'success',
        ...defaultNotifyProps
      });

      return { dataPrep };
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: dataPrepActionTypes.DELETE_WEIGHT_CSV_FAILURE,
        payload: {
          err
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(err),
        type: 'danger',
        ...defaultNotifyProps
      });
    });
};

export const dataPrepActions = {
  // weight
  // saveWeight,
  // deleteWeight,
  loadWeightingFactors,

  // calculations
  // saveCalculation,
  addCalculation,
  updateCalculation,
  deleteCalculation,
  // saveCalculationValue,
  addCalculationValue,
  updateCalculationValue,
  deleteCalculationValue,
  applyCalculations,

  addDataPrep,
  getDataPrepBySurveyId,
  // updateDataPrep,

  addCalculationValueConditionElement,
  updateCalculationValueConditionElement,
  removeCalculationValueConditionElement,

  // weights
  addWeight,
  updateWeight,
  deleteWeight,
  uploadWeightCsvFile,
  deleteWeightCsvFile
};
