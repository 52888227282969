import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';

import { Store as notify } from 'react-notifications-component';
import { defaultNotifyProps } from 'smv-constants';
import { Button, Col, Input, FormGroup, Row, Label } from 'reactstrap';

import { ConfirmAction, RowLabel } from 'smv-components';
import { surveyActions } from 'smv-redux';

export const DataActions = ({ survey }) => {
  const intl = useIntl();

  const [flush, setFlush] = useState(false);
  const [runCalculations, setRunCalculations] = useState(true);
  const [importCsvFile, setImportCsvFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [asyncDataImportStatus, setAsyncDataImportStatus] = useState({ importing: false, from: '', progress: 0 });
  const [fileSize, setFileSize] = useState(0);

  const toggleFlush = useCallback(() => setFlush(b => !b), []);
  const toggleCalculations = useCallback(() => setRunCalculations(b => !b), []);
  const handleCsvFileChange = useCallback(e => {
    const selectedFile = e.target.files[0];
    setFileSize(0);
    if (selectedFile) {
      // Check whether the file extension is '.csv'
      if (selectedFile.name.endsWith('.csv') || selectedFile.name.endsWith('.zip')) {
        setImportCsvFile(selectedFile);
        const fileSizeMb = (selectedFile.size / (1024 * 1024)).toFixed(2);
        setFileSize(fileSizeMb);
      } else {
        e.target.value = null;
      }
    }
  }, []);

  const showNotification = useCallback(() => {
    notify.addNotification({
      title: intl.formatMessage({
        id: 'smoove.page.project.general.data-actions.import.tivian-id.error',
        defaultMessage: 'Missing Tivian ID'
      }),
      type: 'danger',
      ...defaultNotifyProps
    });
  }, [intl]);

  const importPolling = useCallback(() => {
    if (asyncDataImportStatus?.importing) {
      axios
        .get(`/surveys/${survey.id}/importDataStatus`)
        .then(res => {
          setAsyncDataImportStatus(res.data);
          if (res.data.importing) {
            setTimeout(() => {
              importPolling();
            }, 3000);
          } else {
            notify.addNotification({
              title: intl.formatMessage({
                id: 'survey-data-import-success',
                defaultMessage: 'Successfully imported data.'
              }),
              type: 'success',
              ...defaultNotifyProps
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [survey.id, asyncDataImportStatus?.importing, intl]);

  const checkImportStatus = useCallback(() => {
    if (!survey.id) return;
    axios
      .get(`/surveys/${survey.id}/importDataStatus`)
      .then(res => {
        setAsyncDataImportStatus(res.data);
        if (res.data.importing) {
          importPolling();
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [survey.id, importPolling]);

  const importTivianDataHandling = useCallback(() => {
    setIsLoading(true);
    surveyActions
      .runDataImport(survey.id, flush, runCalculations)
      .then(res => {
        setIsLoading(false);
        checkImportStatus();
      })
      .catch(err => {
        setIsLoading(false);
      });
  }, [survey.id, flush, runCalculations, checkImportStatus]);

  const importCsvHandling = useCallback(() => {
    setIsLoading(true);
    surveyActions
      .runCsvDataImport(survey.id, importCsvFile, flush, runCalculations)
      .then(res => {
        setIsLoading(false);
        checkImportStatus();
      })
      .catch(err => {
        setIsLoading(false);
      });
  }, [survey.id, importCsvFile, flush, runCalculations, checkImportStatus]);

  const recalculateHandling = useCallback(() => surveyActions.runDataCalculations(survey.id), [survey.id]);

  useEffect(() => {
    checkImportStatus();
  }, [checkImportStatus]);

  return (
    <>
      <Row className='my-4'>
        <RowLabel
          name={'data-actions'}
          title={intl.formatMessage({
            id: 'smoove.page.project.general.data-actions.label',
            defaultMessage: 'Async Data Actions'
          })}
          tooltip={intl.formatMessage({
            id: 'smoove.page.project.general.data-actions.tooltip',
            defaultMessage: 'Async Data Actions'
          })}
        />
        <Col md={7}>
          {!!survey?.questback?.id && (
            <ConfirmAction
              title={intl.formatMessage({
                id: 'smoove.page.project.general.data-actions.import.title',
                defaultMessage: 'Import tivian data'
              })}
              size={'md'}
              body={
                <>
                  <Row>
                    <Col>
                      {intl.formatMessage({
                        id: `smoove.page.project.general.data-actions.import.text`,
                        defaultMessage:
                          'Performs a raw data import from Tivian. A full import first deletes all data and then imports a new data set from Tivian. Otherwise, an incremental import is performed and only new cases are imported from Tivian.\n\nThe import can be performed with or without calculations.'
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup switch>
                        <Input
                          type='switch'
                          role='switch'
                          id={`flushData`}
                          name={`flushData`}
                          checked={flush}
                          onChange={toggleFlush}
                        />
                        <Label for={`flushData`}>
                          {intl.formatMessage({
                            id: 'smoove.page.project.general.data-actions.import.toggle-flush',
                            defaultMessage: 'Full import (delete first)'
                          })}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup switch>
                        <Input
                          type='switch'
                          role='switch'
                          id={`runCalculations`}
                          name={`runCalculations`}
                          checked={runCalculations}
                          onChange={toggleCalculations}
                        />
                        <Label for={'runCalculations'}>
                          {intl.formatMessage({
                            id: 'smoove.page.project.general.data-actions.import.toggle-calculations',
                            defaultMessage: 'with calculations'
                          })}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              }
              callback={survey.questback.id === undefined ? showNotification : importTivianDataHandling}
              btnColor={'primary'}
              btnText={intl.formatMessage({
                id: `smoove.page.project.general.data-actions.import.confirm`,
                defaultMessage: 'perform'
              })}
            >
              {openModal => (
                <Button
                  color={'primary'}
                  className={'mr-2'}
                  onClick={openModal}
                  disabled={asyncDataImportStatus.importing || isLoading}
                >
                  {intl.formatMessage({
                    id: 'smoove.page.project.general.data-actions.import.button',
                    defaultMessage: 'Import data'
                  })}
                </Button>
              )}
            </ConfirmAction>
          )}

          <ConfirmAction
            title={intl.formatMessage({
              id: 'smoove.page.project.general.data-actions.import-csv.title',
              defaultMessage: 'Import csv/zip file'
            })}
            size={'md'}
            body={
              <>
                <Row>
                  <Col>
                    {intl.formatMessage({
                      id: `smoove.page.project.general.data-actions.import-csv.text`,
                      defaultMessage:
                        'Imports the selected file with raw data into the survey. In the case of a complete import, all data is deleted first and then the uploaded file is imported. Otherwise, an incremental import is performed and the uploaded data is added to the existing data. The import can be performed with or without calculations.'
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      id={'csvfileSelect'}
                      name={'csvfileImport'}
                      type={'file'}
                      onChange={handleCsvFileChange}
                      accept={['.csv', '.zip']}
                    />
                    {fileSize > 15 && (
                      <div className='my-2 text-warning'>
                        <small>
                          Your file seems to be big ({fileSize}MB). It might make sense compress and upload it as a zip
                          file instead to minimize upload time.
                        </small>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup switch>
                      <Input
                        type='switch'
                        role='switch'
                        id={`fullImport`}
                        name={`fullImport`}
                        checked={flush}
                        onChange={toggleFlush}
                      />
                      <Label for={'fullImport'}>
                        {intl.formatMessage({
                          id: 'smoove.page.project.general.data-actions.import.toggle-flush',
                          defaultMessage: 'Full import (delete first)'
                        })}
                      </Label>
                    </FormGroup>
                    <FormGroup switch>
                      <Input
                        type='switch'
                        role='switch'
                        id={`runCalculations`}
                        name={`runCalculations`}
                        checked={runCalculations}
                        onChange={toggleCalculations}
                      />
                      <Label for={`runCalculations`}>
                        {intl.formatMessage({
                          id: 'smoove.page.project.general.data-actions.import.toggle-calculations',
                          defaultMessage: 'with calculations'
                        })}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            }
            callback={importCsvHandling}
            btnColor={'primary'}
            disabled={!importCsvFile}
            btnText={intl.formatMessage({
              id: `smoove.page.project.general.data-actions.import.confirm`,
              defaultMessage: 'perform'
            })}
          >
            {openModal => (
              <Button
                color={'primary'}
                className={'mr-2'}
                onClick={openModal}
                disabled={asyncDataImportStatus.importing || isLoading}
              >
                {intl.formatMessage({
                  id: 'smoove.page.project.general.data-actions.import-csv.button',
                  defaultMessage: 'Import file'
                })}
              </Button>
            )}
          </ConfirmAction>

          <ConfirmAction
            title={intl.formatMessage({
              id: 'smoove.page.project.general.data-actions.calculation.title',
              defaultMessage: 'Perform calculations'
            })}
            size={'md'}
            body={
              <Row>
                <Col>
                  {intl.formatMessage({
                    id: `smoove.page.project.general.data-actions.calculation.text`,
                    defaultMessage:
                      'Applies the calculations configured under `Data` to all cases already imported into the database. The calculation is asynchronous and can take several minutes, depending on the number and complexity.'
                  })}
                </Col>
              </Row>
            }
            callback={recalculateHandling}
            btnColor={'primary'}
            btnText={intl.formatMessage({
              id: `smoove.page.project.general.data-actions.calculation.confirm`,
              defaultMessage: 'perform'
            })}
          >
            {openModal => (
              <Button
                color={'primary'}
                className={'mr-2'}
                onClick={openModal}
                disabled={asyncDataImportStatus.importing || isLoading}
              >
                {intl.formatMessage({
                  id: 'smoove.page.project.general.data-actions.calculation.button',
                  defaultMessage: 'Perform calculations'
                })}
              </Button>
            )}
          </ConfirmAction>
          {asyncDataImportStatus?.importing && (
            <div className='mt-2 d-flex align-items-center'>
              <span className='fal fa-spinner fa-spin mr-2'></span>
              {asyncDataImportStatus?.calculating ? (
                <span className='text-warning '>Calculating ...</span>
              ) : (
                <span className='text-warning '>Import running. Cases: {asyncDataImportStatus?.progress}</span>
              )}
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={5} lg={4}></Col>
        <Col md={7} lg={8}>
          {isLoading && (
            <div>
              <i className='fas fa-spinner fa-spin' />
              <span className='ml-2 text-warning'>Uploading file, please wait...</span>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};
