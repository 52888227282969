import { produce } from 'immer';
import _, { isArray, isInteger, isNull } from 'lodash';

import { sharedReportActionTypes, surveyActionTypes } from '../constants';
import { questionnaireActionTypes } from '../constants/questionnaireConstants';
import { translationActionTypes } from '../constants/translationsActionTypes';

const INITIAL_STATE = {
  id: null,
  projectid: null,
  loading: false,
  error: null,
  title: null,
  type: null,
  status: null,
  locales: {
    // main: 'lc_code'
    // list: { 'lc_code': {} }
    // order: [ 'lc_code1', 'lc_code2', ... ]
  },
  questionnaire: {
    // list: {}
    // order: [] => all element ids in order
    // root: [] => element ids on root level
  },
  translations: {
    // 'translation id': {
    //   de_DE: 'text in DE',
    //   en_US: 'text in US'
    // }
  },
  // data preparation
  data: {},
  tablesFolders: { list: {}, order: [] },
  tables: { list: {}, order: [] },
  reports: { list: {}, order: [] },
  media: {},
  cardImages: [],
  questback: {
    id: null,
    import: {
      lastImportTime: null,
      lastImportCases: null
    }
  },
  references: {
    clientReference: null,
    distributorReference: null,
    internalReference: null
  }
};

export const surveyReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case surveyActionTypes.ADD_SURVEY_REQUEST: {
      return { ...state, loading: true };
    }
    case surveyActionTypes.ADD_SURVEY_SUCCESS: {
      return {
        ...INITIAL_STATE,
        ...payload.survey,
        loading: false
      };
    }
    case surveyActionTypes.ADD_SURVEY_FAILURE: {
      return { ...state, loading: false };
    }

    case surveyActionTypes.DUPLICATE_SURVEY_REQUEST: {
      return { ...state, loading: true };
    }
    case surveyActionTypes.DUPLICATE_SURVEY_SUCCESS: {
      return { ...state, loading: false };
    }
    case surveyActionTypes.DUPLICATE_SURVEY_FAILURE: {
      return { ...state, loading: false };
    }

    case surveyActionTypes.REMOVE_SURVEY_REQUEST: {
      return { ...state, loading: true };
    }
    case surveyActionTypes.REMOVE_SURVEY_SUCCESS: {
      return { ...state, loading: false };
    }
    case surveyActionTypes.REMOVE_SURVEY_FAILURE: {
      return { ...state, loading: false };
    }

    case surveyActionTypes.SWITCH_SURVEY:
      return INITIAL_STATE;

    /**
     * SAVE_SURVEY_CONFIG_X
     * updates the whole survey because the response is the whole survey
     */
    case surveyActionTypes.LAUNCH_SURVEY_REQUEST:
    case surveyActionTypes.START_TEST_SURVEY_REQUEST:
    case surveyActionTypes.UPDATE_SURVEY_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
        draftState.error = false;
      });
    case surveyActionTypes.LAUNCH_SURVEY_SUCCESS:
    case surveyActionTypes.START_TEST_SURVEY_SUCCESS:
    case surveyActionTypes.UPDATE_SURVEY_SUCCESS:
      return produce(state, draftState => {
        const {
          title,
          status,
          description,
          updatedAt,
          locales,
          translations,
          cardImages,
          type,
          questback,
          references,
          includeSampling,
          systemVariables
        } = payload.survey;
        draftState.loading = false;
        draftState.title = title;
        draftState.status = status;
        draftState.description = description;
        draftState.updatedAt = updatedAt;
        draftState.locales = locales;
        draftState.translations = translations;
        draftState.error = false;
        draftState.cardImages = cardImages;
        draftState.type = type;
        draftState.questback = questback;
        draftState.references = references;
        draftState.includeSampling = includeSampling;
        draftState.systemVariables = systemVariables;
      });
    case surveyActionTypes.LAUNCH_SURVEY_FAILURE:
    case surveyActionTypes.START_TEST_SURVEY_FAILURE:
    case surveyActionTypes.UPDATE_SURVEY_FAILURE:
      return produce(state, draftState => {
        draftState.loading = false;
        // draftState.error = payload.error;
      });
    case surveyActionTypes.REORDER_SURVEY_REPORTS_REQUEST:
      return produce(state, draftState => {
        // draftState.loading = true;
        draftState.error = false;
      });
    case surveyActionTypes.REORDER_SURVEY_REPORTS_SUCCESS:
      return produce(state, draftState => {
        const newReportOrder = payload.survey.reports;
        draftState.reports.order = newReportOrder;
        draftState.error = false;
      });
    case surveyActionTypes.REORDER_SURVEY_REPORTS_FAILURE:
      return produce(state, draftState => {
        // draftState.loading = false;
        draftState.error = payload.error;
      });

    /**
     * LOAD_SURVEY_X
     */
    case surveyActionTypes.LOAD_SURVEY_REQUEST:
      return produce(state, draftState => {
        draftState.loading = true;
        draftState.error = null;
      });
    case surveyActionTypes.LOAD_SURVEY_SUCCESS:
      const { survey } = payload;

      return produce(state, draft => {
        draft.loading = false;
        draft.id = survey.id;
        draft.title = survey.title;
        draft.description = survey.description;
        draft.status = survey.status;
        draft.locales = survey.locales;
        draft.media = survey.media ?? {};
        draft.cardImages = survey.cardImages;
        draft.type = survey.type;
        draft.questback = survey.questback;
        draft.references = survey.references;
        draft.includeSampling = survey.includeSampling;

        if (survey.questionnaire) {
          draft.questionnaire = survey.questionnaire;
          draft.questionnaire.loading = false;
        }

        if (survey.systemVariables) {
          draft.systemVariables = survey.systemVariables;
        }

        if (survey.translations) {
          draft.translations = survey.translations;
          draft.translations.loading = false;
        }

        if (survey.tablesFolders) {
          draft.tablesFolders = survey.tablesFolders;
          draft.tablesFolders.loading = false;
        }

        if (survey.tables) {
          draft.tables = survey.tables;
          draft.tables.loading = false;
        }

        if (survey.reports) {
          draft.reports = survey.reports;
          draft.reports.loading = false;
        }

        // handled through dataPrepReducer
        if (survey.data) {
          draft.data = survey.data;
        }

        return draft;
      });
    case surveyActionTypes.LOAD_SURVEY_FAILURE:
      return produce(state, draftState => {
        draftState.loading = false;
        draftState.error = payload.error;
      });

    /**
     * SAVE_SURVEY_TRANSLATION_X
     */
    case surveyActionTypes.SAVE_SURVEY_TRANSLATION_REQUEST:
      return produce(state, draftState => {});
    case surveyActionTypes.SAVE_SURVEY_TRANSLATION_SUCCESS:
      return produce(state, draftState => {
        const { elementid, element, translations } = payload;
        try {
          draftState.translations = _.merge(draftState?.translations, translations);
        } catch (error) {
          draftState.translations = translations;
        }
        draftState.questionnaire.list[elementid] = element;
      });
    case surveyActionTypes.SAVE_SURVEY_TRANSLATION_FAILURE:
      return produce(state, draftState => {});

    /**
     * SAVE_SURVEY_ELEMENT_X
     */
    case surveyActionTypes.SAVE_SURVEY_ELEMENT_REQUEST:
      return produce(state, draftState => {
        const { elementid } = payload;

        if (Object.keys(draftState.questionnaire).length === 0)
          draftState.questionnaire = { list: {}, order: [], root: [] };
        else if (isArray(draftState.questionnaire.list)) draftState.questionnaire.list = {};

        if (!draftState.questionnaire.list?.[elementid]) draftState.questionnaire.list[elementid] = { loading: true };
        else draftState.questionnaire.list[elementid].loading = true;
      });
    case surveyActionTypes.SAVE_SURVEY_ELEMENT_SUCCESS:
      return produce(state, draftState => {
        const { created, order = null, parentid = null, elementid, position = null, element, translations } = payload;

        draftState.questionnaire.list[elementid] = element;
        draftState.questionnaire.list[elementid].loading = false;

        if (translations) draftState.translations = _.merge(draftState.translations, translations);

        if (created) {
          if (!isInteger(position) && !isNull(parentid))
            draftState.questionnaire.list[parentid].children.push(elementid);
          else if (isInteger(position) && !isNull(parentid))
            draftState.questionnaire.list[parentid].children.splice(position, 0, elementid);
          else if (!isInteger(position) && isNull(parentid)) draftState.questionnaire.root.push(elementid);
          else if (isInteger(position) && isNull(parentid))
            draftState.questionnaire.root.splice(position, 0, elementid);

          if (order) draftState.questionnaire.order = order;
        }
      });
    case surveyActionTypes.SAVE_SURVEY_ELEMENT_FAILURE:
      return produce(state, draftState => {
        const { elementid, error } = payload;
        draftState.questionnaire.list[elementid].loading = false;
        console.log(error);
      });

    /**
     * DELETE_SURVEY_ELEMENT_X
     */
    case surveyActionTypes.DELETE_SURVEY_ELEMENT_REQUEST:
      return produce(state, draftState => {
        const { elementid } = payload;
        draftState.questionnaire.list[elementid].loading = true;
      });
    case surveyActionTypes.DELETE_SURVEY_ELEMENT_SUCCESS:
      return produce(state, draftState => {
        const { parentid, elementid } = payload;

        delete draftState.questionnaire.list[elementid];
        _.pull(draftState.questionnaire.order, elementid);

        if (parentid !== null) _.pull(draftState.questionnaire.list[parentid].children, elementid);
        else if (parentid === null) _.pull(draftState.questionnaire.root, elementid);
      });
    case surveyActionTypes.DELETE_SURVEY_ELEMENT_FAILURE:
      return produce(state, draftState => {
        const { elementid, error } = payload;
        draftState.questionnaire.list[elementid].loading = false;
        console.log(error);
      });

    /**
     * MOVE_SURVEY_ELEMENT_X
     */
    case surveyActionTypes.MOVE_SURVEY_ELEMENT_REQUEST:
      return produce(state, draftState => {
        const { elementid } = payload;
        draftState.questionnaire.list[elementid].loading = true;
      });
    case surveyActionTypes.MOVE_SURVEY_ELEMENT_SUCCESS:
      return produce(state, draftState => {
        const { list, order, root } = payload;
        draftState.questionnaire.list = list;
        draftState.questionnaire.order = order;
        draftState.questionnaire.root = root;
      });
    case surveyActionTypes.MOVE_SURVEY_ELEMENT_FAILURE:
      return produce(state, draftState => {
        const { elementid, error } = payload;
        draftState.questionnaire.list[elementid].loading = false;
        console.log(error);
      });

    /**
     * SAVE_SURVEY_SUBELEMENT_X
     */
    case surveyActionTypes.SAVE_SURVEY_SUBELEMENT_REQUEST:
      return produce(state, draftState => {
        const { elementid } = payload;
        draftState.questionnaire.list[elementid].loading = true;
      });
    case surveyActionTypes.SAVE_SURVEY_SUBELEMENT_SUCCESS:
      return produce(state, draftState => {
        const { elementid, element, translations } = payload;
        draftState.questionnaire.list[elementid] = element;
        draftState.questionnaire.list[elementid].loading = false;
        if (translations) {
          if (!draftState?.translations || isArray(draftState.translations)) draftState.translations = {};
          draftState.translations = _.merge(draftState.translations, translations);
        }
      });
    case surveyActionTypes.SAVE_SURVEY_SUBELEMENT_FAILURE:
      return produce(state, draftState => {
        const { elementid, error } = payload;
        draftState.questionnaire.list[elementid].loading = false;
        console.log(error);
      });

    /**
     * DELETE_SURVEY_SUBELEMENT_X
     */
    case surveyActionTypes.DELETE_SURVEY_SUBELEMENT_REQUEST:
      return produce(state, draftState => {
        const { elementid } = payload;
        draftState.questionnaire.list[elementid].loading = true;
      });
    case surveyActionTypes.DELETE_SURVEY_SUBELEMENT_SUCCESS:
      return produce(state, draftState => {
        const { elementid, element } = payload;
        draftState.questionnaire.list[elementid].loading = false;
        draftState.questionnaire.list[elementid] = element;
        // if (translations) draftState.translations = _.merge(draftState.translations, translations);
      });
    case surveyActionTypes.DELETE_SURVEY_SUBELEMENT_FAILURE:
      return produce(state, draftState => {
        const { elementid, error } = payload;
        draftState.questionnaire.list[elementid].loading = false;
        console.log(error);
      });

    case surveyActionTypes.MOVE_SURVEY_SUBELEMENT_SUCCESS:
      return produce(state, draftState => {
        const { element } = payload;
        draftState.questionnaire.list[element.id] = element;
      });

    case sharedReportActionTypes.LOAD_REPORT_SUCCESS:
      return produce(state, draftState => {
        const { projectid, surveyid, questionnaire, survey } = payload;
        draftState.id = surveyid;
        draftState.projectid = projectid;
        draftState.questionnaire = questionnaire;
        draftState.translations = survey.translations;
        draftState.locales = survey.locales;
        draftState.media = survey.media;
        draftState.accessLinkToken = payload.accessLinkToken;
        draftState.data = survey.data;
        draftState.systemVariables = survey.systemVariables;

        if (!draftState?.reports?.list) draftState.reports.list = {};
        draftState.reports.list = {
          [payload.report.id]: payload.report
        };
      });

    /**
     * UPLOAD_MEDIA_X
     */
    case surveyActionTypes.UPLOAD_MEDIA_REQUEST:
      return produce(state, draftState => {
        const { mediaid } = payload;
        if (draftState.media?.[mediaid]) draftState.media[mediaid].loading = true;
        draftState.isLoadingMedia = true;
      });
    case surveyActionTypes.UPLOAD_MEDIA_SUCCESS:
      return produce(state, draftState => {
        const { media } = payload;
        if (isArray(draftState.media)) draftState.media = {};
        draftState.media[media.id] = media;
        draftState.media[media.id].loading = false;
        draftState.isLoadingMedia = false;
      });
    case surveyActionTypes.UPLOAD_MEDIA_FAILURE:
      return produce(state, draftState => {
        const { id, error } = payload;
        if (draftState.media?.[id]) {
          draftState.media[id].loading = false;
          draftState.media[id].error = error;
        }
        draftState.isLoadingMedia = false;
      });

    /**
     * DELETE_MEDIA_X
     */
    case surveyActionTypes.DELETE_MEDIA_REQUEST:
      return produce(state, draftState => {
        const { mediaid } = payload;
        if (draftState.media?.[mediaid]) draftState.media[mediaid].loading = true;
      });
    case surveyActionTypes.DELETE_MEDIA_SUCCESS:
      return produce(state, draftState => {
        const { mediaid } = payload;
        delete draftState.media[mediaid];
      });
    case surveyActionTypes.DELETE_MEDIA_FAILURE:
      return produce(state, draftState => {
        const { mediaid, error } = payload;
        if (draftState.media?.[mediaid]) {
          draftState.media[mediaid].loading = false;
          draftState.media[mediaid].error = error;
        }
      });

    case questionnaireActionTypes.LOAD_QUESTIONNAIRE_REQUEST:
      return produce(state, draftState => {
        draftState.fetching = true;
        draftState.error = null;
      });
    case questionnaireActionTypes.LOAD_QUESTIONNAIRE_SUCCESS:
      return produce(state, draftState => {
        draftState.fetching = false;
        draftState.questionnaire = payload.questionnaire;
      });
    case questionnaireActionTypes.LOAD_QUESTIONNAIRE_FAILURE:
      return produce(state, draftState => {
        draftState.fetching = false;
        draftState.error = true;
      });

    case questionnaireActionTypes.ADD_QUESTIONNAIRE_ELEMENT_REQUEST: {
      return { ...state, fetching: true };
    }
    case questionnaireActionTypes.ADD_QUESTIONNAIRE_ELEMENT_SUCCESS: {
      return { ...state, fetching: false, questionnaire: payload.questionnaire };
    }
    case questionnaireActionTypes.ADD_QUESTIONNAIRE_ELEMENT_FAILURE: {
      return { ...state, fetching: false };
    }

    case questionnaireActionTypes.REMOVE_QUESTIONNAIRE_ELEMENT_REQUEST: {
      return { ...state, fetching: true };
    }
    case questionnaireActionTypes.REMOVE_QUESTIONNAIRE_ELEMENT_SUCCESS: {
      return { ...state, fetching: false, questionnaire: payload.questionnaire };
    }
    case questionnaireActionTypes.REMOVE_QUESTIONNAIRE_ELEMENT_FAILURE: {
      return { ...state, fetching: false };
    }

    case questionnaireActionTypes.UPDATE_QUESTIONNAIRE_ELEMENT_REQUEST: {
      return { ...state, fetching: true };
    }
    case questionnaireActionTypes.UPDATE_QUESTIONNAIRE_ELEMENT_SUCCESS: {
      return { ...state, fetching: false, questionnaire: payload.questionnaire };
    }
    case questionnaireActionTypes.UPDATE_QUESTIONNAIRE_ELEMENT_FAILURE: {
      return { ...state, fetching: false };
    }

    case questionnaireActionTypes.COPY_QUESTIONNAIRE_ELEMENT_REQUEST: {
      return { ...state, fetching: true };
    }
    case questionnaireActionTypes.COPY_QUESTIONNAIRE_ELEMENT_SUCCESS: {
      // return { ...state, fetching: false, survey: payload.survey, questionnaire: payload.questionnaire };
      return produce(state, draftState => {
        draftState.fetching = false;
        draftState.id = payload.surveyId;
        draftState.translations = payload.survey.translations;
        draftState.questionnaire = payload.questionnaire;
      });
    }
    case questionnaireActionTypes.COPY_QUESTIONNAIRE_ELEMENT_FAILURE: {
      return { ...state, fetching: false };
    }

    case questionnaireActionTypes.ADD_CHOICE_SUCCESS:
    case questionnaireActionTypes.ADD_SCALE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        questionnaire: payload.questionnaire,
        translations: payload.translations
      };
    }
    case questionnaireActionTypes.UPDATE_CHOICE_SUCCESS:
    case questionnaireActionTypes.REMOVE_CHOICE_SUCCESS:
    case questionnaireActionTypes.UPDATE_SCALE_SUCCESS:
    case questionnaireActionTypes.REMOVE_SCALE_SUCCESS:
    case questionnaireActionTypes.ADD_CHOICES_IMPORT_SUCCESS:
    case questionnaireActionTypes.UPDATE_CHOICES_IMPORT_SUCCESS:
    case questionnaireActionTypes.REMOVE_CHOICES_IMPORT_SUCCESS: {
      return { ...state, fetching: false, questionnaire: payload.questionnaire };
    }

    case translationActionTypes.GET_ALL_TRANSLATIONS_REQUEST: {
      return { ...state, translations: { ...state.translations, loading: true } };
    }

    case translationActionTypes.GET_ALL_TRANSLATIONS_SUCCESS: {
      return { ...state, translations: { ...payload.translations, loading: false } };
    }

    case translationActionTypes.UPDATE_TRANSLATION_REQUEST: {
      return produce(state, draftState => {
        draftState.translations[payload.translationId] = {
          ...state.translations[payload.translationId],
          loading: true
        };
      });
    }

    case translationActionTypes.UPDATE_TRANSLATION_SUCCESS: {
      return produce(state, draftState => {
        draftState.translations[payload.translations.id] = {
          ...draftState.translations[payload.translations.id],
          ...payload.translations,
          loading: false
        };
      });
    }

    case translationActionTypes.UPDATE_TRANSLATION_FAILURE: {
      return produce(state, draftState => {
        draftState.translations[payload.translationId] = {
          ...state.translations[payload.translationId],
          loading: false
        };
      });
    }

    case translationActionTypes.UPDATEMULTIPLE_TRANSLATION_REQUEST: {
      return produce(state, draftState => {
        draftState.loading = true;
      });
    }
    case translationActionTypes.UPDATEMULTIPLE_TRANSLATION_SUCCESS: {
      return produce(state, draftState => {
        draftState.translations = payload.translations;
        draftState.loading = false;
      });
    }
    case translationActionTypes.UPDATEMULTIPLE_TRANSLATION_FAILURE: {
      return produce(state, draftState => {
        draftState.loading = false;
      });
    }

    case questionnaireActionTypes.ADD_SCREENOUT_ELEMENT_SUCCESS:
    case questionnaireActionTypes.REMOVE_SCREENOUT_ELEMENT_SUCCESS:
    case questionnaireActionTypes.UPDATE_SCREENOUT_ELEMENT_SUCCESS: {
      const { elementId, screenout } = payload;
      return produce(state, draftState => {
        draftState.questionnaire.list[elementId].config.screenout = screenout;
      });
    }

    case questionnaireActionTypes.ADD_FILTER_ELEMENT_SUCCESS:
    case questionnaireActionTypes.REMOVE_FILTER_ELEMENT_SUCCESS:
    case questionnaireActionTypes.UPDATE_FILTER_ELEMENT_SUCCESS: {
      const { elementId, filter } = payload;
      return produce(state, draftState => {
        if (!state.questionnaire.list[elementId].config) {
          draftState.questionnaire.list[elementId].config = {};
        }
        draftState.questionnaire.list[elementId].config.filter = filter;
      });
    }

    case questionnaireActionTypes.ADD_JUMP_ELEMENT_SUCCESS:
    case questionnaireActionTypes.REMOVE_JUMP_ELEMENT_SUCCESS:
    case questionnaireActionTypes.UPDATE_JUMP_ELEMENT_SUCCESS: {
      const { elementId, jump } = payload;
      return produce(state, draftState => {
        draftState.questionnaire.list[elementId].config.jump = jump;
      });
    }

    case questionnaireActionTypes.ADD_DISPLAY_CONDITION_ELEMENT_SUCCESS:
    case questionnaireActionTypes.REMOVE_DISPLAY_CONDITION_ELEMENT_SUCCESS:
    case questionnaireActionTypes.UPDATE_DISPLAY_CONDITION_ELEMENT_SUCCESS: {
      const { elementId, choiceId, displayCondition } = payload;
      return produce(state, draftState => {
        const question = draftState.questionnaire.list[elementId];
        const choice = question.config.choices.list[choiceId];
        choice.config.displayCondition = displayCondition;
      });
    }

    default:
      return state;
  }
};
