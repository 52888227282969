export const settingsActionTypes = {
  /**
   * SMOOVE_SETTINGS_INIT_X
   */
  SMOOVE_SETTINGS_INIT_REQUEST: 'SMOOVE_SETTINGS_INIT_REQUEST',
  SMOOVE_SETTINGS_INIT_SUCCESS: 'SMOOVE_SETTINGS_INIT_SUCCESS',
  SMOOVE_SETTINGS_INIT_FAILURE: 'SMOOVE_SETTINGS_INIT_FAILURE',

  /**
   * SMOOVE_SETTINGS_USER_X_X_X
   */
  SMOOVE_SETTINGS_USER_SET_PROJECTLISTSTYLE_SUCCESS: 'SMOOVE_SETTINGS_USER_SET_PROJECTLISTSTYLE_SUCCESS',
  SMOOVE_SETTINGS_USER_SET_EXPERTMODE_SUCCESS: 'SMOOVE_SETTINGS_USER_SET_EXPERTMODE_SUCCESS'
};
