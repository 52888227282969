import React, { useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import produce from 'immer';
import {
  ResponsiveContainer,
  LineChart as LineChartReCharts,
  AreaChart as AreaChartReCharts,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  Area,
  LabelList,
  Label
} from 'recharts';

import {
  flattenKeys,
  getContrastColor,
  getFixedColor,
  getFormattedValue,
  getSchemeColor,
  useChartData,
  useLegendMouseEffects
} from './helper';

export const LineChart = ({
  elementid,
  chartConfig,
  table,
  tableConfig,
  tableResult,
  activeSignificanceType,
  getOpacitySignificance,
  handler
}) => {
  const [chartData, chartDimensions] = useChartData({
    chartConfig,
    table,
    tableResult,
    transpose: chartConfig?.tableTranspose ?? false
  });

  const selectedClientId = useSelector(state => state.clients.selected);
  const colorScales = useSelector(
    state => state.clients?.list?.[selectedClientId]?.colorScales ?? { list: {}, order: [] }
  );

  /**
   * DOMAIN
   */
  const domain = useMemo(() => {
    if (!!chartConfig?.chartDomainMax && !isNaN(parseFloat(chartConfig.chartDomainMax))) {
      return [0, parseFloat(chartConfig.chartDomainMax)];
    } else {
      return [0, 'auto'];
    }
  }, [chartConfig?.chartDomainMax]);

  const valueFormatter = useCallback(value => getFormattedValue(value, table.config), [table.config]);

  /**
   * AXIS
   */
  const { xAxis, yAxis } = useMemo(() => {
    let xAxis, yAxis;

    if (!chartConfig?.chartOrientation || chartConfig.chartOrientation === 'horizontal') {
      xAxis = <XAxis dataKey='name' type='category' interval={0} padding={{ left: 25, right: 25 }} />;
      yAxis = <YAxis type='number' domain={domain} padding={{ top: 25 }} />;
    } else if (chartConfig.chartOrientation === 'vertical') {
      let yAxisWidth = 60;
      if (!!chartConfig?.axisYwidth) {
        let value = parseInt(chartConfig.axisYwidth);
        if (isFinite(value)) yAxisWidth = value;
      }

      xAxis = <XAxis type='number' domain={domain} allowDataOverflow={true} />;
      yAxis = (
        <YAxis dataKey='name' type='category' interval={0} width={yAxisWidth} padding={{ top: 25, bottom: 25 }} />
      );
    }

    return { xAxis, yAxis };
  }, [chartConfig, domain]);

  /**
   * HOVER EFFECTS
   */
  const flattendHiddenSeries = useMemo(() => {
    if (chartConfig.legendSaveHiddenSeries ?? false) {
      return flattenKeys(chartConfig.legendHiddenSeries ?? {});
    } else {
      return {};
    }
  }, [chartConfig.legendSaveHiddenSeries, chartConfig.legendHiddenSeries]);
  const { onMouseEnter, onMouseLeave, getOpacity, isHidden, toggleSeries, hiddenSeries } = useLegendMouseEffects({
    hiddenSeries: flattendHiddenSeries
  });

  useEffect(() => {
    if (handler?.setTableConfig) {
      handler.setTableConfig(s => {
        // hidden series should be saved, and the current state is different to stored state => update table config
        if (
          s.chart.legendSaveHiddenSeries === true &&
          !isEqual(flattenKeys(s.chart.legendHiddenSeries), hiddenSeries)
        ) {
          return produce(s, d => {
            d.chart.legendHiddenSeries = hiddenSeries;
          });
        }
        // hidden series should not be saved, but the stored state not empty => update table config
        else if (s.chart.legendSaveHiddenSeries !== true && !isEqual(s.chart.legendHiddenSeries, {})) {
          return produce(s, d => {
            d.chart.legendHiddenSeries = {};
          });
        }
        // no update required, series should not be saved or stored state equals current state
        else {
          return s;
        }
      });
    }
  }, [handler, hiddenSeries]);

  if (!chartData) return null;

  const { colorSchema, colorSchemaReverse } = chartConfig;

  if (chartConfig?.chartSubtype === 'stacked') {
    return (
      <ResponsiveContainer width='100%' height='100%'>
        <AreaChartReCharts width={200} height={120} data={chartData} layout={chartConfig?.chartOrientation}>
          {(chartConfig?.showGrid ?? false) && <CartesianGrid strokeDasharray='3 3' />}
          {xAxis}
          {yAxis}

          {(chartConfig?.showTooltip ?? true) && (
            <Tooltip formatter={valueFormatter} labelFormatter={label => <strong>{label}</strong>} />
          )}

          {(chartConfig?.showLegend ?? true) && (
            <Legend
              iconType={'circle'}
              iconSize={chartConfig?.legendIconSize ?? 14}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={toggleSeries}
              formatter={value => <span style={{ color: '#666' }}>{value}</span>}
            />
          )}

          {(chartConfig?.dimensionsReversed === true ? [...chartDimensions].reverse() : [...chartDimensions]).map(
            (dimension, index) => {
              // get colors
              let scalePointValue = index / (chartDimensions.length - 1);
              if (isNaN(scalePointValue)) scalePointValue = 0;
              if (colorSchemaReverse) scalePointValue = 1 - scalePointValue;

              let { color, contrastColor } = getSchemeColor({ colorScales, colorSchema, value: scalePointValue });

              const fixedColor = getFixedColor(chartConfig, dimension.cellIdentifier);
              if (fixedColor) {
                color = fixedColor.hex;
                contrastColor = getContrastColor(fixedColor.rgb);
              }

              const valuePosition = chartConfig?.valuesValuePosition ?? 'top';
              const valueSize = chartConfig?.valuesValueSize ?? 12;
              const circleRadius =
                chartConfig?.valuesShow && valuePosition === 'inside'
                  ? valueSize * 1.25
                  : chartConfig?.valuesCircleRadius ?? 5;

              return (
                <Area
                  key={dimension.id}
                  name={dimension.label}
                  dataKey={`${dimension.id}.value`}
                  strokeWidth={2}
                  stroke={color}
                  dot={
                    <CustomizedDot
                      r={circleRadius}
                      fill={color}
                      significancesShow={chartConfig?.significancesShow ?? false}
                      getOpacitySignificance={getOpacitySignificance}
                      activeSignificanceType={activeSignificanceType}
                    />
                  }
                  /**
                   * disabled the activeDot, cause the hover effect hides the dots value
                   * this should be fixed, if the activeDot is reactivated
                   */
                  activeDot={false}
                  // activeDot={
                  //   <CustomizedDot
                  //     r={circleRadius * 1.2}
                  //     fill={color}
                  //     significancesShow={chartConfig?.significancesShow ?? false}
                  //     getOpacitySignificance={getOpacitySignificance}
                  //     activeSignificanceType={activeSignificanceType}
                  //   />
                  // }
                  fill='none'
                  stackId='1'
                  opacity={activeSignificanceType ? 0.2 : getOpacity(dimension.id)}
                  isAnimationActive={false}
                  hide={isHidden(`${dimension.id}.value`)}
                >
                  {chartConfig?.valuesShow && (
                    <LabelList
                      dataKey={dimension.id}
                      position={valuePosition || 'top'}
                      fill={valuePosition === 'inside' ? contrastColor : `#666`}
                      fontSize={valueSize}
                      opacity={activeSignificanceType ? 0.2 : getOpacity(dimension.id)}
                      content={payload => {
                        let { content, fill, ...rest } = payload;
                        const cellIdentifier = payload.value?.cellIdentifier;
                        if (!cellIdentifier) return null;
                        const fixedColor = getFixedColor(chartConfig, cellIdentifier);
                        if (fixedColor && chartConfig?.valuesValuePosition !== 'outside') {
                          fill = getContrastColor(fixedColor.rgb);
                        }

                        return (
                          <Label fill={fill} {...rest}>
                            {valueFormatter(payload.value.value)}
                          </Label>
                        );
                      }}
                    />
                  )}
                </Area>
              );
            }
          )}
        </AreaChartReCharts>
      </ResponsiveContainer>
    );
  } else {
    return (
      <ResponsiveContainer width='100%' height='100%'>
        <LineChartReCharts width={200} height={120} data={chartData} layout={chartConfig?.chartOrientation}>
          {(chartConfig?.showGrid ?? false) && <CartesianGrid strokeDasharray='3 3' />}
          {xAxis}
          {yAxis}

          {(chartConfig?.showTooltip ?? true) && (
            <Tooltip formatter={valueFormatter} labelFormatter={label => <strong>{label}</strong>} />
          )}

          {(chartConfig?.showLegend ?? true) && (
            <Legend
              iconType={'circle'}
              iconSize={chartConfig?.legendIconSize ?? 14}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={toggleSeries}
              formatter={value => <span style={{ color: '#666' }}>{value}</span>}
            />
          )}

          {(chartConfig?.dimensionsReversed === true ? [...chartDimensions].reverse() : [...chartDimensions]).map(
            (dimension, index) => {
              // get colors
              let scalePointValue = index / (chartDimensions.length - 1);
              if (isNaN(scalePointValue)) scalePointValue = 0;
              if (colorSchemaReverse) scalePointValue = 1 - scalePointValue;

              let { color, contrastColor } = getSchemeColor({ colorScales, colorSchema, value: scalePointValue });

              const fixedColor = getFixedColor(chartConfig, dimension.cellIdentifier);
              if (fixedColor) {
                color = fixedColor.hex;
                contrastColor = getContrastColor(fixedColor.rgb);
              }

              const valuePosition = chartConfig?.valuesValuePosition ?? 'top';
              const valueSize = chartConfig?.valuesValueSize ?? 12;
              const circleRadius =
                chartConfig?.valuesShow && valuePosition === 'inside'
                  ? valueSize * 1.25
                  : chartConfig?.valuesCircleRadius ?? 5;

              return (
                <Line
                  key={dimension.id}
                  name={dimension.label}
                  dataKey={`${dimension.id}.value`}
                  strokeWidth={2}
                  stroke={color}
                  // dot={{ r: circleRadius, stroke: `#fff`, fill: color, strokeWidth: 2 }}
                  dot={
                    <CustomizedDot
                      r={circleRadius}
                      fill={color}
                      significancesShow={chartConfig?.significancesShow ?? false}
                      getOpacitySignificance={getOpacitySignificance}
                      activeSignificanceType={activeSignificanceType}
                    />
                  }
                  /**
                   * disabled the activeDot, cause the hover effect hides the dots value
                   * this should be fixed, if the activeDot is reactivated
                   */
                  activeDot={false}
                  // activeDot={
                  //   <CustomizedDot
                  //     r={circleRadius * 1.2}
                  //     fill={color}
                  //     significancesShow={chartConfig?.significancesShow ?? false}
                  //     getOpacitySignificance={getOpacitySignificance}
                  //     activeSignificanceType={activeSignificanceType}
                  //   />
                  // }
                  legendType={'circle'}
                  opacity={activeSignificanceType ? 0.2 : getOpacity(dimension.id)}
                  isAnimationActive={false}
                  hide={isHidden(`${dimension.id}.value`)}
                >
                  {chartConfig?.valuesShow && (
                    <LabelList
                      dataKey={dimension.id}
                      position={valuePosition || 'top'}
                      fill={valuePosition === 'inside' ? contrastColor : `#666`}
                      fontSize={valueSize}
                      opacity={activeSignificanceType ? 0.2 : getOpacity(dimension.id)}
                      content={payload => {
                        let { content, fill, ...rest } = payload;
                        const cellIdentifier = payload.value?.cellIdentifier;
                        if (!cellIdentifier) return null;
                        const fixedColor = getFixedColor(chartConfig, cellIdentifier);
                        if (fixedColor && chartConfig?.valuesValuePosition !== 'outside') {
                          fill = getContrastColor(fixedColor.rgb);
                        }

                        return (
                          <Label fill={fill} {...rest}>
                            {valueFormatter(payload.value.value)}
                          </Label>
                        );
                      }}
                    />
                  )}
                </Line>
              );
            }
          )}
        </LineChartReCharts>
      </ResponsiveContainer>
    );
  }
};

const CustomizedDot = props => {
  const {
    cx,
    cy,
    r,
    fill,
    dataKey,
    payload,
    opacity,
    significancesShow,
    getOpacitySignificance,
    activeSignificanceType
  } = props;

  const stroke = '#fff';
  const strokeWidth = 2;

  const _dataKey = dataKey?.split('.')?.[0];
  const significance = payload?.significances[_dataKey] ?? null;

  const _opacity =
    activeSignificanceType && getOpacitySignificance !== undefined ? getOpacitySignificance(significance) : opacity;

  if (!cx || !cy) return null;

  if (significancesShow && significance) {
    const sigHighColor = '#008000';
    const sigLowColor = '#FF0000';
    const sigMutuallyColor = '#ac4bbd';

    const _stroke =
      significance === 'high'
        ? sigHighColor
        : significance === 'low'
        ? sigLowColor
        : significance === 'both'
        ? sigMutuallyColor
        : '#fff';

    return (
      <>
        <circle cx={cx} cy={cy} r={r} stroke={_stroke} strokeWidth={strokeWidth} fill={'#fff'} opacity={_opacity} />
        <circle cx={cx} cy={cy} r={r - strokeWidth - 1} fill={fill} opacity={_opacity} />
      </>
    );
  } else {
    return <circle cx={cx} cy={cy} r={r} stroke={stroke} strokeWidth={strokeWidth} fill={fill} opacity={_opacity} />;
  }
};
