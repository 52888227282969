import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useRouteMatch, generatePath, useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalFooter, Input, FormGroup, Container, Row, Col, Button, ModalHeader } from 'reactstrap';
import { Route } from 'react-router-dom';
import produce from 'immer';
import classNames from 'classnames';

import { questionnaireActions } from 'smv-redux/actions';

import { InlineEdit, QueryBuilder } from 'smv-components';
import {
  getQueryBuilderEmptyState,
  getQueryBuilderWithoutElementsWithErrors,
  handleQuerybuilderAutosave
} from 'src/smoove/components/querybuilder/helper';
import { LoopConfig } from '.';

import '../ConfigModal.scss';
import { useSelector } from 'react-redux';
import { getQueryBuilderSources, getQuestionnaireElementsOrder } from 'smv-helpers';

const defaultTabList = [
  ['config', <i className='fal fa-cog ml-2' />],
  ['filter', <i className='fal fa-filter ml-2' />]
];

export const ContainerConfigModal = ({ container, writeable }) => {
  const { path } = useRouteMatch();
  const params = useParams();
  const { surveyid } = params;
  const history = useHistory();

  // const handleVarprefix = value => {
  //   if (value !== container?.config?.varprefix) {
  //     questionnaireActions.updateQuestionnaireElement(surveyid, container.id, {
  //       config: { varprefix: value }
  //     });
  //   }
  // };
  const setSection = useCallback(
    section => {
      history.push(generatePath(path, { ...params, section: section }));
    },
    [history, path, params]
  );

  const toggleModal = useCallback(() => {
    history.push(generatePath(path.replace('/:elementid/:section?', ''), params));
  }, [history, params, path]);

  return (
    <Modal isOpen={true} centered={true} size={'lg'} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        <div className='d-flex'>
          <div className='modal__title-group'>
            <div className='modal__title-info'>
              <FormattedMessage id={'smoove.questionnaire.modal.settings-of'} defaultMessage={'Settings of'} />
              {':'}
            </div>
            <InlineEdit
              disabled={!writeable}
              className='modal__title'
              defaultValue={container.title}
              saveFn={newTitle => {
                if (container.title !== newTitle) {
                  questionnaireActions.updateQuestionnaireElement(surveyid, container.id, {
                    parentId: container.parentId ?? null,
                    title: newTitle
                  });
                }
              }}
            />
          </div>
        </div>
      </ModalHeader>

      {/* Tabs Navigation  */}
      <div className='d-flex mt-4 overflow-auto pb-2'>
        {defaultTabList.map(([tabText, tabIcon]) => (
          <div
            key={tabText}
            className={classNames('tab text-smv px-2 mx-4', {
              'tab--active': tabText === params.section || (tabText === 'config' && !params.section)
            })}
            onClick={() => setSection(tabText)}
          >
            <div className='d-flex align-items-center justify-content-center'>
              <div>
                <FormattedMessage id={`smoove.questionnaire.modal.tab-${tabText}`} defaultMessage={tabText} />
              </div>
              <div>{tabIcon}</div>
            </div>
          </div>
        ))}
      </div>

      <ModalBody style={{ minHeight: '450px' }}>
        <Container className='config-tab mt-2'>
          <Row className='mb-2'>
            <Col sm='12' md='12'>
              <Route path={generatePath(path, { ...params, section: undefined })} exact={true}>
                <ContainerConfigTab surveyid={surveyid} container={container} writeable={writeable} />
              </Route>
              <Route path={generatePath(path, { ...params, section: 'config' })} exact={true}>
                <ContainerConfigTab surveyid={surveyid} container={container} writeable={writeable} />
              </Route>
              <Route path={generatePath(path, { ...params, section: 'filter' })} exact={true}>
                <Filter writeable={writeable} container={container} surveyid={surveyid} />
              </Route>

              {/* Container Prefix */}
              {/* @Todo: http://gitea.mla.local/smoove/module-smoove/issues/50 */}
              {/* {isExpert && ( */}
              {/* <div className='config-group'>
                <h6>
                  <FormattedMessage
                    id={`smoove.questionnaire.modal.config-container.group-prefix`}
                    defaultMessage={'Group-prefix:'}
                  />
                </h6>
                <div>
                  <InlineEdit
                    disabled={!writeable}
                    placeholder='assign group prefix...'
                    defaultValue={container.config?.varprefix}
                    saveFn={handleVarprefix}
                  />
                </div>
                <small>
                  <i>
                    <FormattedMessage
                      id={`smoove.questionnaire.modal.config-container.prefix-text`}
                      defaultMessage={
                        'If you plan to use the surveys collected raw data outside of smoove, you can assign custom variable names and prefixes to grouping elements. With that, exported raw datasets will be easier to read. This setting has no effect on the internal data handling and results.'
                      }
                    />
                  </i>
                </small>
              </div> */}
              {/* )} */}
            </Col>
          </Row>
        </Container>
      </ModalBody>

      <ModalFooter>
        {/* <div className='mr-auto d-flex'>
          <div className='mr-2'>
            <FormattedMessage id={`smoove.common.project.expert-mode`} defaultMessage={'Expert Mode'} />
          </div>
          <SmvToggleExpertModeSwitch />
        </div> */}

        <Button color={'grey'} onClick={toggleModal}>
          <FormattedMessage id={`smoove.common.buttons.close`} defaultMessage={'Close'} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ContainerConfigTab = ({ writeable, container, surveyid }) => {
  const handleRandomize = value => {
    if (value !== container?.config?.randomize) {
      questionnaireActions.updateQuestionnaireElement(surveyid, container.id, {
        config: { randomize: value }
      });
    }
  };

  return (
    <>
      {/* Randomized Order */}
      <div className='config-group'>
        <h6>
          <FormattedMessage
            id={`smoove.questionnaire.modal.config-container.randomize-children`}
            defaultMessage={'Randomize children:'}
          />
        </h6>
        <FormGroup switch>
          <Input
            disabled={!writeable}
            type='switch'
            role='switch'
            id='randomized_children'
            checked={container.config?.randomize || false}
            onChange={() => handleRandomize(!container.config?.randomize)}
          />
        </FormGroup>
        <small>
          <i>
            <FormattedMessage
              id={`smoove.questionnaire.modal.config-container.randomize-children-text`}
              defaultMessage={
                'If activated, direct children of this element will be shown in a randomized order. Child elements on the next level (children of a child) will not be affected.'
              }
            />
          </i>
        </small>
      </div>

      {/* Loop */}
      <LoopConfig container={container} writeable={writeable} />
    </>
  );
};

const Filter = ({ writeable, container, surveyid }) => {
  const [_filter, setFilter] = useState(container?.config?.filter ?? null);
  const [isSaving, setIsSaving] = useState(false);
  const [warning, setWarning] = useState(null);

  const questionnaire = useSelector(state => state.survey.questionnaire);
  const systemVariables = useSelector(state => state.survey?.systemVariables ?? { list: {}, order: [] });

  const filterSources = useMemo(() => {
    const rawSources = {
      question: { list: questionnaire.list, order: getQuestionnaireElementsOrder(questionnaire) },
      sysvar: systemVariables
    };

    const filterSources = getQueryBuilderSources(rawSources, container.id, 'above', false);

    return filterSources;
  }, [questionnaire, systemVariables, container.id]);

  useEffect(() => {
    if (!!container.config?.filter && !!container.config?.filter?.rootElementId) {
      if (!_filter?.id) {
        setFilter(state =>
          produce(state, d => {
            d.id = container.config?.filter.id;
          })
        );
      }
    } else {
      setFilter(getQueryBuilderEmptyState());
    }
  }, [container.config?.filter, _filter?.id]);

  const saveHandler = useCallback(() => {
    setIsSaving(true);

    const filterId = container.config?.filter?.id ? container.config?.filter?.id : null;

    questionnaireActions
      .updateFilterElement(surveyid, container.id, filterId, getQueryBuilderWithoutElementsWithErrors(_filter))
      .then(res => {
        setIsSaving(false);
        setWarning(null);
      });
  }, [surveyid, container.id, _filter, container.config?.filter?.id]);

  const deleteHandler = useCallback(() => {
    setIsSaving(true);
    if (container.config?.filter?.rootElementId) {
      questionnaireActions
        .removeFilterElement(surveyid, container.id, container.config?.filter?.rootElementId)
        .then(res => {
          setIsSaving(false);
          setWarning(null);
        });
    } else {
      setFilter(null);
      setIsSaving(false);
    }
  }, [container.config?.filter?.rootElementId, container.id, surveyid]);

  useEffect(() => {
    handleQuerybuilderAutosave(
      _filter,
      container.config?.filter,
      filterSources,
      isSaving,
      deleteHandler,
      setWarning,
      saveHandler
    );
  }, [_filter, saveHandler, isSaving, container.config?.filter, filterSources, deleteHandler]);

  return (
    <>
      <QueryBuilder
        readOnly={!writeable}
        name='filter'
        fields={filterSources}
        queryBuilder={_filter}
        setQueryBuilder={setFilter}
        warning={warning}
        isSaving={isSaving}
      />
    </>
  );
};
