import React, { useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import produce from 'immer';
import {
  ResponsiveContainer,
  ScatterChart as ScatterChartReCharts,
  Scatter,
  LabelList,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts';

import { isNumeric, useGetSourceElement } from 'smv-helpers';
import {
  flattenKeys,
  getContrastColor,
  getFixedColor,
  getFormattedValue,
  getSchemeColor,
  useChartData,
  useLegendMouseEffects
} from './helper';

export const ScatterChart = ({ elementid, chartConfig, table, tableResult, handler }) => {
  const getSourceElement = useGetSourceElement();

  const dimensionMode = chartConfig?.scatterDimensionsDimension ?? 'allHeads';

  let [chartData, chartDimensions] = useChartData({
    chartConfig,
    table,
    tableResult,
    transpose: dimensionMode === 'allHeads' || dimensionMode === 'heads' ? true : false //chartConfig?.tableTranspose ?? false
  });

  let chartDimensionsX;
  let chartDimensionsY;

  if (dimensionMode === 'rows' || dimensionMode === 'heads') {
    const subDimension = chartConfig?.scatterDimensionsSubDimension
      ? findDataKey(chartData, chartConfig?.scatterDimensionsSubDimension) ?? 0
      : 0;
    chartData = chartData?.[subDimension];

    chartDimensionsX = chartConfig?.scatterDimensionsX
      ? getDimensions(chartDimensions, chartConfig?.scatterDimensionsX)
      : chartDimensions;
    chartDimensionsY = chartConfig?.scatterDimensionsY
      ? getDimensions(chartDimensions, chartConfig?.scatterDimensionsY)
      : chartDimensions;

    chartDimensions = isEqual(chartConfig?.scatterDimensionsLabels, chartConfig?.scatterDimensionsX)
      ? chartDimensionsX
      : chartDimensionsY;
  }

  // console.log('table', table);
  // console.log('chartData', chartData);
  // console.log('chartDimensions', chartDimensions);

  let xIndex;
  let yIndex;

  if (dimensionMode === 'allHeads' || dimensionMode === 'allRows') {
    xIndex = chartConfig?.scatterDimensionsX ? findDataKey(chartData, chartConfig?.scatterDimensionsX) ?? 0 : 0;
    yIndex = chartConfig?.scatterDimensionsY ? findDataKey(chartData, chartConfig?.scatterDimensionsY) ?? 1 : 1;
  }

  const selectedClientId = useSelector(state => state.clients.selected);
  const colorScales = useSelector(
    state => state.clients?.list?.[selectedClientId]?.colorScales ?? { list: {}, order: [] }
  );

  /**
   * DOMAINS
   */
  const domainX = useMemo(() => {
    if (chartConfig?.chartDomainXAuto ?? true) return [0, 'auto'];
    let domainMin = chartConfig?.chartDomainXMin ?? 'auto';
    let domainMax = chartConfig?.chartDomainXMax ?? 'auto';

    if (isNumeric(domainMin)) domainMin = parseFloat(domainMin);
    if (isNumeric(domainMax)) domainMax = parseFloat(domainMax);

    return [domainMin, domainMax];
  }, [chartConfig?.chartDomainXAuto, chartConfig?.chartDomainXMin, chartConfig?.chartDomainXMax]);

  const domainY = useMemo(() => {
    if (chartConfig?.chartDomainYAuto ?? true) return [0, 'auto'];
    let domainMin = chartConfig?.chartDomainYMin ?? 'auto';
    let domainMax = chartConfig?.chartDomainYMax ?? 'auto';

    if (isNumeric(domainMin)) domainMin = parseFloat(domainMin);
    if (isNumeric(domainMax)) domainMax = parseFloat(domainMax);

    return [domainMin, domainMax];
  }, [chartConfig?.chartDomainYAuto, chartConfig?.chartDomainYMin, chartConfig?.chartDomainYMax]);

  const valueFormatter = useCallback(value => getFormattedValue(value, table.config), [table.config]);

  /**
   * AXIS
   */
  const { xAxis, yAxis } = useMemo(() => {
    let xAxis, yAxis;

    let xAxisName;
    let yAxisName;

    if (dimensionMode === 'allHeads' || dimensionMode === 'allRows') {
      xAxisName = chartData?.[xIndex]?.name ?? '';
      yAxisName = chartData?.[yIndex]?.name ?? '';
    } else {
      xAxisName = chartConfig?.scatterDimensionsX
        ? getAxisName(table, chartConfig?.scatterDimensionsX, getSourceElement)
        : '';
      yAxisName = chartConfig?.scatterDimensionsY
        ? getAxisName(table, chartConfig?.scatterDimensionsY, getSourceElement)
        : '';
    }

    xAxis = (
      <XAxis
        type='number'
        dataKey='x'
        name={xAxisName}
        domain={domainX}
        padding={{ left: 25, right: 25 }}
        label={{ value: xAxisName, position: 'insideBottomRight', offset: 0, fill: '#666' }}
      />
    );
    yAxis = (
      <YAxis
        type='number'
        dataKey='y'
        name={yAxisName}
        domain={domainY}
        padding={{ top: 25 }}
        label={{ value: yAxisName, angle: -90, position: 'center', fill: '#666' }}
      />
    );

    return { xAxis, yAxis };
  }, [
    chartData,
    dimensionMode,
    domainX,
    domainY,
    xIndex,
    yIndex,
    chartConfig?.scatterDimensionsX,
    chartConfig?.scatterDimensionsY,
    getSourceElement,
    table
  ]);

  /**
   * HOVER EFFECTS
   */
  const flattendHiddenSeries = useMemo(() => {
    if (chartConfig.legendSaveHiddenSeries ?? false) {
      return flattenKeys(chartConfig.legendHiddenSeries ?? {});
    } else {
      return {};
    }
  }, [chartConfig.legendSaveHiddenSeries, chartConfig.legendHiddenSeries]);
  const { onMouseEnter, onMouseLeave, getOpacity, isHidden, toggleSeries, hiddenSeries } = useLegendMouseEffects({
    hiddenSeries: flattendHiddenSeries
  });

  useEffect(() => {
    if (handler?.setTableConfig) {
      handler.setTableConfig(s => {
        // hidden series should be saved, and the current state is different to stored state => update table config
        if (
          s.chart.legendSaveHiddenSeries === true &&
          !isEqual(flattenKeys(s.chart.legendHiddenSeries), hiddenSeries)
        ) {
          return produce(s, d => {
            d.chart.legendHiddenSeries = hiddenSeries;
          });
        }
        // hidden series should not be saved, but the stored state not empty => update table config
        else if (s.chart.legendSaveHiddenSeries !== true && !isEqual(s.chart.legendHiddenSeries, {})) {
          return produce(s, d => {
            d.chart.legendHiddenSeries = {};
          });
        }
        // no update required, series should not be saved or stored state equals current state
        else {
          return s;
        }
      });
    }
  }, [handler, hiddenSeries]);

  if (!chartData || chartData.length < 2) return null;

  const { colorSchema, colorSchemaReverse } = chartConfig;

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <ScatterChartReCharts width={200} height={120} data={chartData} layout={chartConfig?.chartOrientation}>
        {(chartConfig?.showGrid ?? false) && <CartesianGrid strokeDasharray='3 3' />}

        {xAxis}
        {yAxis}

        {(chartConfig?.showTooltip ?? true) && <Tooltip content={<CustomTooltip formatter={valueFormatter} />} />}

        {(chartConfig?.showLegend ?? true) && (
          <Legend
            iconType={'circle'}
            iconSize={chartConfig?.legendIconSize ?? 14}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={toggleSeries}
            formatter={value => <span style={{ color: '#666' }}>{value}</span>}
          />
        )}

        {(chartConfig?.dimensionsReversed === true ? [...chartDimensions].reverse() : [...chartDimensions]).map(
          (dimension, index) => {
            // get colors
            let scalePointValue = index / (chartDimensions.length - 1);
            if (isNaN(scalePointValue)) scalePointValue = 0;
            if (colorSchemaReverse) scalePointValue = 1 - scalePointValue;

            let { color, contrastColor } = getSchemeColor({ colorScales, colorSchema, value: scalePointValue });

            const cellIdentifier = dimension.cellIdentifier;
            const fixedColor = getFixedColor(chartConfig, cellIdentifier);
            if (fixedColor) {
              color = fixedColor.hex;
              contrastColor = getContrastColor(fixedColor.rgb);
            }

            const valueSize = chartConfig?.valuesValueSize ? parseInt(chartConfig.valuesValueSize) : 12;
            const circleRadius =
              chartConfig?.valuesShow && chartConfig?.valuesValuePosition === 'inside'
                ? valueSize * 1.5
                : chartConfig?.valuesCircleRadius
                ? parseInt(chartConfig.valuesCircleRadius)
                : 5;
            const valuePosition = chartConfig?.valuesValuePosition ?? 'top';

            let xCoordinate;
            let yCoordinate;

            if (dimensionMode === 'rows' || dimensionMode === 'heads') {
              xCoordinate = chartData?.[chartDimensionsX?.[index]?.id]?.value;
              yCoordinate = chartData?.[chartDimensionsY?.[index]?.id]?.value;
            } else {
              xCoordinate = chartData?.[xIndex]?.[dimension?.id]?.value;
              yCoordinate = chartData?.[yIndex]?.[dimension?.id]?.value;
            }

            if (!xCoordinate || !yCoordinate) return null;

            let coordinates = [{ name: dimension.label, x: xCoordinate, y: yCoordinate }];

            return (
              <Scatter
                key={dimension.id}
                name={dimension.label}
                dataKey={`${dimension.id}.value`}
                data={coordinates}
                fill={color}
                shape={
                  <CustomizedCircle
                    dataKey={`${dimension.id}.value`}
                    circleRadius={circleRadius}
                    fill={color}
                    opacity={getOpacity(dimension.id)}
                  />
                }
                isAnimationActive={false}
                hide={isHidden(`${dimension.id}.value`)}
              >
                {chartConfig?.valuesShow && (
                  <LabelList
                    dataKey={`${dimension.id}`}
                    position={valuePosition || 'top'}
                    content={payload => {
                      const { x, y, offset } = payload;
                      let _y = y;
                      if (valuePosition === 'top') _y -= valueSize / 2 + circleRadius + 2;
                      else if (valuePosition === 'bottom') _y += valueSize / 2 + circleRadius + 2;

                      const label = index + 1;

                      return (
                        <g>
                          <text
                            dx={offset}
                            x={x}
                            y={_y}
                            dy={valueSize / 3}
                            fill={valuePosition === 'inside' ? contrastColor : `#666`}
                            fontSize={valueSize}
                            textAnchor='middle'
                            dominantBaseline={'middle'}
                            opacity={getOpacity(dimension.id)}
                          >
                            {label}
                          </text>
                        </g>
                      );
                    }}
                  />
                )}
              </Scatter>
            );
          }
        )}
      </ScatterChartReCharts>
    </ResponsiveContainer>
  );
};

const CustomizedCircle = ({ cx, cy, fill, circleRadius, name, opacity }) => {
  return (
    <circle cx={cx} cy={cy} r={circleRadius} fill={fill} stroke={`#fff`} strokeWidth={2} opacity={opacity}></circle>
  );
};

const CustomTooltip = ({ active, payload, label, formatter }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className='recharts-default-tooltip'
        style={{
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(204, 204, 204)',
          margin: 0,
          padding: 10,
          whiteSpace: 'nowrap'
        }}
      >
        <ul className='recharts-tooltip-item-list' style={{ margin: 0, padding: 0 }}>
          <p className='recharts-tooltip-label' style={{ margin: 0 }}>
            <strong>{`${payload[0]?.payload?.name}`}</strong>
          </p>
          <li className='recharts-tooltip-item' style={{ display: 'block', paddingTop: 4, paddingBottom: 4 }}>
            <span className='recharts-tooltip-item-name'>{`${payload[0].name}`}</span>
            <span className='recharts-tooltip-item-separator'> : </span>
            <span className='recharts-tooltip-item-value'>{`${formatter(payload[0].value)}`}</span>
            <span className='recharts-tooltip-item-unit'></span>
          </li>
          <li className='recharts-tooltip-item' style={{ display: 'block', paddingTop: 4, paddingBottom: 4 }}>
            <span className='recharts-tooltip-item-name'>{`${payload[1].name}`}</span>
            <span className='recharts-tooltip-item-separator'> : </span>
            <span className='recharts-tooltip-item-value'>{`${formatter(payload[1].value)}`}</span>
            <span className='recharts-tooltip-item-unit'></span>
          </li>
        </ul>
      </div>
    );
  }

  return null;
};

function findDataKey(chartData, dimensionConfig) {
  let dataKey = null;

  for (const dataKey in chartData) {
    for (const dataSubKey in chartData[dataKey]) {
      if (chartData[dataKey][dataSubKey]?.cellIdentifier) {
        const cellIdentifier = chartData[dataKey][dataSubKey].cellIdentifier;
        // check if all keys in scatterDimensionsX match the cellIdentifier
        const isMatch = Object.keys(dimensionConfig).every(
          dimKey => dimensionConfig[dimKey] === cellIdentifier[dimKey]
        );

        if (isMatch) {
          return dataKey;
        }
      }
    }
  }

  return dataKey;
}

function getDimensions(chartDimensions, dimensionConfig) {
  const dataKeys = [];

  for (const dataKey in chartDimensions) {
    if (chartDimensions[dataKey]?.cellIdentifier) {
      const cellIdentifier = chartDimensions[dataKey]?.cellIdentifier;
      // check if all keys in scatterDimensionsX match the cellIdentifier
      const isMatch = Object.keys(dimensionConfig).every(dimKey => dimensionConfig[dimKey] === cellIdentifier[dimKey]);

      if (isMatch) {
        dataKeys.push(parseInt(dataKey));
      }
    }
  }

  const dimensions = dataKeys.map(index => chartDimensions[index]);

  return dimensions;
}

function getAxisName(table, dimensionConfig, getSourceElement) {
  let axisName;
  let sourceElement;

  if (dimensionConfig && 'headId' in dimensionConfig) {
    const head = table.heads.list?.[dimensionConfig.headId];
    if (head) {
      const { element } = getSourceElement(head);
      sourceElement = element;
    }
  } else if (dimensionConfig && 'rowId' in dimensionConfig) {
    const row = table.rows.list?.[dimensionConfig.rowId];
    if (row) {
      const { element } = getSourceElement(row);
      sourceElement = element;
    }
  }

  axisName = sourceElement?.label ?? sourceElement?.title ?? sourceElement?.id ?? '';

  return axisName;
}
