import { useMemo } from 'react';
import { Container, Row, Col, Input, UncontrolledTooltip } from 'reactstrap';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { includes } from 'lodash';
import { generatePath, Link } from 'react-router-dom';

import { IconProviderWithName } from 'smv-components';
import { questionnaireActions } from 'smv-redux/actions';
import { getQuestionnaireElementTypeSpecificIconString, mediaPlaceholderRegex } from 'smv-helpers';

import { LanguageSelectDropdown } from 'src/smoove/components/project-results';
import { ConfigTabVarname } from '../../../question/components/configtab/ConfigTabVarname';
import { ConfigChoices } from './choices/ConfigChoices';
import { ConfigScale } from './scale/ConfigScale';
import { ConfigMultipleInput, ConfigSingleInput } from '.';
import { HideSubmitButtonSettings } from './HideSubmitButtonSettings';
import { RankingMatrixConfig } from './rankingmatrix/RankingMatrixConfig';

import './ConfigTab.scss';

const baseUrl = `${process.env.REACT_APP_STATIC_ASSET_URL}`;

export const ConfigTab = ({ question, sharedChoices, writeable }) => {
  const projectid = useSelector(state => state.survey.projectid);
  const surveyid = useSelector(state => state.survey.id);
  const expertMode = useSelector(state => state.smooveSettings?.user?.expertMode ?? false);
  const media = useSelector(state => state.survey.media ?? []);
  const translations = useSelector(state => state.survey.translations);
  const questionnaire = useSelector(state => state.survey.questionnaire);

  const intl = useIntl();

  const referrerQuestionId = new URLSearchParams(window.location.search)?.get('ref');

  const selectedLocale = useSelector(
    state => state.projectSettings?.[surveyid]?.selectedLocale ?? state.survey.locales.main
  );
  // all images that are in the question text for the selected locale
  const imageIdsForSelectedLocale = useMemo(() => {
    if (!question?.translations?.text || !translations[question.translations.text]?.[selectedLocale]) return [];

    const queTextForLocale = translations[question.translations.text][selectedLocale];
    return [...queTextForLocale.matchAll(mediaPlaceholderRegex)].map(el => el[2]);
  }, [question, translations, selectedLocale]);

  const qtype = question?.config?.qtype;
  const showChoices = useMemo(
    () => includes(['single_vector', 'multiple_vector', 'single_matrix', 'multiple_matrix', 'custom_shelf'], qtype),
    [qtype]
  );
  const isMatrixQuestion = useMemo(() => includes(['single_matrix', 'multiple_matrix'], qtype), [qtype]);
  const isSingleChoice = useMemo(() => includes(['single_vector'], qtype), [qtype]);
  const isMultipleChoice = useMemo(() => includes(['multiple_vector'], qtype), [qtype]);

  const internalHandler = useMemo(() => {
    return {
      setMandatory: e => {
        if (!writeable) return;
        questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
          config: { mandatory: !question.config?.mandatory ?? false }
        });
      },
      setDisplayAsCarousel: e => {
        if (!writeable) return;
        questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
          config: { displayAsCarousel: !question.config?.displayAsCarousel ?? false }
        });
      },
      setDisplayAsCards: e => {
        if (!writeable) return;
        questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
          config: { displayAsCards: !question.config?.displayAsCards ?? false }
        });
      },
      setDisplayItemsHorizontal: e => {
        if (!writeable) return;
        questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
          config: { displayItemsHorizontal: !question.config?.displayItemsHorizontal ?? false }
        });
      },
      setAlternateBackgroundColors: e => {
        if (!writeable) return;
        questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
          config: { alternateBackgroundColors: !question.config?.alternateBackgroundColors ?? false }
        });
      },
      handleToggleLightbox: imgId => {
        if (!writeable || !imgId) return;
        questionnaireActions.updateQuestionnaireElement(surveyid, question.id, {
          config: {
            media: {
              ...question.config.media,
              [imgId]: {
                ...question.config.media[imgId],
                lightbox: !question.config.media[imgId].lightbox
              }
            }
          }
        });
      }
    };
  }, [question.id, question.config, surveyid, writeable]);

  const importedByQuestions = useMemo(() => {
    if (!question?.importedBy || !questionnaire?.list) return [];

    return question.importedBy.map(qid => {
      return questionnaire.list[qid];
    });
  }, [question?.importedBy, questionnaire?.list]);

  const isRankingMatrix = question.config.displayType === 'ranking';

  return (
    <Container className='config-tab mt-2' id={`config-tab-${question.id}`}>
      {/* Config  choices */}
      <Row className='mb-2'>
        <Col sm='12' md='3'>
          {/* Question Type Info */}
          <div className='config-group'>
            <h6>
              <FormattedMessage
                id={`smoove.questionnaire.modal.config-tab.question-type`}
                defaultMessage={'Question type'}
              />
            </h6>
            {qtype && (
              <div className='question-type'>
                <IconProviderWithName
                  icon={getQuestionnaireElementTypeSpecificIconString(question)}
                  filled={false}
                  clickable={false}
                  name={intl.formatMessage({
                    id: `smoove.questionnaire.question.${getQuestionnaireElementTypeSpecificIconString(question)
                      .split('_')
                      .join('-')}`
                  })}
                  dragable={false}
                />
              </div>
            )}
          </div>

          {/* Question Varname */}
          {/* @Todo: http://gitea.mla.local/smoove/module-smoove/issues/50 */}
          <LanguageSelectDropdown id={'configtab'} showTranslationButton={false} />
          {importedByQuestions.length > 0 && (
            <div className='mt-4'>
              <small>
                <span className='text-warning mr-1'>
                  {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.warning' })}
                </span>
                <span>{intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.import-warning-text' })}</span>
                <div className='mt-2'>
                  {importedByQuestions.map(question => {
                    return (
                      <div
                        style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                        key={question.id}
                      >
                        {question.config?.varname ? `${question.config?.varname} - ` : ''}
                        {question.title}
                      </div>
                    );
                  })}
                </div>
              </small>
            </div>
          )}
          {referrerQuestionId && (
            <div className='mt-4'>
              <Link to={generatePath(referrerQuestionId)} className='pointer btn btn-secondary btn-sm'>
                Back
              </Link>
            </div>
          )}
        </Col>

        <Col>
          <div className='config-group'>
            <div className='config-scale__headline'>
              <FormattedMessage
                id={`smoove.questionnaire.modal.config-tab.general-question-configuration`}
                defaultMessage={'General question configuration'}
              />
            </div>
            <Row>
              <Col sm='6' lg='5' className='d-flex align-items-center'>
                <FormattedMessage id={`smoove.questionnaire.modal.config-tab.mandatory`} defaultMessage={'Mandatory'} />
              </Col>
              <Col sm='6' lg='4'>
                <Input
                  disabled={!writeable}
                  className='pointer'
                  id={`config_mandatory_id_${question?.config?.varname ?? ''}`}
                  type='checkbox'
                  onChange={internalHandler.setMandatory}
                  checked={question.config?.mandatory ?? false}
                />
              </Col>
            </Row>
            <hr className='my-2' />

            {/* Varname input */}
            <ConfigTabVarname question={question} writeable={writeable} />
            {/* show as carousel checkbox */}
            {isMatrixQuestion && !isRankingMatrix && (
              <>
                <Row>
                  <Col sm='6' lg='5' className='d-flex align-items-center'>
                    <FormattedMessage
                      id={`smoove.questionnaire.modal.config-tab.display-as-carousel`}
                      defaultMessage={'Display as carousel'}
                    />
                    <i className='fal fa-circle-info ml-2' id={`tt_display_carousel`} />
                    <UncontrolledTooltip target={`tt_display_carousel`} delay={{ hide: 200 }} autohide={false}>
                      <FormattedMessage
                        id={`smoove.questionnaire.modal.config-tab.display-as-carousel-tooltip`}
                        defaultMessage={
                          'Select if the question should be shown as a carousel (each item one by one) or in a normal matrix style.'
                        }
                      />
                    </UncontrolledTooltip>
                  </Col>
                  <Col sm='6' lg='4'>
                    <Input
                      disabled={!writeable}
                      id={`config_carousel_id_${question?.config?.varname ?? ''}`}
                      type={'checkbox'}
                      onChange={internalHandler.setDisplayAsCarousel}
                      checked={question.config?.displayAsCarousel ?? false}
                    />
                  </Col>
                </Row>
                <hr className='my-2' />
              </>
            )}

            {/* hide submit button time*/}
            <HideSubmitButtonSettings writeable={writeable} question={question} />
            <hr className='my-2' />

            {/* display as cards checkbox */}
            {(isSingleChoice || isMultipleChoice) && (
              <>
                <Row>
                  <Col sm='6' lg='5' className='d-flex align-items-center'>
                    <FormattedMessage
                      id={`smoove.questionnaire.modal.config-tab.display-as-cards`}
                      defaultMessage={'Display as cards'}
                    />
                    <i className='fal fa-circle-info ml-2' id={`tt_display_cards`} />
                    <UncontrolledTooltip target={`tt_display_cards`} delay={{ hide: 200 }} autohide={false}>
                      <FormattedMessage
                        id={`smoove.questionnaire.modal.config-tab.display-as-cards-tooltip`}
                        defaultMessage={'Select if the items should be shown as cards'}
                      />
                    </UncontrolledTooltip>
                  </Col>
                  <Col sm='6' lg='4'>
                    <Input
                      disabled={!writeable}
                      id={`config_cards_id_${question?.config?.varname ?? ''}`}
                      type='checkbox'
                      onChange={internalHandler.setDisplayAsCards}
                      checked={question.config?.displayAsCards ?? false}
                    />
                  </Col>
                </Row>
                <hr className='my-2' />
              </>
            )}

            {/* display items horizontally */}
            {(isSingleChoice || isMultipleChoice) && (
              <>
                <Row>
                  <Col sm='6' lg='5' className='d-flex align-items-center'>
                    <FormattedMessage
                      id={`smoove.questionnaire.modal.config-tab.display-items-horizontally`}
                      defaultMessage={'Display items horizontally'}
                    />
                    <i className='fal fa-circle-info ml-2' id={`tt_display_items_horizontally`} />
                    <UncontrolledTooltip
                      target={`tt_display_items_horizontally`}
                      delay={{ hide: 200 }}
                      autohide={false}
                    >
                      <FormattedMessage
                        id={`smoove.questionnaire.modal.config-tab.display-items-horizontally-tooltip`}
                        defaultMessage={'Show items horizontally instead of vertically (from left to right)'}
                      />
                    </UncontrolledTooltip>
                  </Col>
                  <Col sm='6' lg='4'>
                    <Input
                      disabled={!writeable || question.config?.displayAsCards}
                      id={`config_display_horizontal_id_${question?.config?.varname ?? ''}`}
                      type='checkbox'
                      onChange={internalHandler.setDisplayItemsHorizontal}
                      checked={(question.config?.displayItemsHorizontal || question.config?.displayAsCards) ?? false}
                    />
                  </Col>
                </Row>
                <hr className='my-2' />
              </>
            )}
            {/* toggle lightbox */}
            {imageIdsForSelectedLocale.length > 0 && (
              <>
                <Row>
                  <Col sm='6' lg='5' className='d-flex align-items-center'>
                    {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.enable-lightbox-on-image' })}
                    <i className='fal fa-circle-info ml-2' id={`tt_lightbox_toggle`} />
                    <UncontrolledTooltip target={`tt_lightbox_toggle`} delay={{ hide: 200 }} autohide={false}>
                      {intl.formatMessage({ id: 'smoove.questionnaire.modal.config-tab.enable-lightbox-tooltip' })}
                    </UncontrolledTooltip>
                  </Col>
                  <Col sm='6' lg='4'>
                    {imageIdsForSelectedLocale.map((imgId, idx) => {
                      const sourceImage = media[question.config.media[imgId]?.source];
                      if (!sourceImage) return null;
                      return (
                        <div className='d-flex align-items-center' key={`${imgId}_${idx}`}>
                          <Input
                            disabled={!writeable}
                            id={`config_is_lightboox_enabled_id_${imgId ?? ''}`}
                            type='checkbox'
                            onChange={e => internalHandler.handleToggleLightbox(imgId)}
                            checked={question.config?.media?.[imgId]?.lightbox ?? false}
                          />
                          <div className='mr-2 my-1' style={{ width: '60px' }}>
                            <img
                              src={`${baseUrl}/${sourceImage.thumbnailUrl}`}
                              alt={sourceImage.originalName}
                              style={{ objectFit: 'contain', width: '100%' }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                </Row>
                <hr className='my-2' />
              </>
            )}

            {/* alternate background colors */}
            {((isSingleChoice && !(question.config?.displayItemsHorizontal ?? false)) ||
              (isMultipleChoice && !(question.config?.displayItemsHorizontal ?? false)) ||
              (isMatrixQuestion && !isRankingMatrix)) && (
              <>
                <Row>
                  <Col sm='6' lg='5' className='d-flex align-items-center'>
                    <FormattedMessage
                      id={`smoove.questionnaire.modal.config-tab.alternate-background-colors`}
                      defaultMessage={'Alternate background colors'}
                    />
                    <i className='fal fa-circle-info ml-2' id={`tt_alternate_bg_colors`} />
                    <UncontrolledTooltip target={`tt_alternate_bg_colors`} delay={{ hide: 200 }} autohide={false}>
                      <FormattedMessage
                        id={`smoove.questionnaire.modal.config-tab.alternate-background-colors-tooltip`}
                        defaultMessage={'If selected, alternate background colors light/dark'}
                      />
                    </UncontrolledTooltip>
                  </Col>
                  <Col sm='6' lg='4'>
                    <Input
                      disabled={!writeable}
                      id={`config_alternate_bg_id_${question?.config?.varname ?? ''}`}
                      type='checkbox'
                      onChange={internalHandler.setAlternateBackgroundColors}
                      // todo: this should be true by default and needs to be set by the wizard/backend when creating the question!
                      checked={question.config?.alternateBackgroundColors ?? false}
                    />
                  </Col>
                </Row>
                <hr className='my-2' />
              </>
            )}
          </div>

          {(qtype === 'single_input_text' || qtype === 'single_input_number') && (
            <ConfigSingleInput question={question} handler={internalHandler} writeable={writeable} />
          )}
          {(qtype === 'multiple_input_text' || qtype === 'multiple_input_number') && (
            <ConfigMultipleInput question={question} writeable={writeable} />
          )}
          {isRankingMatrix && <RankingMatrixConfig writeable={writeable} question={question} />}
          {showChoices && (
            <ConfigChoices
              question={question}
              expertMode={expertMode}
              sharedChoices={sharedChoices}
              writeable={writeable}
              projectid={projectid}
              surveyid={surveyid}
            />
          )}

          {isMatrixQuestion && !isRankingMatrix && (
            <ConfigScale
              question={question}
              config={question.config}
              expertMode={expertMode}
              writeable={writeable}
              projectid={projectid}
              surveyid={surveyid}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};
