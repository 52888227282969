import React from 'react';
import { useParams, useRouteMatch, generatePath, useHistory, Route } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { InlineEdit } from 'smv-components';
import { questionnaireActions } from '../../../../../redux/actions';

import '../ConfigModal.scss';

export const PageConfigModal = ({ page, writeable }) => {
  const { path } = useRouteMatch();
  const params = useParams();
  const history = useHistory();

  const { surveyid } = params;

  const internalHandler = {
    setSection: section => {
      history.push(generatePath(path, { ...params, section: section }));
    },
    close: () => {
      history.push(generatePath(path.replace('/:elementid/:section?', ''), params));
    }
  };

  return (
    <Modal isOpen={true} centered={true} size={'lg'} toggle={internalHandler.close}>
      <ModalHeader toggle={internalHandler.close}>
        <div className='d-flex'>
          <div className='modal__title-group'>
            <div className='modal__title-info'>
              <FormattedMessage id={'smoove.questionnaire.modal.settings-of'} defaultMessage={'Settings of'} />
              {':'}
            </div>
            <InlineEdit
              disabled={!writeable}
              className='modal__title'
              defaultValue={page.title}
              saveFn={newTitle => {
                if (page.title !== newTitle) {
                  questionnaireActions.updateQuestionnaireElement(surveyid, page.id, {
                    parentId: page.parentId ?? null,
                    title: newTitle
                  });
                }
              }}
            />
          </div>
        </div>
      </ModalHeader>

      <ModalBody style={{ minHeight: '450px' }}>
        <Route path={generatePath(path, { ...params, section: undefined })} exact={true}>
          {/* <ul>
            <li>varprefix</li>
            <li>randomized order</li>
            
            <li>screenout</li>
            <li>filter</li>
            <li>jump</li>
          </ul> */}
        </Route>
      </ModalBody>

      <ModalFooter>
        {/* <div className='mr-auto d-flex'>
          <div className='mr-2'>
            <FormattedMessage id={`smoove.common.project.expert-mode`} defaultMessage={'Expert Mode'} />
          </div>
          <SmvToggleExpertModeSwitch />
        </div> */}

        <Button color={'grey'} onClick={internalHandler.close}>
          <FormattedMessage id={`smoove.common.buttons.close`} defaultMessage={'Close'} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
